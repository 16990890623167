<template>
  <section class="
      w-full
      min-h-screen
      flex
      items-center
      justify-center
      py-20
      p-6
      lg:flex-row
      bg-primary
    ">
    <main class="w-full max-w-xl bg-white rounded-xl">
      <div class="px-8 py-10">
        <div class="table mx-auto mb-10">
          <img src="@/assets/images/logo-1.png" alt="site logo" class="max-w-[4rem]" />
        </div>
        <div class="my-6" v-if="error">
          <p class="text-red-500 font-medium text-sm text-center">
            {{ error }}
          </p>
        </div>
        <form class="w-full space-y-3 mt-6">
          <div class="space-y-2 form-group">
            <div class="with-label">
              <input type="email" name="email" id="email" autocomplete="off" spellcheck="off"
                placeholder="example@example.com" v-model.trim="add_data.email_mobile" required />
              <label for="email">{{ $t('email') }}</label>
            </div>
            <p class="text-red-500 font-medium text-xs" v-if="add_errors.email_mobile">
              {{ add_errors.email_mobile.join(' ') }}
            </p>
          </div>
          <div class="space-y-2 form-group">
            <div class="with-label">
              <div class="with-icon">
                <input type="password" name="password" id="password" autocomplete="off" spellcheck="off"
                  placeholder="••••••••" required v-model.trim="add_data.password" />
                <button class="input-icon" @click.prevent="textPasswordType" type="button">
                  <i class="fa-solid fa-eye-slash"></i>
                </button>
              </div>
              <label for="password">{{ $t('password') }}</label>
            </div>
            <p class="text-red-500 font-medium text-xs" v-if="add_errors.password">
              {{ add_errors.password.join(' ') }}
            </p>
          </div>

          <div class="space-y-3 !mt-6">
            <button class="
                w-full
                h-auto
                bg-secondary
                text-white
                font-medium
                rtl:font-semibold
                text-sm
                rounded-md
                text-center
                py-3
                px-4
              " @click.prevent="handleLogin" :disabled="add_disabled">
              <spinner size="w-4 h-4" class="inline-block align-sub" v-if="add_disabled" />

              {{ $t('login') }}
            </button>
            <a href="https://rtibha.com" class="
                w-full
                h-auto
                bg-gray-100
                text-gray-600
                font-medium
                rtl:font-semibold
                text-sm
                rounded-md
                text-center
                py-3
                px-4
                flex
                justify-center
                items-center
                gap-3
              ">
              <i class="fa-solid fa-globe"></i>
              <span>{{ $t('go_website') }}</span>
            </a>
          </div>
        </form>
      </div>
    </main>
  </section>
</template>
<script>
export default {
  name: 'LoginPage',

  data() {
    return {
      add_data: {},
      add_errors: {},
      error: null,
      add_disabled: false,
    }
  },

  methods: {
    async handleLogin() {
      this.add_disabled = true
      this.add_errors = {}
      this.error = null
      try {
        const { data } = await this.axios.post('login', this.add_data)
        this.$store.commit('auth/SET_USER', {
          profile: data.result.profile,
          access_token: data.result.access_token,
        })
        this.$cookies.set('t', data.result.access_token)
        window.location.reload()
      } catch (error) {
        const err = error?.response
        if (!err) {
          this.createAlert('there is something went wrong', 'error')
          return
        }
        const responseError = err?.data?.message
        if (typeof responseError === 'object') {
          this.add_errors = responseError
        } else {
          this.error = responseError
        }
      } finally {
        this.add_disabled = false
      }
    },
  },
}
</script>

<style lang="scss"></style>
