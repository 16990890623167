<template>
  <div>
    <figure class="w-full ring-1 ring-gray-200 rounded-md bg-white h-full">
      <!-- :class="item.style.parent" -->
      <blockquote class="flex gap-4 items-center animate-pulse p-5" v-if="load">
        <div>
          <div class="w-9 h-9 rounded-full bg-gray-200"></div>
        </div>
        <div class="flex-1 max-w-xs space-y-2 hidden sm:block">
          <div class="w-2/3 h-4 rounded bg-gray-200"></div>
          <div class="w-full h-3 rounded bg-gray-100"></div>
        </div>
      </blockquote>
      <blockquote class="w-full p-5 flex gap-4" v-else>
        <div>
          <div
            class="w-12 h-12 rounded-full flex items-center justify-center"
            :class="item.style.parent"
          >
            <i :class="`${item.icon} ${item.style.icon} text-xl`"></i>
          </div>
        </div>
        <div class="flex-1 space-y-0.5">
          <p class="text-gray-600 font-semibold rtl:font-bold text-sm">
            {{ item.translate_key ? $t(item.translate_key) : item.title }}
          </p>

          <h1 class="text-gray-800 font-bold text-2xl">
            {{ item.value }}
          </h1>
        </div>
      </blockquote>
    </figure>
  </div>
</template>

<script>
export default {
  name: 'SingleCard',
  props: {
    item: {
      type: Object,
      default: Object,
    },
    load: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
