<template>
  <div>
    <section class="w-full space-y-6 px-6">
      <filter-wrapper>
        <div class="w-full">
          <multiselect
            v-model="pagination.city_id"
            :options="cities"
            :placeholder="$t('cities')"
            id="city_id"
            :hideSelected="true"
            :clearOnSelect="true"
            :closeOnSelect="false"
            label="name"
            track-by="name"
            multiple
            :disabled="cities.length === 0"
            :limit="1"
            :limit-text="(e) => `+${e}`"
            :show-no-results="true"
            style="background-color: #ffffff !important"
          >
            <span
              slot="noResult"
              class="
                whitespace-normal
                text-gray-600 text-sm
                font-medium
                rtl:font-semibold
              "
              >{{ $t('no_data') }}</span
            ></multiselect
          >
        </div>

        <div class="w-full">
          <div class="actions-group">
            <div>
              <button
                class="
                  w-full
                  h-auto
                  bg-secondary
                  text-white
                  font-medium
                  rtl:font-semibold
                  text-sm
                  rounded-md
                  text-center
                  py-2.5
                  px-4
                "
                @click.prevent="applyFilter"
                :disabled="apply_disabled"
              >
                <spinner size="w-4 h-4 inline-block" v-if="apply_disabled" />
                {{ $t('apply') }}
              </button>
            </div>
            <div>
              <button
                class="
                  w-full
                  h-auto
                  bg-gray-300
                  text-gray-600
                  font-medium
                  rtl:font-semibold
                  text-sm
                  rounded-md
                  text-center
                  py-2.5
                  px-4
                "
                @click.prevent="resetFilter"
                :disabled="reset_disabled"
              >
                <spinner size="w-4 h-4 inline-block" v-if="reset_disabled" />
                {{ $t('reset') }}
              </button>
            </div>
          </div>
        </div>
      </filter-wrapper>
      <main class="w-full">
        <Table
          :has_header="true"
          :has_check="false"
          :title="$t('districts')"
          :records="`${pagination.total} ${$t('records')}`"
          :total_pages="pagination.total_pages"
          v-model="pagination.page"
        >
          <template #actions>
            <!-- <div>
              <button
                class="
                  py-1.5
                  px-3
                  rounded-sm
                  text-sm
                  flex
                  items-center
                  gap-2
                  text-gray-600
                  font-medium
                  rtl:font-semibold
                "
                @click.prevent="add_active = !add_active"
              >
                <i class="fa-solid fa-plus"></i>
                <span>{{ $t('add_new.district') }}</span>
              </button>
            </div> -->
            <div>
              <router-link
                class="
                  py-1.5
                  px-3
                  rounded-sm
                  text-sm
                  flex
                  items-center
                  gap-2
                  text-gray-600
                  font-medium
                  rtl:font-semibold
                "
                to="/setup/district-group"
              >
                <i class="fa-solid fa-layer-group"></i>
                <span>{{ $t('district_group') }}</span>
              </router-link>
            </div>
          </template>
          <template #filter>
            <ul class="flex items-center">
              <li v-for="(item, index) in status" :key="index">
                <button
                  class="
                    py-4
                    px-6
                    text-center
                    font-medium
                    rtl:font-semibold
                    text-sm
                    capitalize
                  "
                  @click.prevent="
                    changeFilterStatus(item === 'all' ? null : item, index)
                  "
                  :class="{
                    'active-tab':
                      pagination.is_active === (item === 'all' ? null : item),
                  }"
                  :disabled="disables[index]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    class="mr-2 rtl:mr-0 rtl:ml-2"
                    v-if="disables[index]"
                  />
                  {{ $t(item.replace(/\s/g, '_')) }}
                </button>
              </li>
            </ul>
          </template>
          <template #head>
            <th>{{ $t('name') }}</th>
            <th>{{ $t('alt_name') }}</th>

            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('city') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('city_id')"
                  ></button>
                </div>
              </div>
            </th>
            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('active') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('is_active')"
                  ></button>
                </div>
              </div>
            </th>

            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('updated_at') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('updated_at')"
                  ></button>
                </div>
              </div>
            </th>
            <th></th>
          </template>
          <template #tbody v-if="!load">
            <TableLoad :rows="5" v-for="i in 6" :key="i" />
          </template>
          <template #tbody v-else-if="load && items.length !== 0">
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ item.name || '-' }}</td>
              <td>{{ item.alt_name || '-' }}</td>
              <td>{{ (item.city || {}).name || '-' }}</td>
              <td>
                <p
                  class="status capitalize"
                  :class="item_status[item.is_active]"
                >
                  {{ item.is_active === 1 ? $t('active') : $t('not_active') }}
                </p>
              </td>
              <td v-html="createTime(item.updated_at || item.created_at)"></td>

              <td>
                <div class="actions-group">
                  <!-- <div>
                    <button
                      class="
                        p-2
                        px-3
                        text-red-500
                        font-medium
                        rtl:font-semibold
                      "
                      @click.prevent="deleteItem(item.id)"
                      :disabled="disables[`delete_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`delete_${item.id}`]"
                      />
                      <span v-else> {{ $t('delete') }}</span>
                    </button>
                  </div> -->
                  <div>
                    <button
                      class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded-sm"
                      @click.prevent="changeStatus(item.id)"
                      :content="$t('change_status')"
                      v-tippy
                      :disabled="disables[`status_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`status_${item.id}`]"
                      />
                      <i class="fa-solid fa-right-left" v-else></i>
                    </button>
                  </div>
                  <div>
                    <button
                      class="w-8 h-8 bg-sky-500 text-white rounded-sm"
                      @click.prevent="showUpdate(item.id)"
                      :content="$t('update')"
                      v-tippy
                    >
                      <i class="fa-solid fa-pen"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template #tbody v-else-if="load && items.length === 0">
            <tr>
              <td colspan="5">
                <div class="table mx-auto my-4">
                  <button
                    class="
                      py-1.5
                      px-3
                      rounded-sm
                      text-sm
                      flex
                      items-center
                      gap-2
                      text-gray-600
                      ring-1 ring-gray-300
                      font-medium
                      rtl:font-semibold
                    "
                    @click.prevent="add_active = !add_active"
                  >
                    <i class="fa-solid fa-plus"></i>
                    <span>{{ $t('add_new.district') }}</span>
                  </button>
                </div>
                <p
                  class="
                    text-sm text-gray-600
                    font-medium
                    rtl:font-semibold
                    text-center
                  "
                >
                  {{ $t('no_data') }}
                </p>
              </td>
            </tr>
          </template>
        </Table>
      </main>
    </section>
    <transition name="scale">
      <Modal
        :title="$t('add_new.district')"
        v-if="add_active"
        @close="add_active = $event"
      >
        <template>
          <form class="p-6 space-y-4">
            <div class="form-group">
              <div class="with-label">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  autocomplete="off"
                  v-model="add_data.name"
                  v-ltr
                />
                <label for="name">{{ $t('name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.name"
              >
                {{ add_errors.name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <input
                  type="text"
                  name="alt_name"
                  id="alt_name"
                  placeholder="الاسم"
                  required
                  autocomplete="off"
                  v-model="add_data.alt_name"
                  v-rtl
                />
                <label for="alt_name">{{ $t('alt_name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.alt_name"
              >
                {{ add_errors.alt_name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="add_data.country_id"
                  :options="countries"
                  :placeholder="$t('select')"
                  id="add_data_country_id"
                  label="name"
                  track-by="name"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="countries.length === 0"
                ></multiselect>
                <label for="add_data_country_id">{{ $t('country') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.country_id"
              >
                {{ add_errors.country_id.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="add_data.region_id"
                  :options="FilterRegions('add_data')"
                  :placeholder="$t('select')"
                  id="add_data_region_id"
                  label="name"
                  track-by="name"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="FilterRegions('add_data').length === 0"
                ></multiselect>
                <label for="add_data_region_id">{{ $t('region') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.region_id"
              >
                {{ add_errors.region_id.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="add_data.city_id"
                  :options="FilterCities('add_data')"
                  :placeholder="$t('select')"
                  id="add_data_city_id"
                  label="name"
                  track-by="name"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="FilterCities('add_data').length === 0"
                ></multiselect>
                <label for="add_data_city_id">{{ $t('city') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.city_id"
              >
                {{ add_errors.city_id.join(' ') }}
              </p>
            </div>

            <div class="actions-group">
              <div>
                <button
                  class="
                    py-2.5
                    px-6
                    rounded-md
                    text-sm
                    font-medium
                    rtl:font-semibold
                    text-white
                    bg-primary
                    table
                  "
                  @click.prevent="addItem"
                  :disabled="add_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
                  {{ $t('add') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
    <transition name="scale">
      <Modal
        :title="$t('update')"
        v-if="edit_active"
        @close="edit_active = $event"
      >
        <template>
          <form class="p-6 space-y-4">
            <div class="form-group">
              <div class="with-label">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  autocomplete="off"
                  v-model="edit_data.name"
                  v-ltr
                />
                <label for="name">{{ $t('name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.name"
              >
                {{ edit_errors.name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <input
                  type="text"
                  name="alt_name"
                  id="alt_name"
                  placeholder="الاسم"
                  required
                  autocomplete="off"
                  v-model="edit_data.alt_name"
                  v-rtl
                />
                <label for="alt_name">{{ $t('alt_name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.alt_name"
              >
                {{ edit_errors.alt_name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="edit_data.country_id"
                  :options="countries"
                  :placeholder="$t('select')"
                  id="edit_data_country_id"
                  label="name"
                  track-by="name"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="countries.length === 0"
                ></multiselect>
                <label for="edit_data_country_id">{{ $t('country') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.country_id"
              >
                {{ edit_errors.country_id.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="edit_data.region_id"
                  :options="FilterRegions('edit_data')"
                  :placeholder="$t('select')"
                  id="edit_data_region_id"
                  label="name"
                  track-by="name"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="FilterRegions('edit_data').length === 0"
                ></multiselect>
                <label for="edit_data_region_id">{{ $t('region') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.region_id"
              >
                {{ edit_errors.region_id.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="edit_data.city_id"
                  :options="FilterCities('edit_data')"
                  :placeholder="$t('select')"
                  id="edit_data_city_id"
                  label="name"
                  track-by="name"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :disabled="FilterCities('edit_data').length === 0"
                ></multiselect>
                <label for="edit_data_city_id">{{ $t('city') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.city_id"
              >
                {{ edit_errors.city_id.join(' ') }}
              </p>
            </div>
            <div class="actions-group">
              <div>
                <button
                  class="
                    py-2.5
                    px-6
                    rounded-md
                    text-sm
                    font-medium
                    rtl:font-semibold
                    text-white
                    bg-primary
                    table
                  "
                  @click.prevent="editItem"
                  :disabled="edit_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="edit_disabled" />
                  {{ $t('save_changes') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
  </div>
</template>
<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import Modal from '@/components/basics/Modal.vue'
import FilterWrapper from '@/components/basics/FilterWrapper.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Districts',
  data() {
    return {
      load: false,
      items: [],
      countries: [],
      regions: [],
      cities: [],
      add_data: {},
      add_active: false,
      add_errors: {},
      add_disabled: false,
      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,
      pagination: {
        page: 1,
        total_pages: 0,
        search_key: null,
        sort_key: null,
        sort_type: 'asc',
        is_active: null,
        city_id: null,
        total: 0,
      },
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },

      apply_disabled: false,
      reset_disabled: false,
      status: ['all', 'active', 'not active'],
      disables: {},
    }
  },
  watch: {
    'pagination.page'() {
      this.getItems()
    },
    edit_active() {
      if (!this.edit_active) {
        this.edit_data = {}
        this.edit_errors = {}
        this.edit_disabled = false
      }
    },
    add_active() {
      if (!this.add_active) {
        this.add_data = {}
        this.add_errors = {}
        this.add_disabled = false
      }
    },
    'add_data.region_id'() {
      this.$set(this['add_data'], 'city_id', null)
    },
    'edit_data.region_id'() {
      this.$set(this['edit_data'], 'city_id', null)
    },
    is_called(val) {
      if (val) this.getItems()
    },
  },
  computed: {
    ...mapGetters('table', ['search', 'is_called']),
    FilterRegions() {
      return (type) => {
        return (
          this.regions.filter(
            (e) => e?.country?.id === this[type]?.country_id?.id
          ) ?? []
        )
      }
    },
    FilterCities() {
      return (type) => {
        return (
          this.cities.filter(
            (e) => e?.region?.id === this[type]?.region_id?.id
          ) ?? []
        )
      }
    },
  },
  created() {
    this.getItems()
    this.getCountries()
    this.getRegions()
    this.getCities()
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled']),
    getCountries() {
      this.$store
        .dispatch('getting/getRoute', {
          name: 'countries',
          options: { is_active: 1 },
        })
        .then(({ result }) => {
          this.countries = result.countries
        })
    },
    getRegions() {
      this.$store
        .dispatch('getting/getRoute', {
          name: 'regions',
          options: { is_active: 1 },
        })
        .then(({ result }) => {
          this.regions = result.regions
        })
    },
    getCities() {
      this.$store
        .dispatch('getting/getRoute', {
          name: 'cities',
          options: { is_active: 1 },
        })
        .then(({ result }) => {
          this.cities = result.cities
        })
    },
    getItems() {
      const { page, search_key, sort_key, sort_type, is_active, city_id } =
        this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'districts',
          options: {
            page,
            search_key,
            sort_key,
            sort_type,
            city_id: city_id?.map((e) => e.id),
            is_active: is_active ? (is_active === 'active' ? 1 : 0) : is_active,
            search_key: this.search,
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.districts
          this.items = data.map((e) => {
            return {
              ...e,
              image: e.image ? result.image_url + '/' + e.image : e.image, // null,
            }
          })
          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
        })
        .catch((err) => {
          console.log(err)
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => {
          this.changeIsCalled(false)
          this.load = true
        })
    },
    async addItem() {
      this.add_disabled = true
      this.add_errors = {}

      try {
        const { data } = await this.axios.post('districts/add', {
          ...this.add_data,
          country_id: this.add_data?.country_id?.id,
          region_id: this.add_data?.region_id?.id,
          city_id: this.add_data?.city_id?.id,
        })
        this.getItems()
        this.createAlert(data.message)
        this.add_active = false
        this.add_data = {}
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    async editItem() {
      this.edit_disabled = true
      this.edit_errors = {}

      try {
        const { data } = await this.axios.post('districts/update', {
          ...this.edit_data,
          country_id: this.edit_data?.country_id?.id,
          region_id: this.edit_data?.region_id?.id,
          city_id: this.edit_data?.city_id?.id,
        })
        const { message, result } = data
        const finalResult = result.region ?? {}
        this.getItems()
        this.createAlert(message)
        // this.edit_active = false
        this.edit_data = {
          ...finalResult,
          country_id: finalResult?.country,
          region_id: finalResult?.region,
          city_id: finalResult?.city,
        }
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.edit_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.edit_errors = res?.data?.message
      } finally {
        this.edit_disabled = false
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true)
      try {
        const { data } = await this.axios.post(
          `districts/update_is_active/${id}`
        )
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)
      try {
      const { isConfirmed } = await this.deleteAlert();
        if (!isConfirmed) return
        const { data } = await this.axios.post(`districts/delete/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.edit_data = {
        ...item,
        country_id: item?.country ?? {},
        region_id: item?.region ?? {},
        city_id: item?.city ?? {},
      }
      this.edit_active = true
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination)
        .filter((e) =>
          ['status', 'search_key', 'country_id', 'region_id'].includes(e)
        )
        .forEach((key) => this.$set(this.pagination, key, null))
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      this.$set(this.pagination, 'is_active', status)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
  },
  components: { Table, Modal, TableLoad, FilterWrapper },
}
</script>
