var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"w-full py-4 px-6"},[_c('div',{staticClass:"w-full flex justify-between items-center gap-4",attrs:{"data-type":"centered-item"}},[_c('div',{staticClass:"flex-1"},[_c('p',{staticClass:"text-gray-600 text-xs font-medium rtl:font-semibold"},[_vm._v(" "+_vm._s(new Date().getFullYear())+" © "+_vm._s(_vm.$t('copyRight'))+" "),_c('a',{staticClass:"text-primary underline hover:no-underline",attrs:{"href":"#"}},[_vm._v("Rawaj")])])]),(['Login', 'Join'].includes(_vm.$route.name))?_c('div',[_c('t-dropdown',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
          var mousedownHandler = ref.mousedownHandler;
          var focusHandler = ref.focusHandler;
          var blurHandler = ref.blurHandler;
          var keydownHandler = ref.keydownHandler;
          var isShown = ref.isShown;
return _c('div',{},[_c('button',{staticClass:"py-2 px-3 rounded-md bg-gray-50 hover:bg-gray-100 text-sm font-medium rtl:font-semibold",class:{ 'border-gray-300 bg-gray-500 text-white ': isShown },on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('div',{staticClass:"w-full gap-3 sm:flex items-center hidden"},[_c('i',{staticClass:"fa-solid fa-angle-down fa-xs transition-transform ease-linear",class:{ 'rotate-180': isShown }}),_c('span',[_vm._v(_vm._s(_vm.$t(("langs." + (_vm.$i18n.locale)))))])]),_c('div',{staticClass:"block sm:hidden"},[_c('i',{staticClass:"fa-solid fa-globe fa-sm text-gray-600"})])])])}},{key:"default",fn:function(ref){
          var hide = ref.hide;
          var blurHandler = ref.blurHandler;
return _c('div',{},[_c('ul',{staticClass:"list-none overflow-hidden"},_vm._l((_vm.langs),function(item,index){return _c('li',{key:index,staticClass:"border-b border-gray-100 last:border-0"},[_c('button',{staticClass:"w-full p-3 flex items-center gap-4 font-medium rtl:font-semibold text-sm hover:bg-gray-100",on:{"click":function($event){$event.preventDefault();return (function () {
                    _vm.changeLang(item.lang)
                    hide()
                    blurHandler()
                  }).apply(null, arguments)}}},[_c('span',{class:("fi-" + (item.flag) + " w-5 h-4 bg-contain bg-no-repeat")}),_c('span',[_vm._v(_vm._s(_vm.$t(("langs." + (item.lang)))))])])])}),0)])}}],null,false,3749929783)})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }