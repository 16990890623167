export default async function checkCompany({ store, to, next }) {
  const { id } = to.params
  try {
    await store.dispatch('getting/getRoute', {
      name: `companies/${id}`,
    })
    return next()
  } catch (error) {
    return next({
      path: '/companies',
    })
  }
}
