import moment from 'moment'

export default {
  namespaced: true,
  state: {
    page: 1,
    apply_disabled: false,
    reset_disabled: false,
    is_called: false,
    search_key: null,
    pagination: {},
  },
  getters: {
    page: (state) => state.page,
    apply: (state) => state.apply_disabled,
    reset: (state) => state.reset_disabled,
    is_called: (state) => state.is_called,
    table_paginate: (state) => {
      const { from, ...other } = state.pagination
      return {
        ...other,
        // from: !from ? moment(new Date()).format('yyyy-MM-DD') : from,
        from,
      }
    },
    search: (state) => state.search_key,
  },
  mutations: {
    setPage(state, payload) {
      state.page = payload
    },
    changeApply(state, payload) {
      state.apply_disabled = payload
    },
    changeReset(state, payload) {
      state.reset_disabled = payload
    },
    changeIsCalled(state, payload) {
      state.is_called = payload
      if (!payload) {
        state.apply_disabled = payload
        state.reset_disabled = payload
      }
    },
    changePagination(state, payload) {
      const {
        company_store_id,
        company_branch_id,
        country_id,
        region_id,
        city_id,
        store_placement_id,
        user_id,
        client_id,
        supervisor_id,
        category_id,
        sub_category_id,
        ...others
      } = payload
      state.pagination = {
        ...others,
        company_store_id: company_store_id?.map((e) => e.id),
        company_branch_id: company_branch_id?.map((e) => e.id),
        country_id: country_id?.map((e) => e.id),
        region_id: region_id?.map((e) => e.id),
        city_id: city_id?.map((e) => e.id),
        store_placement_id: store_placement_id?.map((e) => e.id),
        user_id: user_id?.map((e) => e.id),
        client_id: client_id?.map((e) => e.id),
        supervisor_id: supervisor_id?.map((e) => e.id),
        category_id: category_id?.map((e) => e.id),
        sub_category_id: sub_category_id?.map((e) => e.id),
      }
    },
    updatePagination() {
      const item = JSON.parse(JSON.stringify(state.pagination))
      Object.assign(item, payload)
      state.pagination = item
    },
    resetPagination(state) {
      state.pagination = {}
    },
    updateSearch(state, payload) {
      state.search_key = payload
    },
  },
  actions: {
    setNewPage({ commit }, pageCount) {
      commit('setPage', pageCount)
    },
  },
}
