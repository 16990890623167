<template>
  <div>
    <MessagesContainerWrapper @items="(e) => (users = e || [])">
      <main class="flex-1 w-full bg-white border border-gray-200">
        <div class="flex flex-col h-full">
          <div>
            <header class="w-full border-b border-gray-100 p-4">
              <div
                class="flex items-center"
                v-if="load && (DisplayCurrentUserRoomData || {}).id"
              >
                <div>
                  <div class="w-10 h-10">
                    <img
                      src="@/assets/images/profile.jpg"
                      alt="user profile image"
                      class="w-full h-full rounded-full object-cover border border-gray-200 block sticky top-20"
                    />
                  </div>
                </div>
                <div class="px-4 space-y-1 flex-1">
                  <div class="table">
                    <div class="grid">
                      <p
                        class="text-sm text-primary font-medium rtl:font-semibold table truncate"
                      >
                        {{ DisplayCurrentUserRoomData.name }}
                      </p>
                      <a
                        :href="`mailto:${DisplayCurrentUserRoomData.email}`"
                        class="text-xs text-gray-400 block font-medium rtl:font-semibold truncate"
                        >{{ DisplayCurrentUserRoomData.email }}</a
                      >
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    class="w-8 h-8 rounded-full flex items-center justify-center bg-red-500/10 text-red-500"
                    @click.prevent="delete_active = !delete_active"
                  >
                    <i class="fa-solid fa-trash fa-sm align-middle"></i>
                  </button>
                </div>
              </div>
              <div class="flex items-center" v-else>
                <div>
                  <div
                    class="w-10 h-10 rounded-full bg-gray-200 animate-pulse"
                  ></div>
                </div>
                <div class="px-4 space-y-2 flex-1">
                  <div
                    class="w-full max-w-[10rem] h-4 rounded-sm bg-gray-200 animate-pulse"
                  ></div>
                  <div
                    class="w-full max-w-xs h-3 rounded-sm bg-gray-200 animate-pulse"
                  ></div>
                </div>
              </div>
            </header>
          </div>
          <div
            class="flex-1 overflow-y-auto p-4 max-h-[45rem] md:max-h-[auto]"
            ref="chatBox"
          >
            <div class="w-full space-y-1" v-if="load">
              <SingleMessage
                v-for="(item, idx) in items"
                :key="idx"
                :message="item"
                :prev="[idx == 0 ? null : items[idx - 1]]"
              />
            </div>
            <div class="w-full" v-if="load && items.length === 0">
              <div class="table m-auto">
                <div class="flex items-center justify-center flex-col gap-6">
                  <div>
                    <div
                      class="w-28 h-28 flex items-center justify-center bg-gray-100 rounded-full text-gray-500"
                    >
                      <i class="fa-solid fa-comment-slash fa-2xl"></i>
                    </div>
                  </div>
                  <div class="flex-1 space-y-2 text-center">
                    <h1
                      class="text-lg text-primary font-semibold rtl:font-bold"
                    >
                      There are no messages
                    </h1>
                    <p
                      class="text-sm text-gray-400 max-w-xl font-medium rtl:font-semibold"
                    >
                      Enter your message content and then click the send button
                      to send the message to the current user
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="space-y-2" v-if="!load">
              <div
                class="h-10 w-2/5 rounded-md bg-gray-100 animate-pulse odd:ml-auto odd:w-1/5"
                v-for="i in 4"
                :key="i"
              ></div>
            </div>
          </div>
          <div class="relative">
            <footer class="border-t bg-white border-gray-100 p-4 bottom-0 z-10">
              <form class="w-full">
                <div class="form-group flex gap-4">
                  <div>
                    <button
                      class="w-10 h-10 rounded-full bg-primary flex items-center justify-center text-white"
                      :disabled="add_disabled || !add_data.body"
                      @click.prevent="SendMessage"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="add_disabled"
                      />
                      <i class="fa-solid fa-paper-plane" v-else></i>
                    </button>
                  </div>
                  <div class="flex-1">
                    <div class="form-group">
                      <textarea
                        name="message"
                        id="message"
                        placeholder="Type your message..."
                        v-model.trim="add_data.body"
                      ></textarea>
                      <p
                        class="text-red-500 font-medium text-xs"
                        v-if="add_errors.body"
                      >
                        {{ add_errors.body.join(' ') }}
                      </p>
                    </div>
                    <div class="hidden">
                      <label
                        for="attachments"
                        class="p-2 px-3 rounded-sm bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm cursor-pointer hover:bg-gray-300 table ml-auto rtl:ml-0 rtl:mr-auto"
                      >
                        <i
                          class="fa-solid fa-paperclip mr-2 rtl:mr-0 rtl:ml-2"
                        ></i>
                        Upload attachments</label
                      >
                      <input
                        type="file"
                        name="attachments"
                        id="attachments"
                        hidden
                        aria-hidden="true"
                        class="hidden"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </footer>
          </div>
        </div>
      </main>
    </MessagesContainerWrapper>
    <transition name="scale">
      <Modal
        :close="false"
        v-if="delete_active"
        @close="delete_active = $event"
      >
        <template>
          <div class="p-8">
            <div class="flex items-center justify-center flex-col gap-6">
              <div>
                <div
                  class="w-28 h-28 flex items-center justify-center bg-gray-100 rounded-full text-gray-500"
                >
                  <i class="fa-solid fa-eraser text-5xl"></i>
                </div>
              </div>
              <div class="flex-1 space-y-2 text-center">
                <h1 class="text-lg text-primary font-semibold rtl:font-bold">
                  Delete room?
                </h1>
                <p
                  class="text-sm text-gray-400 max-w-xl font-medium rtl:font-semibold"
                >
                  If you really want to delete that room, this means that you
                  dispense with all messages in it
                </p>
                <button
                  class="w-full max-w-xs text-center py-3 px-6 rounded-full text-white bg-red-500 text-xs font-medium rtl:font-semibold shadow-xl shadow-red-500/30 !mt-6"
                  @click.prevent="delete_active = false"
                >
                  Sure, delete
                </button>
                <button
                  class="w-full max-w-xs text-center py-3 px-6 rounded-full text-gray-600 text-xs font-medium rtl:font-semibold"
                  @click.prevent="delete_active = false"
                >
                  No, cancel
                </button>
              </div>
            </div>
          </div>
        </template>
      </Modal>
    </transition>
  </div>
</template>
<script>
import Modal from '@/components/basics/Modal.vue'
import MessagesContainerWrapper from '@/components/messages/MessagesContainerWrapper.vue'
import SingleMessage from '@/components/basics/SingleMessage.vue'
export default {
  name: 'MessageIndex',
  data() {
    return {
      delete_active: false,
      add_disabled: false,
      load: false,
      add_data: {},
      add_errors: {},
      id: null,
      items: [],
      users: [],
    }
  },
  created() {
    const userId = parseInt(this.$route.params.id)
    this.$set(this.add_data, 'user_id', userId)
    this.id = userId
    this.getItems().finally(() => {
      this.ScrollChatDown()
    })
  },
  computed: {
    DisplayCurrentUserRoomData() {
      return this.users.find((e) => e?.id === this.id) || {}
    },
  },
  methods: {
    getItems() {
      return this.$store
        .dispatch('getting/getRoute', `support_messages/${this.id}`)
        .then(({ result }) => {
          this.$store.commit('chat/UPDATE_ITEMS', result.chat_messages)
          this.items = this.$store.state.chat.messages.sort(() => -1)
        })
        .catch((err) => {
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => (this.load = true))
    },
    async SendMessage() {
      this.add_disabled = true
      try {
        const { data } = await this.axios.post('support_messages/send', {
          ...this.add_data,
        })
        const chat_message = data.result?.chat_message

        this.items.push(chat_message)
        this.$store.dispatch('chat/AddNewMessage', {
          ...this.add_data,
        })

        this.$set(this.add_data, 'body', null)

        //  Scroll down when get new message
        this.ScrollChatDown()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.edit_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    ScrollChatDown() {
      const chat = this.$refs?.chatBox
      if (chat) {
        setTimeout(() => {
          chat.scrollTop = chat.scrollHeight
        }, 5)
      }
    },
    openDrop(e) {
      const icons = e.currentTarget.childNodes ?? []
      const dropDowns = document.querySelectorAll('.dropdown')
      this.multiAngle(icons)
      dropDowns.forEach((el) => {
        if (el.classList.contains('hidden')) {
          el.classList.replace('hidden', 'block')
        } else {
          el.classList.replace('block', 'hidden')
        }
      })
    },
  },
  components: { Modal, MessagesContainerWrapper, SingleMessage },
}
</script>
