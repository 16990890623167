<template>
  <div>
    <transition name="scale">
      <Modal
        :title="'Import EXCEL file'"
        v-if="show_active"
        @close="
          (e) => {
            show_active = e
            $refs.fileImported.value = null
          }
        "
      >
        <template>
          <form class="w-full space-y-6 p-6">
            <div class="w-full">
              <label
                for="attachments"
                class="w-full border-dashed border-2 border-gray-200 py-10 px-8 text-center space-y-4 text-gray-600 flex items-center justify-center flex-col rounded-md cursor-pointer"
                @dragover="
                  $event.target.classList.replace('border-dashed', 'border')
                "
                @dragleave="
                  $event.target.classList.replace('border', 'border-dashed')
                "
                @dragend="
                  $event.target.classList.replace('border', 'border-dashed')
                "
                @drop="dropControl($event)"
              >
                <h1 class="text-xl">
                  <i class="fa-solid fa-cloud-arrow-up fa-2xl"></i>
                </h1>
                <p class="font-medium rtl:font-semibold text-sm">
                  Drag or drop .excel file here.
                </p>
                <p
                  class="font-medium rtl:font-semibold text-xs text-gray-600"
                  v-if="file_name"
                >
                  {{ file_name }}
                </p>
              </label>
              <input
                type="file"
                name="attachments"
                id="attachments"
                hidden
                aria-hidden="true"
                class="hidden"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                ref="fileImported"
                @change.prevent="uploadAttachment"
              />
              <!-- @change.prevent="uploadAttachments" -->
            </div>
            <p
              class="text-red-500 font-medium text-xs mt-2"
              v-if="Object.keys(errors).length"
            >
              {{ Object.values(errors).join('<br />') }}
            </p>
            <div class="w-full p-4 rounded-md bg-gray-100 flex gap-4">
              <div>
                <div
                  class="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center"
                >
                  <i class="fa-solid fa-file-excel text-gray-600"></i>
                </div>
              </div>
              <div class="flex-1 space-y-1">
                <h1 class="font-semibold rtl:font-bold text-sm">
                  Explanatory template for the form of the required data
                </h1>
                <p class="font-medium rtl:font-semibold text-sm">
                  The following template can be downloaded to display the form
                  of the data required to import the data well by clicking on
                  the next button and then filling in the data in the same form
                  shown in the file
                </p>
                <a
                  class="table p-2 rounded-sm bg-primary text-white font-medium rtl:font-semibold text-center text-xs !mt-4"
                  href="javascript:void(0)"
                  @click.prevent="downloadTemplate"
                >
                  Download now
                </a>
              </div>
            </div>
            <div class="actions-group">
              <div>
                <!-- @click.prevent="assignUnits"
                  :disabled="add_disabled" -->
                <button
                  class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                  @click.prevent="() => $emit('click-event')"
                  :disabled="disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="disabled" />
                  Submit
                </button>
              </div>
              <div>
                <!-- @click.prevent="assignUnits"
                  :disabled="add_disabled" -->
                <button
                  class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-gray-600 bg-gray-200 table"
                  @click.prevent="
                    () => {
                      $refs.fileImported.value = null
                      show_active = false
                    }
                  "
                >
                  <!-- <spinner size="w-4 h-4 inline-block" v-if="add_disabled" /> -->
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
  </div>
</template>

<script>
import Modal from '@/components/basics/Modal'
export default {
  name: 'ImportFile',
  components: {
    Modal,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      default: Object,
      type: Object,
    },
  },
  data() {
    return {
      show_active: false,
      file_name: null,
    }
  },
  mounted() {
    ;[
      'drop',
      'dragstart',
      'dragend',
      'dragenter',
      'dragleave',
      'dragover',
    ].forEach((event) => {
      window.addEventListener(event, (e) => e.preventDefault())
    })
  },

  methods: {
    show() {
      this.show_active = true
    },
    hide() {
      this.show_active = false
      this.file_name = null
      this.$emit('uploaded-file', null)
    },
    dropControl(e) {
      e.preventDefault()
      const files = e.dataTransfer.files
      e.currentTarget.classList.replace('border', 'border-dashed')
      console.log(files)
      if (!files.length) {
        this.createAlert(
          'There is something went wrong while uploading file',
          'error'
        )
        return
      }
      this.$emit('uploaded-file', files[0])
      this.file_name = files[0]?.name
    },
    uploadAttachment(e) {
      e.preventDefault()
      const files = e.target.files
      if (!files.length) {
        this.createAlert(
          'There is something went wrong while uploading file',
          'error'
        )
        return
      }
      this.$emit('uploaded-file', files[0])
      this.file_name = files[0]?.name
    },
    downloadTemplate() {
      let link = document.createElement('a')
      link.href = `./${this.$route.meta.template_name}`
      link.download = this.$route.meta.template_name
      link.click()
    },
  },
}
</script>
