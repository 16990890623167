<template>
  <div class="space-y-6">
    <div class="px-6">
      <tabs :link_type="'link'" :tabs="tabs" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Tabs from './basics/Tabs.vue'
export default {
  components: { Tabs },
  name: 'WebsiteTans',
  data() {
    return {
      tabs: [
        {
          url: '/website/clients',
          title: 'Clients',
          key: 'clients',
        },
        {
          url: '/website/partners',
          title: 'Partners',
          key: 'partners',
        },
        {
          url: '/website/pages',
          title: 'Pages',
          key: 'pages',
        },
      ],
    }
  },
}
</script>
