<template>
  <div>
    <container-wrapper class="px-6">
      <div class="space-y-6">
        <div>
          <Tabs :link_type="'link'" :tabs="pagesTab" />
        </div>
        <slot></slot>
      </div>
    </container-wrapper>
  </div>
</template>

<script>
import Tabs from '@/components/basics/Tabs.vue'
import ContainerWrapper from './ContainerWrapper.vue'
export default {
  name: 'ContainerTabWrapper',
  computed: {
    companyId() {
      return this.$route.params?.id
    },
  },
  data() {
    return {
      pagesTab: [],
    }
  },
  created() {
    this.pagesTab = [
      {
        url: `/companies/${this.companyId}/users`,
        title: 'Users',
        key: 'users',
      },
      {
        url: `/companies/${this.companyId}/client-users`,
        title: 'Client users',
        key: 'client_users',
      },
      {
        url: `/companies/${this.companyId}/clients`,
        title: 'Clients',
        key: 'clients',
      },
      {
        url: `/companies/${this.companyId}/merchandisers`,
        title: 'Merchandisers',
        key: 'merchandisers',
      },
      {
        url: `/companies/${this.companyId}/supervisors`,
        title: 'Supervisors',
        key: 'supervisors',
      },
    ]
  },
  components: {
    Tabs,
    ContainerWrapper,
  },
}
</script>
