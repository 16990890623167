import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import HomePage from '@/views/Home'
import LoginPage from '@/views/Login'
import Profile from '@/views/account/Profile'
import MessageIndex from '@/views/messages/index'
import SingleMessage from '@/views/messages/_id'
import Stores from '@/views/Stores'
import Branches from '@/views/Branches'
import Roles from '@/views/setup/Roles'
import Notifications from '@/views/Notifications'
import Tasks from '@/views/setup/Tasks'
import Countries from '@/views/setup/location/Countries'
import Regions from '@/views/setup/location/Regions'
import RegionGroups from '@/views/setup/location/RegionGroups'
import DistrictGroup from '@/views/setup/location/DistrictGroup'
import Cities from '@/views/setup/location/Cities'
import Districts from '@/views/setup/location/Districts'
import Admins from '@/views/setup/Admins'
import Tutorials from '@/views/setup/Tutorials'
import Settings from '@/views/setup/Settings'
import EventLogs from '@/views/setup/EventLogs'
import Companies from '@/views/companies/index'
import CompaniesClients from '@/views/companies/_id/Clients'
import CompaniesMerchandisers from '@/views/companies/_id/Merchandisers'
import CompaniesSupervisors from '@/views/companies/_id/Supervisors'
import CompaniesSuperusers from '@/views/companies/_id/SuperUsers'
import WClient from '@/views/websites/clients'
import Partners from '@/views/websites/partners'
import Pages from '@/views/websites/pages'
import ClientUsers from '@/views/companies/_id/ClientUsers'
import Error from '@/views/Error'

// middleware
import auth from '@/middleware/auth'
import hasAccess from '@/middleware/hasAccess'
import checkCompany from '@/middleware/checkCompany'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,

    meta: {
      middleware: [auth, hasAccess],
      title: 'home',
      permission: 'show dashboard',
    },
  },

  {
    path: '/companies',
    name: 'Companies',
    component: Companies,
    meta: {
      middleware: [auth, hasAccess],
      title: 'companies',
      permission: 'show companies',
    },
  },

  {
    path: '/companies/:id/clients',
    name: 'CompaniesClients',
    component: CompaniesClients,
    meta: {
      middleware: [auth, hasAccess, checkCompany],
      title: 'clients',
      permission: 'show companies',
    },
  },
  {
    path: '/companies/:id/merchandisers',
    name: 'CompaniesMerchandisers',
    component: CompaniesMerchandisers,
    meta: {
      middleware: [auth, hasAccess, checkCompany],
      title: 'merchandisers',
      permission: 'show companies',
    },
  },
  {
    path: '/companies/:id/supervisors',
    name: 'CompaniesSupervisors',
    component: CompaniesSupervisors,
    meta: {
      middleware: [auth, hasAccess, checkCompany],
      title: 'supervisors',
      permission: 'show companies',
    },
  },
  {
    path: '/companies/:id/users',
    name: 'CompaniesSuperusers',
    component: CompaniesSuperusers,
    meta: {
      middleware: [auth, hasAccess, checkCompany],
      title: 'users',
      permission: 'show companies',
    },
  },
  {
    path: '/companies/:id/client-users',
    name: 'ClientUsers',
    component: ClientUsers,
    meta: {
      middleware: [auth, hasAccess, checkCompany],
      title: 'client_users',
      permission: 'show companies',
    },
  },

  {
    path: '/website/clients',
    name: 'WClient',
    component: WClient,
    meta: {
      middleware: [auth, hasAccess],
      title: 'clients',
      permission: 'show website',
    },
  },
  {
    path: '/website/pages',
    name: 'Pages',
    component: Pages,
    meta: {
      middleware: [auth, hasAccess],
      title: 'pages',
      permission: 'show website',
    },
  },
  {
    path: '/website/partners',
    name: 'Partners',
    component: Partners,
    meta: {
      middleware: [auth, hasAccess],
      title: 'partners',
      permission: 'show website',
    },
  },
  {
    path: '/messages',
    name: 'MessageIndex',
    component: MessageIndex,
    meta: {
      middleware: [auth, hasAccess],
      title: 'messages',
      permission: 'show messages',
    },
  },
  {
    path: '/messages/:id',
    name: 'SingleMessage',
    component: SingleMessage,
    meta: {
      middleware: [auth, hasAccess],
      title: 'messages',
      permission: 'show messages',
    },
  },

  {
    path: '/stores',
    name: 'Stores',
    component: Stores,
    meta: {
      middleware: [auth, hasAccess],
      title: 'stores',
      permission: 'show stores',
      end_point: 'stores',
      template_name: 'stores-template.xlsx',
    },
  },
  {
    path: '/branches',
    name: 'Branches',
    component: Branches,
    meta: {
      middleware: [auth, hasAccess],
      title: 'branches',
      permission: 'show branches',
      end_point: 'branches',
      template_name: 'branches-template.xlsx',
    },
  },

  {
    path: '/setup/tasks',
    name: 'Tasks',
    component: Tasks,
    meta: {
      middleware: [auth, hasAccess],
      title: 'tasks',
      permission: 'show tasks',
      end_point: 'tasks',
    },
  },

  {
    path: '/setup/countries',
    name: 'Countries',
    component: Countries,
    meta: {
      middleware: [auth, hasAccess],
      title: 'countries',
      permission: 'show countries',
      end_point: 'countries',
    },
  },
  {
    path: '/setup/admins',
    name: 'Admins',
    component: Admins,
    meta: {
      middleware: [auth, hasAccess],
      title: 'admins',
      permission: 'show admins',
      end_point: 'admins',
    },
  },
  {
    path: '/setup/tutorials',
    name: 'Tutorials',
    component: Tutorials,
    meta: {
      middleware: [auth, hasAccess],
      title: 'tutorials',
      permission: 'show tutorials',
      end_point: 'tutorials',
    },
  },
  {
    path: '/setup/logs',
    name: 'EventLogs',
    component: EventLogs,
    meta: {
      middleware: [auth, hasAccess],
      title: 'logs',
      permission: 'show logs',
      end_point: 'logs',
    },
  },
  {
    path: '/setup/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      middleware: [auth, hasAccess],
      title: 'settings',
      permission: 'show settings',
      end_point: 'settings',
    },
  },
  {
    path: '/setup/regions',
    name: 'Regions',
    component: Regions,
    meta: {
      middleware: [auth, hasAccess],
      title: 'regions',
      permission: 'show regions',
      end_point: 'regions',
    },
  },
  {
    path: '/setup/region-groups',
    name: 'RegionGroups',
    component: RegionGroups,
    meta: {
      middleware: [auth, hasAccess],
      title: 'region_groups',
      permission: 'show region_groups',
      end_point: 'region_groups',
    },
  },
  {
    path: '/setup/district-group',
    name: 'DistrictGroup',
    component: DistrictGroup,
    meta: {
      middleware: [auth, hasAccess],
      title: 'district_group',
      permission: 'show district_groups',
      end_point: 'district_group',
    },
  },
  {
    path: '/setup/cities',
    name: 'Cities',
    component: Cities,
    meta: {
      middleware: [auth, hasAccess],
      title: 'cities',
      permission: 'show cities',
      end_point: 'cities',
    },
  },
  {
    path: '/setup/districts',
    name: 'Districts',
    component: Districts,
    meta: {
      middleware: [auth, hasAccess],
      title: 'districts',
      permission: 'show districts',
      end_point: 'districts',
    },
  },
  {
    path: '/setup/roles',
    name: 'Roles',
    component: Roles,
    meta: {
      middleware: [auth, hasAccess],
      title: 'roles',
      permission: 'show roles',
    },
  },

  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    meta: {
      middleware: [auth, hasAccess],
      title: 'notifications',
      permission: 'notifications',
    },
  },
  {
    path: '*',
    name: 'Error',
    component: Error,
    meta: {
      middleware: [auth, hasAccess],
      title: 'error',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      middleware: [auth],
      title: 'profile',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      middleware: [auth],
      title: 'login',
    },
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return document
        .querySelector(to.hash)
        .scrollIntoView({ behavior: 'smooth' })
    } else {
      return savedPosition || { x: 0, y: 0 }
    }
  },
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters)
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      to,
      store,
      routes,
    }
    const nextMiddleware = nextFactory(context, middleware, 1)
    return middleware[0]({ ...context, next: nextMiddleware })
  } else {
    return next()
  }
})
export default router
