<template>
  <section class="w-full space-y-6">
    <container-tab-wrapper>
      <main class="w-full">
        <Table
          :has_header="true"
          :has_check="false"
          :title="$t('users')"
          :records="`${pagination.total || 0} ${$t('records')}`"
          :pagination="pagination"
          :total_pages="pagination.total_pages"
          v-model="pagination.page"
          :is_loading="is_loading"
        >
          <template #filter>
            <ul class="flex items-center">
              <li v-for="(item, index) in status" :key="index">
                <button
                  class="
                    p-4
                    text-center
                    font-medium
                    rtl:font-semibold
                    text-sm
                    capitalize
                  "
                  @click.prevent="
                    changeFilterStatus(item === 'all' ? null : item, index)
                  "
                  :class="{
                    'active-tab':
                      pagination.is_active === (item === 'all' ? null : item),
                  }"
                  :disabled="disables[index]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    class="mr-2 rtl:mr-0 rtl:ml-2"
                    v-if="disables[index]"
                  />
                  {{ $t(item.replace(/\s/g, '_')) }}
                </button>
              </li>
            </ul>
          </template>
          <template #head>
            <th>{{ $t('name') }}</th>
            <th>{{ $t('username') }}</th>

            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('mobile') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('mobile')"
                  ></button>
                </div>
              </div>
            </th>

            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('active') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('is_active')"
                  ></button>
                </div>
              </div>
            </th>
            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('updated_at') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('updated_at')"
                  ></button>
                </div>
              </div>
            </th>

            <th></th>
          </template>
          <template #tbody v-if="!load">
            <TableLoad :rows="6" v-for="i in 6" :key="i" />
          </template>
          <template #tbody v-else-if="load && items.length !== 0">
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ item.name || '...' }}</td>
              <td>
                <a
                  :href="`mailto:${item.username}`"
                  class="text-sky-500"
                  v-if="item.username"
                  >{{ item.username }}</a
                >
                <span v-else>-</span>
              </td>
              <td>
                <a
                  :href="`tel:${item.mobile}`"
                  class="text-sky-500"
                  v-if="item.mobile"
                  >{{ item.mobile }}</a
                >
                <span v-else>-</span>
              </td>

              <td>
                <p
                  class="status capitalize"
                  :class="item_status[item.is_active]"
                >
                  {{ item.is_active === 1 ? $t('active') : $t('not_active') }}
                </p>
              </td>
              <td v-html="createTime(item.updated_at || item.created_at)"></td>

              <td>
                <div class="actions-group">
                  <div>
                    <button
                      class="p-1.5 text-red-500 font-medium rtl:font-semibold"
                      @click.prevent="deleteItem(item.id)"
                      :disabled="disables[`delete_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`delete_${item.id}`]"
                      />
                      <span v-else> {{ $t('delete') }} </span>
                    </button>
                  </div>

                  <div>
                    <button
                      class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded-sm"
                      @click.prevent="changeStatus(item.id)"
                      :content="$t('change_status')"
                      v-tippy
                      :disabled="disables[`status_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`status_${item.id}`]"
                      />
                      <i class="fa-solid fa-right-left" v-else></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template #tbody v-else-if="load && items.length === 0">
            <tr>
              <td :colspan="6">
                <p
                  class="
                    text-sm text-gray-600
                    font-medium
                    rtl:font-semibold
                    text-center
                  "
                >
                  {{ $t('no_data') }}
                </p>
              </td>
            </tr>
          </template>
        </Table>
      </main>
    </container-tab-wrapper>
  </section>
</template>

<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import Modal from '@/components/basics/Modal.vue'
import ContainerTabWrapper from '@/components/companies/users/ContainerTabWrapper.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'SuperUsers',
  components: {
    Table,
    TableLoad,
    Modal,
    ContainerTabWrapper,
  },
  data() {
    return {
      load: false,
      items: [],
      pagination: {
        page: 1,
        is_active: null,
      },
      is_loading: false,
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
      status: ['all', 'active', 'not active'],
      disables: {},
    }
  },
  computed: {
    ...mapGetters('table', ['search', 'is_called']),
  },
  watch: {
    is_called(val) {
      if (val) this.getItems()
    },
  },
  async created() {
    await this.getItems()
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled']),
    async getItems() {
      const { page, search_key, sort_key, sort_type, is_active } =
        this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'users',
          options: {
            page,
            search_key: this.search,
            sort_key,
            sort_type,
            is_active: is_active ? (is_active === 'active' ? 1 : 0) : is_active,
            company_id: this.$route.params.id,
            role_type: 'super_user',
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.users
          this.items = data.map((e) => {
            return {
              ...e,
            }
          })
          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
        })
        .catch((err) => {
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => {
          this.load = true
          this.changeIsCalled(false)
        })
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true)
      try {
        const { data } = await this.axios.post(`users/update_is_active/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)
      try {
        const { isConfirmed } = await this.deleteAlert();
        if (!isConfirmed) return
        const { data } = await this.axios.post(`users/delete/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      this.$set(this.pagination, 'is_active', status)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
  },
}
</script>
