<template>
  <div class="sticky top-0 z-[21]">
    <header class="w-full bg-white border-b border-gray-200 py-4 px-6">
      <div class="relative" data-type="centered-item">
        <div class="w-full flex items-center gap-2">
          <div>
            <button
              class="
                w-10
                h-10
                flex
                items-center
                justify-center
                text-gray-600
                bg-gray-50
                hover:bg-gray-100
                rounded
                relative
              "
              @click.prevent="openCloseSide"
            >
              <i class="fa-solid fa-bars"></i>
            </button>
          </div>
          <div v-can="'dashboard'">
            <button
              class="
                w-10
                h-10
                flex
                items-center
                justify-center
                text-gray-600
                bg-gray-50
                hover:bg-gray-100
                rounded
                relative
              "
              @click.prevent="notify_active = !notify_active"
              v-click-outside="() => (notify_active = false)"
            >
              <i class="fa-solid fa-bell"></i>
              <span
                class="
                  absolute
                  -top-1
                  -right-2
                  rtl:-left-2 rtl:right-auto
                  bg-secondary
                  text-white text-[10px]
                  py-0.5
                  px-1.5
                  rounded-full
                  ring-2 ring-white
                  font-medium
                "
                >0</span
              >
            </button>
          </div>
          <div v-can="'dashboard'">
            <button
              class="
                w-10
                h-10
                flex
                items-center
                justify-center
                text-gray-600
                bg-gray-50
                hover:bg-gray-100
                rounded
                relative
              "
              @click.prevent="msg_active = !msg_active"
              v-click-outside="() => (msg_active = false)"
            >
              <i class="fa-solid fa-message"></i>
              <span
                class="
                  absolute
                  -top-1
                  -right-2
                  rtl:-left-2 rtl:right-auto
                  bg-secondary
                  text-white text-[10px]
                  py-0.5
                  px-1.5
                  rounded-full
                  ring-2 ring-white
                  font-medium
                "
                >{{ messages.length > 100 ? `+99` : messages.length }}</span
              >
            </button>
          </div>

          <div class="relative">
            <button
              class="
                py-2
                px-3
                rounded-md
                bg-gray-50
                hover:bg-gray-100
                text-sm
                font-medium
                rtl:font-semibold
              "
              @click.prevent="lang_active = !lang_active"
              v-click-outside="() => (lang_active = false)"
            >
              <div class="w-full gap-3 sm:flex items-center hidden">
                <i
                  class="
                    fa-solid fa-angle-down fa-xs
                    transition-transform
                    ease-linear
                  "
                  :class="{ 'rotate-180': lang_active }"
                ></i>
                <span>{{ $t(`langs.${$i18n.locale}`) }}</span>
              </div>
              <div class="block sm:hidden">
                <i class="fa-solid fa-globe fa-sm text-gray-600"></i>
              </div>
            </button>
            <transition name="move">
              <div
                v-if="lang_active"
                class="
                  w-full
                  max-w-xs
                  min-w-[13rem]
                  bg-white
                  shadow-sm
                  absolute
                  top-full
                  left-0
                  rtl:right-0 rtl:left-auto
                  mt-4
                  ring-1 ring-gray-100
                  z-[5]
                "
              >
                <ul class="list-none">
                  <li
                    v-for="(item, index) in langs"
                    :key="index"
                    class="border-b border-gray-100 last:border-0"
                  >
                    <button
                      class="
                        w-full
                        p-3
                        flex
                        items-center
                        gap-4
                        font-medium
                        rtl:font-semibold
                        text-sm
                        hover:bg-gray-100
                      "
                      @click.prevent="changeLang(item.lang)"
                    >
                      <span
                        :class="`fi-${item.flag} w-5 h-4 bg-contain bg-no-repeat`"
                      ></span>
                      <span>{{ $t(`langs.${item.lang}`) }}</span>
                    </button>
                  </li>
                </ul>
              </div>
            </transition>
          </div>

          <div class="flex-1"></div>
          <div>
            <div
              class="flex gap-4 items-center"
              v-if="Object.keys(user).length !== 0"
            >
              <div>
                <div
                  class="w-9 h-9"
                  @click.prevent="profile_active = !profile_active"
                >
                  <img
                    :src="user.image || require('@/assets/images/profile.jpg')"
                    @error="
                      (e) =>
                        (e.target.src = require('@/assets/images/profile.jpg'))
                    "
                    alt="profile image"
                    class="
                      w-full
                      h-full
                      object-cover
                      rounded-full
                      ring-1 ring-gray-200
                    "
                  />
                </div>
              </div>
              <div class="flex-1 max-w-xs space-y-0.5 hidden sm:grid">
                <h1 class="text-xs font-semibold">{{ user.name }}</h1>
                <a
                  :href="`tel:${user.mobile}`"
                  v-if="user.mobile"
                  class="text-xs font-medium text-gray-600 block truncate"
                  >{{ user.mobile }}</a
                >
                <a
                  :href="`mailto:${user.email}`"
                  v-else
                  class="text-xs font-medium text-gray-600 block truncate"
                  >{{ user.email }}</a
                >
              </div>
              <div class="hidden sm:block">
                <button
                  class="
                    w-8
                    h-8
                    rounded-full
                    bg-gray-100
                    hover:bg-gray-200
                    text-gray-600
                  "
                  @click.prevent="profile_active = !profile_active"
                >
                  <i
                    class="
                      fa-solid fa-angle-down fa-sm
                      align-middle
                      transition-all
                    "
                    :class="{
                      'rotate-180': profile_active,
                    }"
                  ></i>
                </button>
              </div>
            </div>
            <div class="flex gap-4 items-center animate-pulse" v-else>
              <div>
                <div class="w-9 h-9 rounded-full bg-gray-200"></div>
              </div>
              <div
                class="
                  flex-1
                  max-w-xs
                  space-y-2
                  hidden
                  sm:block sm:min-w-[12rem]
                "
              >
                <div class="w-2/3 h-4 rounded-sm bg-gray-200"></div>
                <div class="w-full h-3 rounded-sm bg-gray-100"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- profile dropdown -->
        <transition name="move">
          <figure
            class="
              w-full
              absolute
              top-[calc(100%+1.5rem)]
              bg-white
              max-w-[14rem]
              ring-1 ring-gray-200
              right-0
              rtl:right-auto rtl:left-0
              flex flex-col
              max-h-[calc(100vh-11rem)]
              rounded-md
              shadow-xl shadow-gray-600/[0.05]
            "
            v-if="profile_active"
          >
            <blockquote class="flex-1 overflow-y-auto">
              <ul class="list-none p-2">
                <li class="text-sm">
                  <router-link
                    to="/profile"
                    class="
                      w-full
                      px-3
                      py-2
                      flex
                      rounded-md
                      items-center
                      gap-4
                      text-gray-500
                      group
                      hover:bg-gray-50
                    "
                  >
                    <div>
                      <i class="fa-solid fa-user fa-sm align-middle"></i>
                    </div>
                    <div class="flex-1 grid">
                      <p class="text-sm font-medium rtl:font-semibold truncate">
                        {{ $t('edit_profile') }}
                      </p>
                    </div>
                    <div>
                      <p class="status" :class="profile_status[user.status]">
                        {{ user.status }}
                      </p>
                    </div>
                  </router-link>
                </li>
                <li class="text-sm">
                  <a
                    href="javascript:void(0)"
                    class="
                      w-full
                      px-3
                      py-2
                      flex
                      rounded-md
                      items-center
                      gap-4
                      text-gray-500
                      group
                      hover:bg-gray-50
                    "
                    @click.prevent="checkWindow"
                  >
                    <div>
                      <i
                        class="fa-solid fa-window-restore fa-sm align-middle"
                      ></i>
                    </div>
                    <div class="flex-1 grid">
                      <p class="text-sm font-medium rtl:font-semibold truncate">
                        {{ $t('window_width') }} (
                        {{ $store.state.window_width || 'Full width' }} )
                      </p>
                    </div>
                  </a>
                </li>

                <li class="text-sm pt-2 mt-2 border-t border-gray-100">
                  <a
                    href="#"
                    class="
                      w-full
                      px-3
                      py-2
                      flex
                      rounded-md
                      items-center
                      gap-4
                      text-gray-500
                      group
                      hover:bg-gray-50
                    "
                    @click.prevent="logout"
                  >
                    <div>
                      <i
                        class="
                          fa-solid fa-arrow-right-from-bracket fa-sm
                          align-middle
                        "
                      ></i>
                    </div>
                    <div class="flex-1 grid">
                      <p class="text-sm font-medium rtl:font-semibold truncate">
                        {{ $t('logout') }}
                      </p>
                    </div>
                  </a>
                </li>
              </ul>
            </blockquote>
          </figure>
        </transition>
        <!-- ./profile dropdown -->
        <!-- messages -->
        <transition name="move">
          <figure
            class="
              w-full
              absolute
              top-full
              sm:top-[calc(100%+1rem)]
              bg-white
              max-w-full
              xsm:max-w-xs
              ring-1 ring-gray-200
              left-0
              sm:left-[5.25rem]
              rtl:left-auto rtl:right-0
              sm:rtl:right-6
              flex flex-col
              max-h-[calc(100vh-11rem)]
              rounded
            "
            v-if="msg_active"
          >
            <header
              class="
                px-4
                py-3
                border-b border-gray-100
                flex
                items-center
                gap-2
                w-full
              "
            >
              <div class="flex-1">
                <p class="text-gray-600 font-semibold rtl:font-bold">
                  {{ $t('messages') }}
                  <span
                    class="
                      py-1
                      px-1.5
                      inline-block
                      rounded-full
                      bg-gray-100
                      text-xs
                      font-semibold
                    "
                    >{{ messages.length > 100 ? `+99` : messages.length }}</span
                  >
                </p>
              </div>
              <div>
                <button
                  class="py-1 p-3 text-gray-600"
                  @click.prevent="msg_active = false"
                >
                  <i class="fa-solid fa-times"></i>
                </button>
              </div>
            </header>
            <blockquote class="flex-1 overflow-y-auto">
              <ul class="list-none divide-y divide-gray-100">
                <li
                  class="w-full p-4"
                  v-for="(item, index) in messages"
                  :key="index"
                >
                  <router-link
                    class="flex items-center gap-4"
                    :to="`/messages/${item.id}`"
                  >
                    <figure class="w-full">
                      <figcaption class="w-full">
                        <div class="flex items-center">
                          <div>
                            <div class="w-10 h-10">
                              <img
                                src="@/assets/images/profile.jpg"
                                alt="user profile image"
                                class="
                                  w-full
                                  h-full
                                  rounded-full
                                  object-cover
                                  border border-gray-200
                                  block
                                  sticky
                                  top-20
                                "
                              />
                            </div>
                          </div>
                          <div class="px-4 space-y-1 flex-1">
                            <div class="table">
                              <div class="grid">
                                <p
                                  class="
                                    text-sm text-primary
                                    font-medium
                                    rtl:font-semibold
                                    table
                                    truncate
                                  "
                                >
                                  {{ item.name }}
                                </p>
                                <a
                                  :href="`mailto:${item.email}`"
                                  class="
                                    text-xs text-gray-400
                                    block
                                    font-medium
                                    rtl:font-semibold
                                    truncate
                                  "
                                  >{{ item.email }}</a
                                >
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              class="
                                w-8
                                h-8
                                rounded-full
                                flex
                                items-center
                                justify-center
                                bg-primary/10
                                text-primary
                                group-hover:bg-primary/20
                              "
                            >
                              <i
                                class="
                                  fa-solid fa-angle-right
                                  block
                                  rtl:hidden
                                  fa-sm
                                  align-middle
                                "
                              ></i>
                              <i
                                class="
                                  fa-solid fa-angle-left
                                  hidden
                                  rtl:block
                                  fa-sm
                                  align-middle
                                "
                              ></i>
                            </button>
                          </div>
                        </div>
                      </figcaption>
                      <blockquote class="space-y-1 pt-4 px-2">
                        <p
                          class="
                            text-sm text-gray-600
                            font-medium
                            rtl:font-semibold
                            line-clamp-2
                          "
                          v-html="GenerateContent(item.last_msg)"
                        ></p>
                        <p
                          class="
                            text-xs text-gray-400
                            font-medium
                            rtl:font-semibold
                            line-clamp-2
                          "
                        >
                          {{ item.created_at | moment('h:ma , ddd MMM-yyyy') }}
                        </p>
                      </blockquote>
                    </figure>
                  </router-link>
                </li>
                <li v-if="!messages.length" class="p-4">
                  <div class="flex items-center justify-center flex-col gap-6">
                    <div>
                      <div
                        class="
                          w-16
                          h-16
                          flex
                          items-center
                          justify-center
                          bg-gray-100
                          rounded-full
                          text-gray-500
                        "
                      >
                        <i class="fa-solid fa-message"></i>
                      </div>
                    </div>
                    <div class="flex-1 space-y-4 text-center">
                      <h1 class="text-gray-600 font-bold text-sm">
                        There are no messages to display
                      </h1>
                    </div>
                  </div>
                </li>
              </ul>
            </blockquote>
            <footer class="px-4 py-3 bg-gray-100">
              <router-link
                to="/messages"
                class="text-primary font-semibold rtl:font-bold text-xs"
              >
                {{ $t('view_all') }}
              </router-link>
            </footer>
          </figure>
        </transition>
        <!-- ./messages -->
        <!-- notifications -->
        <transition name="move">
          <figure
            class="
              w-full
              absolute
              top-full
              sm:top-[calc(100%+1.5rem)]
              bg-white
              max-w-full
              xsm:max-w-xs
              ring-1 ring-gray-200
              left-0
              sm:left-[5.25rem]
              rtl:left-auto rtl:right-0
              sm:rtl:right-6
              flex flex-col
              max-h-[calc(100vh-11rem)]
            "
            v-if="notify_active"
          >
            <header
              class="
                px-4
                py-3
                border-b border-gray-100
                flex
                items-center
                gap-2
                w-full
              "
            >
              <div class="flex-1">
                <p class="text-gray-600 font-semibold rtl:font-bold">
                  {{ $t('notifications') }}
                  <span
                    class="
                      py-1
                      px-1.5
                      inline-block
                      rounded-full
                      bg-gray-100
                      text-xs
                      font-semibold
                    "
                    >+99</span
                  >
                </p>
              </div>
              <div>
                <button
                  class="py-1 p-3 text-gray-600"
                  @click.prevent="notify_active = false"
                >
                  <i class="fa-solid fa-times"></i>
                </button>
              </div>
            </header>
            <blockquote class="flex-1 overflow-y-auto">
              <ul class="list-none">
                <!-- <li
                  class="w-full border-b border-gray-100 last:border-0 p-4"
                  v-for="i in 10"
                  :key="i"
                >
                  <div class="flex-1 grid gap-1">
                    <h1 class="text-primary font-semibold text-sm">
                      Notification title
                    </h1>
                    <div class="flex items-center gap-2">
                      <div class="flex-1 grid">
                        <p
                          class="text-xs text-gray-600 font-medium rtl:font-semibold block truncate"
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Fuga voluptates incidunt temporibus doloremque.
                          Quod sit ipsa ducimus voluptatum? Minima iure in
                          ipsam? Nam deserunt, dolorem neque unde quidem
                          consequatur ipsa.
                        </p>
                      </div>
                      <div>
                        <p class="text-xs text-gray-400 font-semibold">
                          11:55AM
                        </p>
                      </div>
                    </div>
                  </div>
                </li> -->
              </ul>
            </blockquote>
            <footer class="px-4 py-3 bg-gray-100">
              <router-link
                to="/notifications"
                class="text-primary font-semibold rtl:font-bold text-xs"
              >
                {{ $t('view_all') }}
              </router-link>
            </footer>
          </figure>
        </transition>
        <!-- ./notifications -->
      </div>
    </header>

    <!-- sidebar -->
    <side-bar ref="sideBar" />
    <!-- ./sidebar -->
  </div>
</template>
<script>
import SideBar from '@/components/basics/SideBar.vue'
export default {
  data() {
    return {
      tree: null,
      msg_active: false,
      notify_active: false,
      profile_active: false,
      lang_active: false,
      contract_disabled: false,
      langs: [],
      profile_status: {
        contract: 'bg-gray-600/10 text-gray-600',
        is_ready: 'bg-sky-600/10 text-sky-600',
        join_request: 'bg-yellow-600/10 text-yellow-600',
        rejected: 'bg-red-600/10 text-red-600',
        on: 'bg-blue-600/10 text-blue-600',
        off: 'bg-red-500/10 text-red-500',
      },
      notifications: [],
      notify_load: false,
      last_messages: [],
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user_info
    },
    currentCart() {
      const { id } = this.$store.state.auth.user_info
      return this.$store.getters['customize/currentCart'](id) || []
    },
    messages() {
      const { data } = this.$store.getters['chat/getUsers']
      // return [...data, ...this.last_messages]
      return data
    },
    GenerateContent() {
      return (text) =>
        text.replace(
          /(?:(?:https?:\/\/)|(?:www\.))[^\s]+(?!^(.+?)([.,?!'"]*)$)/gi,
          (e) =>
            `<a href="${e.replace(
              /['|"]$/gi,
              ''
            )}" class="text-primary underline">${e}</a>`
        )
    },
  },
  created() {
    const mapLanguages = this.$i18n.availableLocales.map((e) => {
      const iso = { ar: 'sa', en: 'us' }
      return { lang: e, flag: iso[e] }
    })
    this.langs = mapLanguages
  },
  watch: {
    $route() {
      // this.openCloseSide()
      this.notify_active = false
      this.msg_active = false
      this.profile_active = false
      this.lang_active = false
    },
    msg_active() {
      if (this.msg_active) {
        this.notify_active = false
      }
    },
    notify_active() {
      if (this.notify_active) {
        this.msg_active = false
      }
    },
  },
  mounted() {
    this.checkWindow()
  },
  methods: {
    openCloseSide() {
      const sidebar = this.$refs.sideBar?.$el
      if (sidebar.classList.contains('side-active')) {
        sidebar.classList.remove('side-active')
        this.tree = null
      } else {
        sidebar.classList.add('side-active')
      }
    },
    checkWindow(e) {
      const windowWidthType = localStorage.getItem('window')
      // console.log(windowWidthType)
      // if (!windowWidthType) {
      //   localStorage.setItem('window', 'full')
      //   this.$store.commit('SET_WIDTH', 'full')
      //   return
      // }
      if (e?.type === 'click') {
        localStorage.setItem(
          'window',
          windowWidthType === 'full' ? 'center' : 'full'
        )
      }
      const windowWidth = localStorage.getItem('window')
      // this.checkWindow()
      this.$store.commit('SET_WIDTH', windowWidth)
      const containers = document.querySelectorAll(
        '[data-type="centered-item"]'
      )
      containers.forEach((elem) => {
        elem.classList.add(windowWidth === 'full' ? 'w-full' : 'xl:container')
        elem.classList.remove(
          windowWidth === 'full' ? 'xl:container' : 'w-full'
        )
      })
    },
  },
  components: { SideBar },
}
</script>
