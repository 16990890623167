var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"w-full px-6"},[_c('Table',{attrs:{"has_header":true,"has_check":false,"has_customize":false,"title":_vm.$t('logs'),"records":((_vm.pagination.total || 0) + " " + (_vm.$t('records'))),"pagination":_vm.pagination,"total_pages":_vm.pagination.total_pages},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('th',[_vm._v(_vm._s(_vm.$t('status')))]),_c('th',[_vm._v("IP")]),_c('th',[_vm._v("Method")]),_c('th',[_vm._v("Details")])]},proxy:true},(!_vm.load)?{key:"tbody",fn:function(){return _vm._l((6),function(i){return _c('TableLoad',{key:i,attrs:{"rows":3}})})},proxy:true}:(_vm.load && _vm.items.length !== 0)?{key:"tbody",fn:function(){return _vm._l((_vm.items),function(item,index){return _c('tr',{key:index},[_c('td',[_c('p',[_vm._v(_vm._s(item.status))])]),_c('td',[_vm._v(" "+_vm._s(item.ip || '-')+" ")]),_c('td',[_vm._v(" "+_vm._s(item.request_method || '-')+" ")]),_c('td',[_c('div',{staticClass:"actions-group"},[_c('div',[_c('button',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"w-8 h-8 bg-sky-500 text-white rounded",attrs:{"content":"View"},on:{"click":function($event){$event.preventDefault();return _vm.showDetails(item.id)}}},[_c('i',{staticClass:"fa-solid fa-eye"})])])])])])})},proxy:true}:(_vm.load && _vm.items.length === 0)?{key:"tbody",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":3}},[_c('p',{staticClass:"text-sm text-slate-600 font-medium rtl:font-semibold text-center"},[_vm._v(" "+_vm._s(_vm.$t('no_data'))+" ")])])])]},proxy:true}:null],null,true),model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1),_c('transition',{attrs:{"name":"scale"}},[(_vm.show_active)?_c('modal',{attrs:{"title":(_vm.view_details.causer || {}).name || _vm.$t('view_details'),"size":"!max-w-screen-md"},on:{"close":function (e) {
          _vm.show_active = e
        }}},[[_c('div',{staticClass:"overflow-x-auto"},[_c('table',{staticClass:"styled-table"},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Request URL")]),_c('td',[_vm._v(_vm._s(_vm.view_details.request_url))])]),_c('tr',[_c('td',[_vm._v("Body")]),_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(
                      JSON.stringify(JSON.parse(_vm.view_details.body), null, 2)
                    )}})])]),_c('tr',[_c('td',[_vm._v("User agent")]),_c('td',[_vm._v(_vm._s(_vm.view_details.user_agent))])]),_c('tr',[_c('td',[_vm._v("Response")]),_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(
                      JSON.stringify(
                        JSON.parse(_vm.view_details.response),
                        null,
                        2
                      )
                    )}})])]),_c('tr',[_c('td',[_vm._v("Created date")]),_c('td',[_vm._v(_vm._s(_vm.view_details.created_at))])])])])])]],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }