<template>
  <div>
    <section class="w-full px-6">
      <Table
        :has_header="true"
        :has_check="false"
        :has_customize="false"
        :title="$t('logs')"
        :records="`${pagination.total || 0} ${$t('records')}`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
      >
        <template #head>
          <th>{{ $t('status') }}</th>
          <th>IP</th>
          <th>Method</th>
          <th>Details</th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="3" v-for="i in 6" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length !== 0">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <p>{{ item.status }}</p>
            </td>
            <td>
              {{ item.ip || '-' }}
            </td>
            <td>
              {{ item.request_method || '-' }}
            </td>

            <td>
              <div class="actions-group">
                <div>
                  <button
                    class="w-8 h-8 bg-sky-500 text-white rounded"
                    @click.prevent="showDetails(item.id)"
                    content="View"
                    v-tippy
                  >
                    <i class="fa-solid fa-eye"></i>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <template #tbody v-else-if="load && items.length === 0">
          <tr>
            <td :colspan="3">
              <p
                class="text-sm text-slate-600 font-medium rtl:font-semibold text-center"
              >
                {{ $t('no_data') }}
              </p>
            </td>
          </tr>
        </template>
      </Table>
      <!-- <TableLoad  /> -->
    </section>
    <transition name="scale">
      <modal
        :title="(view_details.causer || {}).name || $t('view_details')"
        v-if="show_active"
        @close="
          (e) => {
            show_active = e
          }
        "
        size="!max-w-screen-md"
      >
        <!-- v-html=""
            <pre class="language-json">

              <code
              class="language-json"
              v-html="highlightResult(view_details.response)"
              ></code>
            </pre> -->
        <template>
          <div class="overflow-x-auto">
            <table class="styled-table">
              <tbody>
                <tr>
                  <td>Request URL</td>
                  <td>{{ view_details.request_url }}</td>
                </tr>
                <tr>
                  <td>Body</td>
                  <td>
                    <p
                      v-html="
                        JSON.stringify(JSON.parse(view_details.body), null, 2)
                      "
                    ></p>
                  </td>
                </tr>
                <tr>
                  <td>User agent</td>
                  <td>{{ view_details.user_agent }}</td>
                </tr>
                <tr>
                  <td>Response</td>
                  <td>
                    <p
                      v-html="
                        JSON.stringify(
                          JSON.parse(view_details.response),
                          null,
                          2
                        )
                      "
                    ></p>
                  </td>
                </tr>
                <tr>
                  <td>Created date</td>
                  <td>{{ view_details.created_at }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </modal>
    </transition>
  </div>
</template>
<script>
import Modal from '@/components/basics/Modal.vue'
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import Prism from 'prismjs'
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: { Modal, Table, TableLoad },
  name: 'EventLogs',
  data() {
    return {
      load: false,
      items: [],
      add_data: {},
      show_active: false,

      pagination: {
        page: 1,
      },

      disables: {},
    }
  },
  watch: {
    'pagination.page'() {
      this.getItems()
    },
    is_called(val) {
      if (val) this.getItems()
    },
  },
  computed: {
    ...mapGetters('table', ['search', 'is_called']),
    highlightResult() {
      return (str) =>
        Prism.highlight(
          JSON.stringify(str, null, 4),
          Prism.languages.javascript,
          'javascript'
        )
    },
  },
  async created() {
    await this.getItems()
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled']),
    async getItems() {
      const { page, search_key, sort_key, sort_type, is_active } =
        this.pagination
      try {
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'request_logs',
          options: {
            page,
            search_key,
            sort_key: this.search,
            sort_type,
            is_active: is_active ? (is_active === 'active' ? 1 : 0) : is_active,
          },
        })
        const { data, pagination } = result.request_logs
        this.items = data.map((e) => {
          return {
            ...e,
          }
        })
        this.$set(this.pagination, 'total', pagination.total)
        this.$set(this.pagination, 'total_pages', pagination.total_pages)
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.load = true
        this.changeIsCalled(false)
      }
    },

    showDetails(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.view_details = {
        ...item,
      }
      this.show_active = true
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination)
        .filter((e) => ['status', 'search_key'].includes(e))
        .forEach((key) => this.$set(this.pagination, key, null))
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
  },
}
</script>
<!-- @import url('prismjs/themes/prism.css'); -->
<style scope lang="scss">
@import url('prismjs/themes/prism-tomorrow.min.css');
</style>