<template>
  <aside class="h-screen fixed top-0 z-[5] w-full bg-gray-700/30 side-container" ref="sideBar"
    @click.self="openCloseSide">
    <div class="
        h-full
        flex flex-col
        overflow-x-hidden
        min-h-screen
        max-h-screen
        bg-gray-700
      " data-type="sidebar">
      <header class="relative">
        <div class="w-full border-b border-slate-600 p-4 flex items-center">
          <div class="flex-1">
            <img src="@/assets/images/logo-test.png" alt="logo image"
              class="max-w-[8rem] max-h-[3rem] object-contain table" />
          </div>
          <div>
            <button class="text-white w-5 h-5" @click.prevent="openCloseSide">
              <i class="fa-solid fa-times fa-sm"></i>
            </button>
          </div>
        </div>
      </header>
      <nav class="w-full flex-1 overflow-y-auto">
        <ul class="list-none w-full p-2">
          <li v-for="(item, index) in pages" :key="index" class="animate-item-move" v-can="item.permission">
            <router-link :to="item.path" class="w-full flex items-center gap-4 text-white p-3 px-4" v-if="!item.child">
              <div>
                <i :class="item.icon"></i>
              </div>
              <div class="flex-1 grid">
                <p class="truncate text-sm font-medium rtl:font-semibold">
                  {{ $t(item.key) }}
                </p>
              </div>
            </router-link>
            <div v-else>
              <a href="#" class="w-full flex items-center gap-4 text-white p-3">
                <div class="flex-1 grid">
                  <p class="
                      truncate
                      text-xs
                      font-medium
                      rtl:font-semibold
                      text-gray-400
                    ">
                    {{ $t(item.key) }}
                  </p>
                </div>
              </a>
              <transition name="move">
                <ul class="list-none space-y-1">
                  <li v-for="(child, idx) in item.child" :key="idx" v-can="child.permission">
                    <router-link class="
                        w-full
                        flex
                        items-center
                        gap-4
                        text-white
                        p-2.5
                        px-4
                        text-sm
                      " :to="child.path">
                      <div>
                        <i :class="child.icon"></i>
                      </div>
                      <div class="flex-1 grid">
                        <p class="truncate text-sm font-medium rtl:font-semibold">
                          {{ $t(child.key) }}
                        </p>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </transition>
            </div>
          </li>
        </ul>
      </nav>
      <footer class="p-4">
        <button class="
            w-full
            flex
            items-center
            gap-4
            text-white
            p-3
            px-4
            rounded-md
            bg-gray-600
            text-left
            rtl:text-right
          " @click.prevent="logout">
          <div>
            <i class="fa-solid fa-power-off"></i>
          </div>
          <div class="flex-1 grid">
            <p class="truncate text-sm font-medium rtl:font-semibold">
              {{ $t('logout') }}
            </p>
          </div>
        </button>
      </footer>
    </div>
  </aside>
</template>
<script>
export default {
  name: 'SideBar',
  data() {
    return {
      pages: [
        {
          name: 'Dashboard',
          permission: 'dashboard',
          key: 'dashboard',
          icon: 'fa-solid fa-home',
          path: '/',
        },

        {
          name: 'Companies',
          permission: 'companies',
          key: 'companies',

          icon: 'fa-solid fa-building',
          path: '/companies',
        },
        {
          name: 'Stores',
          permission: 'stores',
          key: 'stores',

          icon: 'fa-solid fa-store',
          path: '/stores',
        },
        {
          name: 'Branches',
          permission: 'branches',
          key: 'branches',

          icon: 'fa-solid fa-shop',
          path: '/branches',
        },
        {
          name: 'Messages',
          permission: 'messages',
          key: 'messages',

          icon: 'fa-solid fa-message',
          path: '/messages',
        },
        {
          name: 'Setup',
          permission: 'settings|website|tutorials|tasks|countries|regions|cities|districts|admins|roles|logs',
          key: 'setup',
          child: [
            {
              name: 'Settings',
              permission: 'settings',
              key: 'settings',
              icon: 'fa-solid fa-gear',

              path: '/setup/settings',
            },
            {
              name: 'Website',
              permission: 'website',
              key: 'website',
              icon: 'fa-solid fa-globe',

              path: '/website/clients',
            },
            {
              name: 'Tutorials',
              permission: 'tutorials',
              key: 'tutorials',
              icon: 'fa-solid fa-list-check',

              path: '/setup/tutorials',
            },
            {
              name: 'Tasks',
              permission: 'tasks',
              key: 'tasks',
              icon: 'fa-solid fa-list-check',

              path: '/setup/tasks',
            },

            {
              name: 'Countries',
              permission: 'countries',
              key: 'countries',
              icon: 'fa-solid fa-earth-asia',

              path: '/setup/countries',
            },
            {
              name: 'Regions',
              permission: 'regions',
              key: 'regions',
              icon: 'fa-solid fa-location-dot',

              path: '/setup/regions',
            },

            {
              name: 'Cities',
              permission: 'cities',
              key: 'cities',
              icon: 'fa-solid fa-map-location-dot',

              path: '/setup/cities',
            },
            {
              name: 'Districts',
              permission: 'districts',
              key: 'districts',
              icon: 'fa-solid fa-location-dot',

              path: '/setup/districts',
            },
            {
              name: 'Admins',
              permission: 'admins',
              key: 'admins',
              icon: 'fa-solid fa-user-tie',

              path: '/setup/admins',
            },
            {
              name: 'Permissions',
              permission: 'roles',
              key: 'permissions',
              icon: 'fa-solid fa-toggle-off',
              path: '/setup/roles',
            },
            {
              name: 'Logs',
              permission: 'logs',
              key: 'logs',
              icon: 'fa-solid fa-code',
              path: '/setup/logs',
            },
          ],
        },
      ],
      tree: null,
    }
  },
  watch: {
    $route() {
      // close sidebar
      const sidebar = this.$refs.sideBar
      if (sidebar.classList.contains('side-active')) {
        sidebar.classList.remove('side-active')
        this.tree = null
      }
    },
    tree() {
      const sidebar = this.$el
      const asideMove = document.querySelectorAll('.aside-move')
      if (this.tree) {
        if (!sidebar.classList.contains('side-active')) {
          sidebar.classList.add('side-active')
          asideMove.forEach((e) => e.classList.add('current-aside-active'))
        }
      }
    },
  },
  methods: {
    openCloseSide() {
      const sidebar = this.$refs.sideBar
      if (sidebar.classList.contains('side-active')) {
        sidebar.classList.remove('side-active')
        this.tree = null
      } else {
        sidebar.classList.add('side-active')
      }
    },
  },
}
</script>
