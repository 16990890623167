<template>
  <div>
    <section class="w-full space-y-6">
      <filter-wrapper v-if="false">
        <div class="w-full">
          <div class="form-group">
            <input
              type="text"
              name="search"
              id="search"
              :placeholder="$t('search')"
              v-model="pagination.search_key"
              class="!bg-white !py-2.5 ring-1 ring-gray-200"
            />
          </div>
        </div>

        <div class="w-full">
          <div class="actions-group">
            <div>
              <button
                class="
                  w-full
                  h-auto
                  bg-secondary
                  text-white
                  font-medium
                  rtl:font-semibold
                  text-sm
                  rounded-md
                  text-center
                  py-2.5
                  px-4
                "
                @click.prevent="applyFilter"
                :disabled="apply_disabled"
              >
                <spinner size="w-4 h-4 inline-block" v-if="apply_disabled" />
                Apply
              </button>
            </div>
            <div>
              <button
                class="
                  w-full
                  h-auto
                  bg-gray-300
                  text-gray-600
                  font-medium
                  rtl:font-semibold
                  text-sm
                  rounded-md
                  text-center
                  py-2.5
                  px-4
                "
                @click.prevent="resetFilter"
                :disabled="reset_disabled"
              >
                <spinner size="w-4 h-4 inline-block" v-if="reset_disabled" />
                Reset
              </button>
            </div>
          </div>
        </div>
      </filter-wrapper>

      <main class="w-full px-6">
        <Table
          :has_header="true"
          :has_check="false"
          :title="$t('companies')"
          :records="`${pagination.total} ${$t('records')}`"
          :total_pages="pagination.total_pages"
          v-model="pagination.page"
        >
          <template #filter>
            <ul class="flex items-center">
              <li v-for="(item, index) in status" :key="index">
                <button
                  class="
                    py-4
                    px-6
                    text-center
                    font-medium
                    rtl:font-semibold
                    text-sm
                    capitalize
                  "
                  @click.prevent="
                    changeFilterStatus(item === 'all' ? null : item, index)
                  "
                  :class="{
                    'active-tab': pagination.is_new
                      ? pagination.is_new === item
                      : pagination.is_active === (item === 'all' ? null : item),
                  }"
                  :disabled="disables[index]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    class="mr-2 rtl:mr-0 rtl:ml-2"
                    v-if="disables[index]"
                  />
                  {{ $t(item.replace(/\s/g, '_')) }}
                </button>
              </li>
            </ul>
          </template>
          <template #head>
            <th>{{ $t('name') }}</th>
            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('domain') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('domain')"
                  ></button>
                </div>
              </div>
            </th>
            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('merchandisers') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('merchandisers_count')"
                  ></button>
                </div>
              </div>
            </th>

            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('supervisors') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('supervisors_count')"
                  ></button>
                </div>
              </div>
            </th>
            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('skus') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('skus_count')"
                  ></button>
                </div>
              </div>
            </th>
            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('active_merchandisers') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('active_merch')"
                  ></button>
                </div>
              </div>
            </th>
            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('not_active_merchandisers') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('not_active')"
                  ></button>
                </div>
              </div>
            </th>
            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('active') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('is_active')"
                  ></button>
                </div>
              </div>
            </th>

            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('updated_at') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('updated_at')"
                  ></button>
                </div>
              </div>
            </th>

            <th></th>
          </template>
          <template #tbody v-if="!load">
            <TableLoad :rows="8" v-for="i in 6" :key="i" />
          </template>
          <template #tbody v-else-if="load && items.length !== 0">
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ item.name || '-' }}</td>
              <td>{{ item.domain || '-' }}</td>
              <td>
                {{
                  item.is_new
                    ? item.merchandisers_count || 0
                    : item.active_merchandisers_count +
                        item.not_active_merchandisers_count || 0
                }}
              </td>
              <td>{{ item.supervisors_count || 0 }}</td>
              <td>{{ item.skus_count || 0 }}</td>
              <td>{{ item.active_merchandisers_count || 0 }}</td>
              <td>{{ item.not_active_merchandisers_count || 0 }}</td>
              <td>
                <p
                  class="status capitalize"
                  :class="item_status[item.is_active]"
                  v-if="!item.is_new"
                >
                  {{ item.is_active === 1 ? $t('active') : $t('not_active') }}
                </p>
                <p class="status capitalize" :class="item_status['new']" v-else>
                  {{ $t('new') }}
                </p>
              </td>
              <td v-html="createTime(item.updated_at || item.created_at)"></td>
              <td>
                <div class="actions-group">
                  <div>
                    <button
                      class="
                        p-2
                        px-3
                        text-red-500
                        font-medium
                        rtl:font-semibold
                      "
                      @click.prevent="deleteItem(item.id)"
                      :disabled="disables[`delete_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`delete_${item.id}`]"
                      />
                      <span v-else> {{ $t('delete') }} </span>
                    </button>
                  </div>
                  <div v-if="item.is_new">
                    <button
                      class="
                        py-1.5
                        px-2
                        text-white
                        font-medium
                        rtl:font-semibold
                        bg-teal-500
                        rounded
                      "
                      @click.prevent="acceptItem(item.id)"
                      :disabled="disables[`accept_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`accept_${item.id}`]"
                      />
                      <span v-else> {{ $t('accept') }} </span>
                    </button>
                  </div>
                  <div v-if="!item.is_new">
                    <button
                      class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded-sm"
                      @click.prevent="changeStatus(item.id)"
                      :content="$t('change_status')"
                      v-tippy
                      :disabled="disables[`status_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`status_${item.id}`]"
                      />
                      <i class="fa-solid fa-right-left" v-else></i>
                    </button>
                  </div>
                  <div v-if="!item.is_new">
                    <button
                      class="w-8 h-8 bg-sky-500 text-white rounded-sm"
                      @click.prevent="showUpdate(item.id)"
                      :content="$t('update')"
                      v-tippy
                    >
                      <i class="fa-solid fa-pen"></i>
                    </button>
                  </div>
                  <div v-if="!item.is_new">
                    <router-link
                      :to="`/companies/${item.id}/users`"
                      class="
                        w-8
                        h-8
                        bg-sky-500
                        text-white
                        rounded-sm
                        flex
                        items-center
                        justify-center
                      "
                      :content="$t('view')"
                      v-tippy
                    >
                      <i class="fa-solid fa-eye"></i>
                    </router-link>
                  </div>
                  <div v-if="!item.is_new">
                    <button
                      class="w-8 h-8 bg-teal-500 text-white rounded-sm"
                      @click.prevent="tryToLogin(item.id)"
                      :content="$t('login')"
                      :disabled="disables[`login_${item.id}`]"
                      v-tippy
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`login_${item.id}`]"
                      />
                      <i class="fa-solid fa-right-to-bracket" v-else></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template #tbody v-else-if="load && items.length === 0">
            <tr>
              <td colspan="8">
                <p
                  class="
                    text-sm text-gray-600
                    font-medium
                    rtl:font-semibold
                    text-center
                  "
                >
                  {{ $t('no_data') }}
                </p>
              </td>
            </tr>
          </template>
        </Table>
      </main>
    </section>

    <transition name="scale">
      <Modal
        :title="$t('update')"
        v-if="edit_active"
        @close="edit_active = $event"
      >
        <template>
          <form class="p-6 space-y-4">
            <div class="form-group">
              <div class="with-label">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  autocomplete="off"
                  v-model="edit_data.name"
                />
                <label for="name">{{ $t('name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.name"
              >
                {{ edit_errors.name.join(' ') }}
              </p>
            </div>
            <div class="form-group hidden">
              <div class="with-label">
                <input
                  type="text"
                  name="alt_name"
                  id="alt_name"
                  placeholder="الاسم"
                  required
                  autocomplete="off"
                  v-model="edit_data.alt_name"
                  v-rtl
                />
                <label for="alt_name">{{ $t('alt_name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.alt_name"
              >
                {{ edit_errors.alt_name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <input
                  type="text"
                  name="contact_name"
                  id="contact_name"
                  placeholder="Abo abduallah"
                  required
                  autocomplete="off"
                  v-model="edit_data.contact_name"
                />
                <label for="contact_name">{{ $t('name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.contact_name"
              >
                {{ edit_errors.contact_name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <input
                  type="email"
                  name="contact_email"
                  id="contact_email"
                  placeholder="example@example.com"
                  required
                  autocomplete="off"
                  v-model="edit_data.contact_email"
                  v-email
                />
                <label for="contact_email">{{ $t('email') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.contact_email"
              >
                {{ edit_errors.contact_email.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <vue-tel-input
                  id="contact_mobile"
                  ref="userMobile"
                  @open="handleOpen('userMobile')"
                  v-model="edit_data.contact_mobile"
                />
                <label for="contact_mobile">{{ $t('mobile') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.contact_mobile"
              >
                {{ edit_errors.contact_mobile.join(' ') }}
              </p>
            </div>

            <div class="form-group">
              <div class="with-label">
                <select
                  name="merchandisers_count"
                  id="merchandisers_count"
                  v-model="edit_data.merchandisers_count"
                  class="custom-select"
                >
                  <option value="" selected disabled>-- select --</option>
                  <option value="2 - 9">2 - 9</option>
                  <option value="10 - 50">10 - 50</option>
                  <option value="+50">+50</option>
                </select>
                <label for="merchandisers_count">{{
                  $t('merchandisers_count')
                }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.merchandisers_count"
              >
                {{ edit_errors.merchandisers_count.join(' ') }}
              </p>
            </div>
            <div class="w-full space-y-2">
              <label
                for="tasks"
                class="table font-medium rtl:font-semibold text-sm"
                >{{ $t('modules') }}</label
              >
              <ul
                class="
                  w-full
                  list-none
                  space-y-4
                  mt-4
                  p-2
                  max-h-[20rem]
                  overflow-y-auto
                "
              >
                <li v-for="(item, index) in tasks" :key="index">
                  <div class="form-group-with-check">
                    <label :for="`${item.type}-${item.id}`" class="flex-1">
                      <div class="flex items-center gap-3">
                        <div>
                          <input
                            autocomplete="off"
                            type="checkbox"
                            :name="`${item.type}-${item.id}`"
                            :id="`${item.type}-${item.id}`"
                            :value="item.id"
                            v-model="edit_data.task_ids"
                            @change.prevent="
                              handleParentItem($event, item.id, 'edit_data')
                            "
                          />
                          <!-- v-if="!item.sub_tasks.length" -->
                        </div>
                        <p class="flex-1">
                          {{ item.name }}
                        </p>
                      </div>
                    </label>
                  </div>
                  <ul
                    class="
                      w-full
                      list-none
                      space-y-4
                      mt-4
                      [padding-inline-start:1.5rem]
                    "
                    v-if="false"
                  >
                    <li v-for="(sub, index) in item.sub_tasks" :key="index">
                      <div class="form-group-with-check">
                        <label :for="`${sub.type}-${sub.id}`" class="flex-1">
                          <div class="flex items-center gap-3">
                            <div>
                              <input
                                autocomplete="off"
                                type="checkbox"
                                :name="`${sub.type}-${sub.id}`"
                                :id="`${sub.type}-${sub.id}`"
                                :value="sub.id"
                                v-model="edit_data.task_ids"
                                @change.prevent="
                                  handleChildItem($event, item.id, 'edit_data')
                                "
                              />
                            </div>
                            <p class="flex-1">
                              {{ sub.name }}
                            </p>
                          </div>
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>

              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.task_ids"
              >
                {{ edit_errors.task_ids.join(' ') }}
              </p>
            </div>

            <div class="actions-group">
              <div>
                <button
                  class="
                    py-2.5
                    px-6
                    rounded-md
                    text-sm
                    font-medium
                    rtl:font-semibold
                    text-white
                    bg-primary
                    table
                  "
                  @click.prevent="editItem"
                  :disabled="edit_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="edit_disabled" />
                  {{ $t('save_changes') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>

    <!-- delete modal -->
    <transition name="scale">
      <Modal
        :title="$t('delete')"
        v-if="delete_active"
        @close="delete_active = $event"
      >
        <template>
          <form class="p-6 space-y-2">
            <h1 class="text-lg text-red-500 font-bold mb-4">
              {{ $t('deletion.title') }}
            </h1>
            <p class="text-sm text-gray-600 font-medium">
              {{ $t('deletion.content') }}
            </p>
            <p class="text-sm text-gray-600 font-medium">
              {{ $t('deletion.code') }}
            </p>
            <div class="form-group !my-4">
              <input
                type="text"
                autocomplete="off"
                placeholder="xxxxxx"
                v-model="delete_data.code"
              />
            </div>
            <button
              class="
                py-2.5
                px-6
                rounded-md
                text-sm
                font-medium
                rtl:font-semibold
                text-white
                bg-primary
                table
              "
              @click.prevent="deleteItem"
              :disabled="delete_disabled"
            >
              <spinner size="w-4 h-4 inline-block" v-if="delete_disabled" />
              {{ $t('save_changes') }}
            </button>
          </form>
        </template>
      </Modal>
    </transition>
  </div>
</template>
<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import Modal from '@/components/basics/Modal.vue'
import FilterWrapper from '@/components/basics/FilterWrapper.vue'
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'Companies',
  data() {
    return {
      load: false,
      items: [],

      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,
      pagination: {
        page: 1,
        total_pages: 0,
        search_key: null,
        sort_key: null,
        sort_type: 'asc',
        is_active: null,
        is_new: null,
        total: 0,
      },
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
        new: 'bg-teal-500/10 text-teal-500',
      },
      delete_data: {
        id: null,
        code: null,
      },
      delete_active: false,
      delete_disabled: false,
      apply_disabled: false,
      reset_disabled: false,
      status: ['all', 'active', 'not active', 'new'],
      disables: {},
      tasks: [],
    }
  },
  watch: {
    'pagination.page'() {
      this.getItems()
    },
    edit_active() {
      if (!this.edit_active) {
        this.edit_data = {}
        this.edit_errors = {}
        this.edit_disabled = false
      }
    },
    add_active() {
      if (!this.add_active) {
        this.add_data = {}
        this.add_errors = {}
        this.add_disabled = false
      }
    },
    is_called(val) {
      if (val) return this.getItems()
    },
  },
  computed: {
    ...mapGetters('table', ['search', 'is_called']),
  },
  async created() {
    Promise.all([await this.getTasks(), await this.getItems()]).finally(() => {
      this.load = true
    })
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled']),
    getTasks() {
      this.$store.dispatch('getting/getRoute', 'tasks').then(({ result }) => {
        this.tasks = result.tasks
      })
    },
    getItems() {
      const { page, search_key, sort_key, sort_type, is_active, is_new } =
        this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'companies',
          options: {
            page,
            search_key: this.search,
            sort_key,
            sort_type,
            is_active: !is_new
              ? is_active
                ? is_active === 'active'
                  ? 1
                  : 0
                : is_active
              : null,
            is_new: !is_active
              ? is_new
                ? is_new === 'new'
                  ? 1
                  : 0
                : is_new
              : null,
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.companies
          this.items = data.map((e) => {
            return {
              ...e,
              image: e.image ? result.image_url + '/' + e.image : e.image, // null,
            }
          })
          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
        })
        .catch((err) => {
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => {
          this.changeIsCalled(false)
        })
    },
    async addItem() {
      this.add_disabled = true
      this.add_errors = {}

      try {
        const { data } = await this.axios.post('companies/add', {
          ...this.add_data,
        })
        this.getItems()
        this.createAlert(data.message)
        this.add_active = false
        this.add_data = {}
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    async editItem() {
      this.edit_disabled = true
      this.edit_errors = {}

      try {
        const { data } = await this.axios.post('companies/update', {
          ...this.edit_data,
        })
        const { message, result } = data
        const finalResult = result.company ?? {}
        this.getItems()
        this.createAlert(message)
        // this.edit_active = false
        this.edit_data = {
          ...finalResult,
          task_ids: finalResult.tasks?.map((e) => e.id) || [],
        }
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.edit_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.edit_errors = res?.data?.message
      } finally {
        this.edit_disabled = false
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true)
      try {
        const { data } = await this.axios.post(
          `companies/update_is_active/${id}`
        )
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async tryToLogin(id) {
      this.$set(this.disables, `login_${id}`, true)
      try {
        const { data } = await this.axios.post(`companies/login/${id}`)
        this.createAlert(data.message)
        // console.log(data)
        window.location.replace(
          `http://portal.rtibha.com/login/?token=${data.result.access_token}`
        )
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)
      this.delete_disabled = true
      const isEvent = id instanceof Event
      if (!isEvent) this.delete_data = { id }
      try {
        const { data } = await this.axios.post(
          `companies/delete/${isEvent ? this.delete_data.id : id}`,
          {
            code: this.delete_data.code,
          }
        )

        if (data.result?.need_code) {
          this.delete_active = true
          return
        }

        if (this.delete_data.code) {
          this.delete_active = false
          this.delete_data = {}
          this.createAlert(data.message)
          this.getItems()
        }
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
        this.delete_disabled = false
      }
    },
    async acceptItem(id) {
      this.$set(this.disables, `accept_${id}`, true)
      try {
        const { data } = await this.axios.post(`companies/accept/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.edit_data = {
        ...item,
        task_ids: item.tasks?.map((e) => e.id) || [],
      }
      this.edit_active = true
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination)
        .filter((e) => ['status', 'search_key'].includes(e))
        .forEach((key) => this.$set(this.pagination, key, null))
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      if (status === 'new') {
        this.$set(this.pagination, 'is_active', null)
        this.$set(this.pagination, 'is_new', status)
      } else {
        this.$set(this.pagination, 'is_new', null)
        this.$set(this.pagination, 'is_active', status)
      }
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
    handleParentItem(e, id, type) {
      const tasks = this.tasks
      const task = tasks.find((e) => e.id === id)
      const isChecked = e.target.checked

      if (!task?.sub_tasks?.length) return
      const taskIds = task?.sub_tasks?.map((e) => e.id)
      if (isChecked) {
        this.$set(
          this[type],
          'task_ids',
          new Array().concat(this[type]?.task_ids, taskIds)
        )
      } else {
        this.$set(
          this[type],
          'task_ids',
          this[type]?.task_ids?.filter(
            (e) => !new Array().concat(id, taskIds).includes(e)
          )
        )
      }
    },
    handleChildItem(e, id, type) {
      const isChecked = e.target.checked
      const selectedTasks = this[type]?.task_ids

      if (isChecked) {
        if (selectedTasks.includes(id)) return
        this.$set(this[type], 'task_ids', new Array().concat(selectedTasks))
      } else {
        const parentSubTasks = this.tasks.find((e) => e.id === id)
        const siblingIds = parentSubTasks.sub_tasks.map((e) => e.id)
        const hasSiblingIds = siblingIds.some((e) =>
          new RegExp(selectedTasks.join('|')).test(e)
        )

        if (hasSiblingIds) return
        this.$set(
          this[type],
          'task_ids',
          selectedTasks.filter((e) => e !== id)
        )
      }
    },
  },
  components: { Table, Modal, TableLoad, FilterWrapper },
}
</script>
