<template>
  <div>
    <section class="w-full px-6 pt-20">
      <main
        class="container bg-white px-8 py-20 min-h-[calc(100vh-400px)] flex items-center justify-center rounded-sm ring-1 ring-gray-200"
      >
        <div class="table m-auto">
          <div class="flex items-center justify-center flex-col gap-6">
            <div>
              <div
                class="w-28 h-28 flex items-center justify-center bg-red-500/10 rounded-full text-red-500"
              >
                <i class="fa-solid fa-broom fa-2xl"></i>
              </div>
            </div>
            <div class="flex-1 space-y-4 text-center">
              <h1 class="text-3xl text-primary font-bold">
                OOPS!! We lost this page
              </h1>
              <p
                class="text-lg text-gray-400 max-w-2xl font-medium rtl:font-semibold"
              >
                Hasret, we searched for that page but we won't be able to find
                what you're looking for, maybe you entered something wrong or
                that page doesn't exist for us in the first place
              </p>
              <router-link
                to="/"
                class="w-full max-w-sm text-center py-3 px-6 rounded-full text-white bg-secondary text-xs font-medium rtl:font-semibold shadow-xl shadow-secondary/30 !mt-8 block mx-auto"
              >
                Back home
              </router-link>
            </div>
          </div>
        </div>
      </main>
    </section>
  </div>
</template>
<script>
export default {
  name: 'Notifications',
}
</script>
