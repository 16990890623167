export default async function hasAccess({ next, store, to, from, routes }) {
  const permissions = store.state.auth.permissions

  const filterRoutes = routes.filter(
    (route) =>
      (permissions.includes(route.meta.permission) || !route.meta.permission) &&
      !/login/gi.test(route.path)
  )

  const isExisted = filterRoutes.some(
    (route) => route.meta.permission === to.meta.permission
  )

  if (!to.meta.permission) return next()

  if (isExisted && permissions.includes(to.meta.permission)) return next()

  if (!isExisted) return next({ name: filterRoutes[0]?.name })

  return next({
    name: 'Error',
  })
}
