<template>
  <div class="flex flex-col items-stretch justify-between min-h-screen">
    <Header v-if="!['Login', 'Join'].includes($route.name)" />
    <div class="flex-1 h-full">
      <div
        :data-type="!['Login', 'Join'].includes($route.name) && 'centered-item'"
      >
        <router-view class="pt-4 pb-10"></router-view>
      </div>

      <button
        class="
          fixed
          bottom-6
          right-6
          rtl:right-auto rtl:left-6
          w-10
          h-10
          rounded-full
          flex
          items-center
          justify-center
          bg-secondary
          text-white
          z-[21]
        "
        @click.prevent="backToTop"
        v-if="!['Login', 'Join'].includes($route.name)"
      >
        <i class="fa-solid fa-circle-arrow-up"></i>
      </button>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  components: { Header, Footer },
  data() {
    return {
      hasPath: false,
      redirects: {
        SingleVisit: '/visits',
        SingleMessage: '/messages',
        SingleJourneyPlane: '/setup/journey-plans',
        SingleClient: '/setup/clients',
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('customize', [
      'getBasicColumn',
      'getSelectedColumns',
      'getColumns',
      'routeName_2',
    ]),
    endpointName() {
      return this.$route.meta?.endpoint_name
    },
    routeName() {
      const route = this.$route
      const name = route.path
        .split('/')
        .filter((e) => e)
        .at(-1)
        .split('-')
        .join(' ')
      return route.meta.title ? this.$t(route.meta.title) : name
    },
  },
  watch: {
    $route() {
      this.getRoute()
      this.resetPagination()
      this.resetCards()
      this.updateSearch(null)
      this.setRouteName(null)
      this.changeIsCalled(false)
    },
    endpointName() {
      this.checkSelectedColumns()
    },
    getUser: {
      immediate: true,
      handler(val) {
        if (val) {
          this.checkUserToAccessPusher()
          this.GetUsers({
            page: 1,
          })
        }
      },
    },
  },

  created() {
    this.getRoute()

    if (!localStorage.getItem('window')) {
      localStorage.setItem('window', 'full')
    }
    this.checkSelectedColumns()
    // if (this.getUser?.id) {
    //   this.checkUserToAccessPusher()
    // }
  },

  methods: {
    ...mapMutations('table', [
      'changePagination',
      'resetPagination',
      'updateSearch',
      'changeIsCalled',
    ]),
    ...mapMutations('customize', [
      'resetCards',
      'updatedSelectedColumns',
      'setRouteName',
    ]),
    ...mapActions('chat', ['GetUsers']),
    getLastRoute() {
      return window.location.pathname
        .split('/')
        .filter((e) => e)
        .at(-1)
        .split('-')
        .join(' ')
    },
    getCurrentRoute() {
      const pathname = this.$route.path
      const splitPath = pathname.split('/')
      const newSplit = [...new Set(splitPath)]
      let routes = ''

      newSplit.forEach((route, idx) => {
        routes += `${
          idx !== newSplit.length - 1
            ? `<span class="inline-block"> ${
                route ? route : 'Home'
              }</span> <i class="fa-solid fa-angle-right inline-block rtl:hidden mx-1 fa-xs align-middle"></i>
      <i class="fa-solid fa-angle-left hidden rtl:inline-block mx-1 fa-xs align-middle"></i>`
            : `<a href="${this.$route.path}" class="text-sky-600 inline-block">${route}</a>`
        }`
      })

      return routes
    },
    getRoute() {
      let pathname = this.$route.path.split('/').filter((e) => e).length !== 0
      return (this.hasPath = pathname)
    },
    backToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },
    checkUserToAccessPusher() {
      const channel = this.$echo?.private('admin-channel')
      channel?.listen('.support_messages', ({ support_message }) => {
        const { body, user } = support_message
        this.$store.commit('chat/UPDATE_MESSAGE', support_message)
        this.$store.commit('chat/UPDATE_ITEMS', support_message)
        this.checkPermissions({
          title: `New message sent by ${user.name || 'UNKNOWN'}`,
          body: body,
        })
        new Audio(require('@/assets/sounds/message.mp3')).play()
      })
    },
    checkSelectedColumns() {
      const columns = this.getColumns
      const routes = this.$router.options.routes
      const mapRoutes = routes.filter((e) => e.meta.endpoint_name)
      for (let rIdx = 0; rIdx < mapRoutes.length; rIdx++) {
        const route = mapRoutes[rIdx]
        const key = route.meta.endpoint_name
        if (Object.hasOwnProperty.call(columns, key)) {
          if (!this.getSelectedColumns(key)) {
            this.updatedSelectedColumns({
              type: key,
              data: columns[key],
            })
          }
        }
      }
    },
    handleBack() {
      const name = this.$route.name
      if (Object.hasOwnProperty.call(this.redirects, name)) {
        this.$router.push({
          path: this.redirects[name],
        })
        return
      }
      this.$router.go(-1)
    },
  },
}
</script>
