<template>
  <div>
    <MessagesContainerWrapper>
      <main class="flex-1 w-full bg-white border border-gray-200">
        <div class="flex flex-col h-full">
          <div>
            <header class="w-full border-b border-gray-100 p-4">
              <div class="flex items-center">
                <div>
                  <div
                    class="w-10 h-10 rounded-full bg-gray-200 animate-pulse"
                  ></div>
                </div>
                <div class="px-4 space-y-2 flex-1">
                  <div
                    class="w-full max-w-[10rem] h-4 rounded-sm bg-gray-200 animate-pulse"
                  ></div>
                  <div
                    class="w-full max-w-xs h-3 rounded-sm bg-gray-200 animate-pulse"
                  ></div>
                </div>
              </div>
            </header>
          </div>
          <div class="flex-1 p-10 py-20">
            <div class="table m-auto">
              <div class="flex items-center justify-center flex-col gap-6">
                <div>
                  <div
                    class="w-28 h-28 flex items-center justify-center bg-gray-100 rounded-full text-gray-500"
                  >
                    <i class="fa-solid fa-paper-plane fa-2xl"></i>
                  </div>
                </div>
                <div class="flex-1 space-y-2 text-center">
                  <h1 class="text-lg text-primary font-semibold rtl:font-bold">
                    There are no messages
                  </h1>
                  <p
                    class="text-sm text-gray-400 max-w-xl font-medium rtl:font-semibold"
                  >
                    Click on the user from the other side if there are any users
                    available to complete the discussion and conversation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </MessagesContainerWrapper>
  </div>
</template>
<script>
import MessagesContainerWrapper from '@/components/messages/MessagesContainerWrapper.vue'
export default {
  name: 'MessageIndex',
  data() {
    return {
      user_active: false,
      new_active: false,
      send_disabled: false,
      add_data: {
        user_id: null,
        name: null,
      },
      users: [],
      load: false,
      items: [],
      pagination: {
        page: 1,
        total: 0,
        total_pages: 0,
        search_key: null,
      },
    }
  },
  watch: {
    'add_data.name'() {
      if (!this['add_data.name']) {
        this.user_active = true
      }
    },
  },
  created() {
    this.getItems()
  },
  methods: {
    getItems() {
      const { page, search_key } = this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'support_messages/users',
          options: {
            page,
            search_key,
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.users
          this.items = data.map((e) => {
            return {
              ...e,
            }
          })
          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
        })
        .catch((err) => {
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => {
          this.load = true
        })
    },
    openDrop(e) {
      const icons = e.currentTarget.childNodes ?? []
      const dropDowns = document.querySelectorAll('.dropdown')
      this.multiAngle(icons)
      dropDowns.forEach((el) => {
        if (el.classList.contains('hidden')) {
          el.classList.replace('hidden', 'block')
        } else {
          el.classList.replace('block', 'hidden')
        }
      })
    },
  },
  components: { MessagesContainerWrapper },
}
</script>
