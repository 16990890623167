var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"with-label"},[_c('gmap-autocomplete',{ref:"autocomplete",attrs:{"value":_vm.add_data.address,"options":{
          componentRestrictions: { country: ['SA'] },
          fields: [],
          strictBounds: false,
          types: ['geocode'],
        },"placeholder":"eg: Sudia Arabia"},on:{"place_changed":_vm.setPlace}}),_c('label',[_vm._v(_vm._s(_vm.$t('address')))])],1)]),_c('div',{staticClass:"form-group space-y-2"},[_c('div',{staticClass:"w-full pt-0 relative"},[_c('GmapMap',{staticClass:"rounded-lg overflow-hidden",staticStyle:{"height":"280px"},attrs:{"center":{
          lat: Number(_vm.add_data.lat),
          lng: Number(_vm.add_data.lng),
        },"cluster":{ options: { styles: _vm.map.clusterStyle } },"zoom":8,"map-type-id":"terrain","options":{
          fullscreenControl: false,
          styles: _vm.map.mapStyle,
          mapTypeControl: false,
        }}},[_c('GmapMarker',{attrs:{"position":{
            lat: Number(_vm.add_data.lat),
            lng: Number(_vm.add_data.lng),
          },"draggable":true},on:{"dragend":_vm.updatePosition}})],1),_c('div',{staticClass:"absolute top-2 left-2 rtl:left-auto rtl:right-2"},[_c('button',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"w-9 h-9 rounded-md text-gray-600 shadow-lg shadow-gray-800/20 bg-white z-[2]",attrs:{"content":"get location","type":"button"},on:{"click":_vm.getMyLocation}},[_c('i',{staticClass:"fa-solid fa-location-crosshairs"})])])],1),(_vm.error)?_c('p',{staticClass:"text-gray-600 font-medium text-xs italic"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }