<template>
  <div class="space-y-4">
    <div class="form-group">
      <div class="with-label">
        <gmap-autocomplete
          ref="autocomplete"
          :value="add_data.address"
          :options="{
            componentRestrictions: { country: ['SA'] },
            fields: [],
            strictBounds: false,
            types: ['geocode'],
          }"
          @place_changed="setPlace"
          placeholder="eg: Sudia Arabia"
        />
        <label>{{ $t('address') }}</label>
      </div>
    </div>
    <div class="form-group space-y-2">
      <div class="w-full pt-0 relative">
        <GmapMap
          :center="{
            lat: Number(add_data.lat),
            lng: Number(add_data.lng),
          }"
          :cluster="{ options: { styles: map.clusterStyle } }"
          :zoom="8"
          map-type-id="terrain"
          :options="{
            fullscreenControl: false,
            styles: map.mapStyle,
            mapTypeControl: false,
          }"
          style="height: 280px"
          class="rounded-lg overflow-hidden"
        >
          <GmapMarker
            :position="{
              lat: Number(add_data.lat),
              lng: Number(add_data.lng),
            }"
            :draggable="true"
            @dragend="updatePosition"
          />
        </GmapMap>
        <div class="absolute top-2 left-2 rtl:left-auto rtl:right-2">
          <button
            class="w-9 h-9 rounded-md text-gray-600 shadow-lg shadow-gray-800/20 bg-white z-[2]"
            @click="getMyLocation"
            content="get location"
            v-tippy
            type="button"
          >
            <i class="fa-solid fa-location-crosshairs"></i>
          </button>
        </div>
      </div>
      <p class="text-gray-600 font-medium text-xs italic" v-if="error">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      add_data: {
        ...this.value,
      },
      map: {
        clusterStyle: [
          {
            url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
            width: 56,
            height: 56,
            textColor: '#fff',
          },
        ],
        mapStyle: [
          {
            featureType: 'all',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                visibility: 'on',
              },
              {
                color: '#3e606f',
              },
              {
                weight: 2,
              },
              {
                gamma: 0.84,
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'administrative',
            elementType: 'geometry',
            stylers: [
              {
                weight: 0.6,
              },
              {
                color: '#313536',
              },
            ],
          },
          {
            featureType: 'landscape',
            elementType: 'geometry',
            stylers: [
              {
                color: '#44a688',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
              {
                color: '#13876c',
              },
            ],
          },
          {
            featureType: 'poi.attraction',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#f5e4e4',
              },
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi.attraction',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'on',
              },
              {
                lightness: '14',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
              {
                color: '#13876c',
              },
              {
                visibility: 'simplified',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
              {
                color: '#067372',
              },
              {
                lightness: '-20',
              },
            ],
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [
              {
                color: '#357374',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
              {
                color: '#004757',
              },
            ],
          },
        ],
      },
      error: null,
    }
  },
  props: {
    value: {
      type: Object,
      default: {},
    },
  },
  watch: {
    add_data() {
      this.$emit('input', {
        address: this.add_data.address,
        lat: this.add_data.lat?.toString(),
        lng: this.add_data.lng?.toString(),
      })
    },
  },
  created() {
    const geoLocation = !!(this.value?.lat && this.value?.lng)
      ? this.value
      : {
          lat: 24.7135517,
          lng: 46.6752957,
        }

    this.$set(this.add_data, 'lat', geoLocation.lat)
    this.$set(this.add_data, 'lng', geoLocation.lng)
  },
  methods: {
    getMyLocation() {
      this.getLocation()

      if (!this.add_data?.address) {
        this.$geocoder.send(
          !(this.value?.lat || this.value?.lng) ? geoLocation : this.add_data,
          (response) => {
            if (response.status == 'OK') {
              let address = response.results[0].formatted_address
              this.$set(this.add_data, 'address', address)
              this.$set(this.add_data, 'lat', geoLocation.lat)
              this.$set(this.add_data, 'lng', geoLocation.lng)
            }
          }
        )
      }
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.setLocation,
          this.locationError
        )
      } else {
        alert('Geolocation is not supported by this browser.')
      }
    },
    locationError(e) {
      this.error = e.message
    },
    setLocation(pos) {
      let map_pos = {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      }
      this.updatePosition(map_pos, 1)
    },
    setPlace(place) {
      if (place.geometry) {
        this.$set(this.add_data, 'lat', place.geometry.location.lat())
        this.$set(this.add_data, 'lng', place.geometry.location.lng())
        this.$set(
          this.add_data,
          'address',
          this.$refs.autocomplete.$refs.input.value
        )
      }
    },
    updatePosition(event, flag = null) {
      let pos = {}
      if (flag) {
        pos = event
      } else {
        pos = { lat: event.latLng.lat(), lng: event.latLng.lng() }
      }

      this.$set(this.add_data, 'lat', pos.lat)
      this.$set(this.add_data, 'lng', pos.lng)
      this.$emit('input', this.add_data)
      this.$geocoder.send(pos, (response) => {
        if (response.status == 'OK') {
          let address = response.results[0].formatted_address
          this.$set(this.add_data, 'address', address)
          this.$refs.autocomplete.$refs.input.value = address
        }
      })
    },
  },
}
</script>
