<template>
  <div>
    <section class="w-full p-6 space-y-6">
      <Table
        title="tutorials"
        :records="`${pagination.total || 0} records`"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
        :has_header="true"
        :has_check="false"
      >
        <template #actions>
          <div>
            <button
              class="
                py-1.5
                px-3
                text-sm
                flex
                items-center
                gap-2
                text-gray-600
                font-medium
                rtl:font-semibold
              "
              @click.prevent="add_active = !add_active"
            >
              <i class="fa-solid fa-plus"></i>
              <span>{{ $t('add_new.tutorial') }}</span>
            </button>
          </div>
        </template>
        <template #filter>
          <ul class="flex items-center">
            <li v-for="(item, index) in status" :key="index">
              <button
                class="
                  py-4
                  px-6
                  text-center
                  font-medium
                  rtl:font-semibold
                  text-sm
                  capitalize
                "
                @click.prevent="
                  changeFilterStatus(item === 'all' ? null : item, index)
                "
                :class="{
                  'active-tab':
                    pagination.is_active === (item === 'all' ? null : item),
                }"
                :disabled="disables[index]"
              >
                <spinner
                  size="w-4 h-4 inline-block"
                  class="mr-2 rtl:mr-0 rtl:ml-2"
                  v-if="disables[index]"
                />
                <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
                {{ item }}
              </button>
            </li>
          </ul>
        </template>
        <template #head>
          <th>{{ $t('title') }}</th>
          <th>{{ $t('video_url') }}</th>
          <th>{{ $t('status') }}</th>
          <th>{{ $t('updated_at') }}</th>
          <th></th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="5" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <div class="flex items-center gap-3">
                <div>
                  <div class="w-10 h-10">
                    <img
                      :src="
                        item.image || require('@/assets/images/placeholder.png')
                      "
                      @error="
                        $event.target.src = require('@/assets/images/placeholder.png')
                      "
                      alt="item image"
                      class="
                        w-full
                        h-full
                        rounded-md
                        border
                        object-cover
                        border-gray-100
                      "
                    />
                  </div>
                </div>
                <span
                  class="
                    inline-block
                    line-clamp-2
                    max-w-sm
                    min-w-[15rem]
                    !whitespace-normal
                  "
                >
                  {{ item.title }}
                </span>
              </div>
            </td>

            <td>
              <a
                :href="item.video_url"
                class="
                  text-sky-500
                  line-clamp-2
                  max-w-sm
                  min-w-[15rem]
                  !whitespace-normal
                  table
                "
                v-if="item.video_url"
                >{{ item.video_url }}</a
              >
              <span v-else>-</span>
            </td>
            <td>
              <p class="status capitalize" :class="item_status[item.is_active]">
                {{ item.is_active === 1 ? 'Active' : 'Not active' }}
              </p>
            </td>

            <td v-html="createTime(item.updated_at || item.created_at)"></td>

            <td>
              <div class="actions-group">
                <div>
                  <button
                    class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                    @click.prevent="deleteItem(item.id)"
                    :disabled="disables[`delete_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`delete_${item.id}`]"
                    />
                    <span v-else> {{ $t('delete') }} </span>
                  </button>
                </div>
                <div>
                  <button
                    class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                    @click.prevent="changeStatus(item.id)"
                    :content="$t('change_status')"
                    v-tippy
                    :disabled="disables[`status_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`status_${item.id}`]"
                    />
                    <i class="fa-solid fa-right-left" v-else></i>
                  </button>
                </div>
                <div>
                  <button
                    class="w-8 h-8 bg-sky-500 text-white rounded"
                    @click.prevent="showUpdate(item.id)"
                    :content="$t('update')"
                    v-tippy
                  >
                    <i class="fa-solid fa-pen"></i>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="5" class="!text-center">{{ $t('no_data') }}</td>
          </tr>
        </template>
      </Table>
    </section>
    <Modal
      :title="$t('add_new.tutorial')"
      v-if="add_active"
      @close="
        (e) => {
          add_active = e
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <figure class="space-y-4">
            <slide-transition>
              <figcaption v-if="add_data.image" class="relative table">
                <img
                  :src="
                    add_data.image
                      ? convertImage(add_data.image)
                      : require('@/assets/images/placeholder.png')
                  "
                  @error="
                    (e) =>
                      (e.target.src = require('@/assets/images/placeholder.png'))
                  "
                  alt="category image"
                  class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                />
                <button
                  class="
                    w-6
                    h-6
                    rounded-full
                    flex
                    items-center
                    justify-center
                    bg-red-500
                    text-white
                    absolute
                    top-0
                    right-0
                    rtl:right-auto rtl:left-0
                    ring-2 ring-white
                  "
                  type="button"
                  @click.prevent="() => clearImage('add_data')"
                >
                  <i class="fa-solid fa-xmark fa-sm"></i>
                </button>
              </figcaption>
            </slide-transition>
            <blockquote class="space-y-2">
              <label class="block">
                <span class="sr-only">Choose image</span>
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file"
                  ref="uploader"
                  @change="(e) => $set(add_data, 'image', e.target.files[0])"
                />
              </label>
              <p class="error" v-if="add_errors.image">
                {{ add_errors.image.join(' ') }}
              </p>
            </blockquote>
          </figure>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.title">
                {{ add_errors.title.join(' ') }}
              </p>
              <input
                type="text"
                name="title"
                id="title"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="add_data.title"
                v-ltr
              />
              <label for="title"
                >{{ $t('title') }} | {{ $t('langs.en') }}</label
              >
            </div>
          </div>

          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.alt_title">
                {{ add_errors.alt_title.join(' ') }}
              </p>

              <input
                type="text"
                name="alt_title"
                id="alt_title"
                placeholder="الاسم"
                required
                autocomplete="off"
                v-model="add_data.alt_title"
                v-rtl
              />
              <label for="alt_title"
                >{{ $t('title') }} | {{ $t('langs.ar') }}</label
              >
            </div>
          </div>

          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.video_url">
                {{ add_errors.video_url.join(' ') }}
              </p>

              <input
                type="url"
                name="video_url"
                id="video_url"
                placeholder="www.example.com"
                required
                autocomplete="off"
                v-model="add_data.video_url"
              />
              <label for="video_url">{{ $t('video_url') }}</label>
            </div>
          </div>
          <div v-if="add_data.video_url" class="prose prose-container">
            <div
              v-if="/^<(iframe|embed)+/g.test(add_data.video_url)"
              v-html="add_data.video_url"
              class="w-full"
            ></div>
            <div v-else-if="/^(https?:\/\/[^ ]*)/.test(add_data.video_url)">
              <video
                :poster="
                  add_data.image
                    ? convertImage(add_data.image)
                    : require('@/assets/images/placeholder.png')
                "
                @error="
                  $event.target.poster = require('@/assets/images/placeholder.png')
                "
                :controls="!!add_data.image"
              >
                <source :src="add_data.video_url" />
              </video>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.tags">
                {{ add_errors.tags.join(' ') }}
              </p>

              <v-tags
                v-model="add_tags"
                class="v-tags"
                :tags="add_data.tags"
                @tags-changed="(newTags) => (add_data.tags = newTags)"
              />
              <label for="tags">{{ $t('keywords') }}</label>
            </div>
          </div>

          <div>
            <button
              class="
                w-full
                h-auto
                bg-primary
                text-white
                font-medium
                rtl:font-semibold
                text-sm
                rounded-md
                text-center
                py-3
                px-4
              "
              @click.prevent="addItem"
              :disabled="add_disabled"
            >
              <spinner
                size="w-4 h-4"
                class="inline-block align-sub"
                v-if="add_disabled"
              />

              {{ $t('save_changes') }}
            </button>
          </div>
        </form>
      </template>
    </Modal>
    <Modal
      :title="$t('update')"
      v-if="edit_active"
      @close="
        (e) => {
          edit_active = e
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <figure class="space-y-4">
            <slide-transition>
              <figcaption v-if="edit_data.image" class="relative table">
                <img
                  :src="
                    edit_data.image
                      ? typeof edit_data.image === 'object'
                        ? convertImage(edit_data.image)
                        : edit_data.image
                      : require('@/assets/images/placeholder.png')
                  "
                  @error="
                    (e) =>
                      (e.target.src = require('@/assets/images/placeholder.png'))
                  "
                  alt="category image"
                  class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                />
                <button
                  class="
                    w-6
                    h-6
                    rounded-full
                    flex
                    items-center
                    justify-center
                    bg-red-500
                    text-white
                    absolute
                    top-0
                    right-0
                    rtl:right-auto rtl:left-0
                    ring-2 ring-white
                  "
                  type="button"
                  @click.prevent="() => clearImage('edit_data')"
                >
                  <i class="fa-solid fa-xmark fa-sm"></i>
                </button>
              </figcaption>
            </slide-transition>
            <blockquote class="space-y-2">
              <label class="block">
                <span class="sr-only">Choose image</span>
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file"
                  ref="uploader"
                  @change="(e) => $set(edit_data, 'image', e.target.files[0])"
                />
              </label>
              <p class="error" v-if="edit_errors.image">
                {{ edit_errors.image.join(' ') }}
              </p>
            </blockquote>
          </figure>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.title">
                {{ edit_errors.title.join(' ') }}
              </p>
              <input
                type="text"
                name="title"
                id="title"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="edit_data.title"
                v-ltr
              />
              <label for="title"
                >{{ $t('title') }} | {{ $t('langs.en') }}</label
              >
            </div>
          </div>

          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.alt_title">
                {{ edit_errors.alt_title.join(' ') }}
              </p>

              <input
                type="text"
                name="alt_title"
                id="alt_title"
                placeholder="الاسم"
                required
                autocomplete="off"
                v-model="edit_data.alt_title"
                v-rtl
              />
              <label for="alt_title"
                >{{ $t('title') }} | {{ $t('langs.ar') }}</label
              >
            </div>
          </div>

          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.video_url">
                {{ edit_errors.video_url.join(' ') }}
              </p>

              <input
                type="url"
                name="video_url"
                id="video_url"
                placeholder="www.example.com"
                required
                autocomplete="off"
                v-model="edit_data.video_url"
              />
              <label for="video_url">{{ $t('video_url') }}</label>
            </div>
          </div>

          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.tags">
                {{ edit_errors.tags.join(' ') }}
              </p>

              <v-tags
                v-model="add_tags"
                class="v-tags"
                :tags="edit_data.tags"
                @tags-changed="(newTags) => (edit_data.tags = newTags)"
              />
              <label for="tags">{{ $t('keywords') }}</label>
            </div>
          </div>
          <div>
            <button
              class="
                w-full
                h-auto
                bg-primary
                text-white
                font-medium
                rtl:font-semibold
                text-sm
                rounded-md
                text-center
                py-3
                px-4
              "
              @click.prevent="editItem"
              :disabled="edit_disabled"
            >
              <spinner
                size="w-4 h-4"
                class="inline-block align-sub"
                v-if="edit_disabled"
              />

              {{ $t('save_changes') }}
            </button>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>
<script>
import Modal from '@/components/basics/Modal.vue'
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'

import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Tutorials',
  data() {
    return {
      load: false,
      items: [],
      modules: [],
      add_data: {},
      add_errors: {},
      add_disabled: false,
      add_active: false,

      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,

      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
      status: ['all', 'active', 'not active'],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: 'asc',
      },
      disables: {},
      add_tags: '',
    }
  },
  async created() {
    Promise.all([await this.getItems()]).finally(() => (this.load = true))
  },
  watch: {
    'pagination.page'() {
      this.getItems()
    },
    is_called(val, _) {
      if (val) {
        this.getItems()
      }
    },
    add_active(val, _) {
      if (!val) {
        const uploader = this.$refs.uploader
        this.add_data = {
          tags: [],
        }
        this.add_errors = {}
        uploader.value = null
      }
    },
  },
  computed: {
    ...mapGetters('table', ['search', 'is_called']),
  },
  methods: {
    ...mapActions('getting', ['getRoute']),
    ...mapMutations('table', ['changeIsCalled']),

    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination

        const { result } = await this.getRoute({
          name: 'tutorials',
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === 'active'
                ? 1
                : 0
              : null,
            search_key: this.search,
          },
        })
        const { data, pagination } = result.tutorials
        this.items = data
        this.$set(this.pagination, 'total', pagination.total)
        this.$set(this.pagination, 'total_pages', pagination.total_pages)
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      } finally {
        this.changeIsCalled(false)
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true)
      try {
        const { data } = await this.axios.post(
          `tutorials/update_is_active/${id}`
        )
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)
      try {
        const { isConfirmed } = await this.deleteAlert();
        if (!isConfirmed) return
        const { data } = await this.axios.post(`tutorials/delete/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async addItem() {
      try {
        this.add_disabled = true
        this.add_errors = {}
        let fd = new FormData()

        for (const key in this.add_data) {
          const item = this.add_data[key]
          if (['module_ids'].includes(key)) {
            item.forEach((e, i) => {
              fd.append(`${key}[${i}]`, e)
            })
          } else if (key === 'image') {
            if (item) {
              fd.append(key, item, item?.name)
            }
          } else if (key === 'tags') {
            for (const tag in item) {
              fd.append(`${key}[${tag}]`, item[tag]?.text)
            }
          } else {
            fd.append(key, item)
          }
        }

        const { data } = await this.axios.post('tutorials/add', fd)
        this.add_data = {}
        this.add_active = false
        this.$refs.uploader.value = null
        this.createAlert(data?.message)
        this.getItems()
      } catch (error) {
        const err = error?.response
        if (!err || !err?.data) {
          this.createAlert('there is something went wrong', 'error')
          return
        }
        const responseError = err?.data?.message

        if (typeof responseError === 'object') {
          this.add_errors = responseError
        } else {
          this.error = responseError
        }
      } finally {
        this.add_disabled = false
      }
    },
    async editItem() {
      try {
        this.edit_disabled = true
        this.edit_errors = {}
        let fd = new FormData()

        for (const key in this.edit_data) {
          const item = this.edit_data[key]
          if (['module_ids'].includes(key)) {
            item.forEach((e, i) => {
              fd.append(`${key}[${i}]`, e)
            })
          } else if (key === 'image') {
            if (item && typeof item === 'object') {
              fd.append(key, item, item?.name)
            } else {
              fd.delete(key)
            }
          } else if (key === 'tags') {
            for (const tag in item) {
              fd.append(`${key}[${tag}]`, item[tag]?.text || item[tag])
            }
          } else {
            fd.append(key, item)
          }
        }

        const { data } = await this.axios.post('tutorials/update', fd)
        this.edit_data = {}
        this.edit_active = false
        this.$refs.uploader.value = null
        this.createAlert(data?.message)
        this.getItems()
      } catch (error) {
        const err = error?.response
        if (!err || !err?.data) {
          this.createAlert('there is something went wrong', 'error')
          return
        }
        const responseError = err?.data?.message

        if (typeof responseError === 'object') {
          this.edit_errors = responseError
        } else {
          this.error = responseError
        }
      } finally {
        this.edit_disabled = false
      }
    },
    clearImage(type) {
      const uploader = this.$refs.uploader
      if (!uploader) return
      uploader.value = null
      this.$set(this[type], 'image', null)
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.edit_data = {
        ...item,
        tags: item?.tags?.map((e) => ({
          text: e,
        })),
      }
      this.edit_active = true
    },

    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      this.$set(this.pagination, 'is_active', status)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
  },
  components: { Modal, Table, TableLoad },
}
</script>
