<template>
  <div>
    <div class="px-6 pb-20">
      <section
        class="flex gap-4 flex-col-reverse md:flex-row md:min-h-[calc(100vh-250px)] md:max-h-[48rem]"
      >
        <aside class="flex-1 w-full md:max-w-sm">
          <div
            class="w-full h-full bg-white border border-gray-200 flex flex-col"
          >
            <div>
              <header
                class="w-full border-b border-gray-100 p-4 flex items-center gap-4"
              >
                <div class="flex-1">
                  <p
                    class="font-medium rtl:font-semibold text-sm block truncate"
                  >
                    All Messages
                  </p>
                </div>
                <div>
                  <button
                    class="w-8 h-8 rounded-full flex md:hidden items-center justify-center text-gray-600"
                    @click.prevent="openDrop"
                  >
                    <i
                      class="fa-solid fa-angle-right block rtl:hidden fa-sm align-middle"
                    ></i>
                    <i
                      class="fa-solid fa-angle-left hidden rtl:block fa-sm align-middle"
                    ></i>
                  </button>
                </div>
              </header>
            </div>

            <div class="flex-1 overflow-y-auto hidden md:block dropdown">
              <div class="w-full h-full" v-if="load && items.length !== 0">
                <router-link
                  v-for="(item, index) in items"
                  :to="`/messages/${item.id}?page=${pagination.page}`"
                  :key="index"
                  class="group border-b border-gray-100 p-4 last:border-0 hover:bg-gray-100 block"
                >
                  <figure class="w-full">
                    <figcaption class="w-full">
                      <div class="flex items-center">
                        <div>
                          <div class="w-10 h-10">
                            <img
                              src="@/assets/images/profile.jpg"
                              alt="user profile image"
                              class="w-full h-full rounded-full object-cover border border-gray-200 block sticky top-20"
                            />
                          </div>
                        </div>
                        <div class="px-4 space-y-1 flex-1">
                          <div class="table">
                            <div class="grid">
                              <p
                                class="text-sm text-primary font-medium rtl:font-semibold table truncate"
                              >
                                {{ item.name }}
                              </p>
                              <a
                                :href="`mailto:${item.email}`"
                                class="text-xs text-gray-400 block font-medium rtl:font-semibold truncate"
                                >{{ item.email }}</a
                              >
                            </div>
                          </div>
                        </div>
                        <div>
                          <button
                            class="w-8 h-8 rounded-full flex items-center justify-center bg-primary/10 text-primary group-hover:bg-primary/20"
                          >
                            <i
                              class="fa-solid fa-angle-right block rtl:hidden fa-sm align-middle"
                            ></i>
                            <i
                              class="fa-solid fa-angle-left hidden rtl:block fa-sm align-middle"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </figcaption>
                    <blockquote class="space-y-1 pt-4 px-2">
                      <p
                        class="text-sm text-gray-600 font-medium rtl:font-semibold line-clamp-2"
                        v-html="GenerateContent(item.last_msg)"
                      ></p>
                      <p
                        class="text-xs text-gray-400 font-medium rtl:font-semibold line-clamp-2"
                      >
                        {{ item.last_msg_time | moment('h:ma , ddd MMM-yyyy') }}
                      </p>
                    </blockquote>
                  </figure>
                </router-link>
              </div>
              <div class="space-y-6 p-4" v-if="!load">
                <div class="flex items-center" v-for="i in 4" :key="i">
                  <div>
                    <div
                      class="w-10 h-10 rounded-full bg-gray-200 animate-pulse"
                    ></div>
                  </div>
                  <div class="px-4 space-y-2 flex-1">
                    <div
                      class="w-full max-w-[10rem] h-4 rounded-sm bg-gray-200 animate-pulse"
                    ></div>
                    <div
                      class="w-full max-w-xs h-3 rounded-sm bg-gray-200 animate-pulse"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="table m-auto p-4" v-if="load && items.length === 0">
                <div
                  class="flex items-center justify-center flex-col gap-4 py-6"
                >
                  <div>
                    <div
                      class="w-14 h-14 flex items-center justify-center bg-gray-100 rounded-full text-gray-500"
                    >
                      <i class="fa-solid fa-user-group"></i>
                    </div>
                  </div>
                  <div class="flex-1 space-y-2 text-center">
                    <h1
                      class="text-sm text-primary font-semibold rtl:font-bold"
                    >
                      There are no users
                    </h1>
                    <!-- <p
                      class="text-sm text-gray-400 max-w-xl font-medium rtl:font-semibold"
                    >
                      Click on the user from the other side if there are any
                      users available to complete the discussion and
                      conversation.
                    </p> -->
                  </div>
                </div>
              </div>
            </div>
            <div>
              <footer class="w-full">
                <div
                  class="table mx-auto !py-6 px-4"
                  v-if="load && pagination.total_pages > 1"
                >
                  <paginate
                    v-model="pagination.page"
                    :page-count="pagination.total_pages"
                    :page-range="5"
                    :margin-pages="2"
                    :prev-text="'Previous'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                  >
                  </paginate>
                </div>
              </footer>
            </div>
          </div>
        </aside>
        <slot></slot>
      </section>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MessagesContainerWrapper',
  data() {
    return {
      load: false,
      // items: [],
      pagination: {
        page: 1,
        total: 0,
        total_pages: 0,
        search_key: null,
      },
    }
  },

  created() {
    this.getItems()
  },
  computed: {
    items() {
      const { data, pagination } = this.$store.getters['chat/getUsers']
      this.$emit('items', data)
      this.$set(this.pagination, 'total', pagination.total)
      this.$set(this.pagination, 'total_pages', pagination.total_pages)
      return data
    },
    GenerateContent() {
      return (text) =>
        text.replace(
          /(?:(?:https?:\/\/)|(?:www\.))[^\s]+(?!^(.+?)([.,?!'"]*)$)/gi,
          (e) =>
            `<a href="${e.replace(
              /['|"]$/gi,
              ''
            )}" class="text-primary underline">${e}</a>`
        )
    },
  },
  methods: {
    getItems() {
      const { page, search_key } = this.pagination
      return this.$store
        .dispatch('chat/GetUsers', {
          page: this.$route.query?.page || page,
          search_key,
        })
        .catch((err) => this.createAlert(err))
        .finally(() => {
          this.load = true
        })
    },

    openDrop(e) {
      const icons = e.currentTarget.childNodes ?? []
      const dropDowns = document.querySelectorAll('.dropdown')
      this.multiAngle(icons)
      dropDowns.forEach((el) => {
        if (el.classList.contains('hidden')) {
          el.classList.replace('hidden', 'block')
        } else {
          el.classList.replace('block', 'hidden')
        }
      })
    },
  },
}
</script>
