import axios from 'axios'

export default {
  namespaced: true,
  state: {
    users: [],
    pagination: {},
    messages: [],
  },
  getters: {
    getUsers: (state) => {
      return {
        data: state.users,
        pagination: state.pagination,
      }
    },
  },
  mutations: {
    SET_USERS(state, { data, pagination }) {
      state.users = data
      state.pagination = pagination
    },
    UPDATE_MESSAGE(state, data) {
      const { user, created_at, body, ...other } = data
      const findUser = state.users.find((e) => e.id === user.id)
      if (!findUser) return
      Object.assign(findUser, {
        last_msg_time: created_at,
        last_msg: body,
      })
    },
    UPDATE_ITEMS(state, data) {
      state.messages = data
    },
    UPDATE_ITEM(state, data) {
      state.messages = [...state.messages, data]
    },
  },
  actions: {
    GetUsers({ commit }, options) {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await axios.get('support_messages/users', {
            params: { ...options },
          })
          commit('SET_USERS', data.result.users)
          resolve()
        } catch (error) {
          const res = error?.response
          if (!res) {
            reject('There is something went wrong, please try again later.')
            return
          }
          reject(res?.data?.message)
        }
      })
    },
    AddNewMessage({ state, commit }, data) {
      return new Promise((_, reject) => {
        const user = state.users.find((e) => e?.id === data?.user_id)
        if (!user) {
          reject('Invalid user id')
          return
        }
        commit('UPDATE_MESSAGE', { user, message: data?.body })
      })
    },
  },
  modules: {},
}
