import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '@/store'

Vue.use(VueAxios, axios)

axios.defaults.baseURL = store.state.BASE_URL
axios.interceptors.request.use(async (config) => {
  const token = store.state.auth.token || Vue.$cookies.get('t')
  if (!!token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  config.headers['lang'] = Vue.$cookies.get('locale') || 'ar'
  return config
})

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    // if (error.response.status === 429) {
    //   Vue.$cookies.remove('t')
    //   location.reload()
    // }

    return Promise.reject(error)
  }
)
