<template>
  <website-tabs>
    <div>
      <section class="w-full space-y-6">
        <main class="w-full px-6">
          <Table
            :has_header="true"
            :has_check="false"
            :title="$t('pages')"
            :records="`${pagination.total} ${$t('records')}`"
            :total_pages="pagination.total_pages"
            v-model="pagination.page"
          >
            <template #actions>
              <div>
                <button
                  class="py-1.5 px-3 rounded-sm text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
                  @click.prevent="add_active = !add_active"
                >
                  <i class="fa-solid fa-plus"></i>
                  <span>{{ $t('add_new.page') }}</span>
                </button>
              </div>
            </template>
            <template #filter>
              <ul class="flex items-center">
                <li v-for="(item, index) in status" :key="index">
                  <button
                    class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                    @click.prevent="
                      changeFilterStatus(item === 'all' ? null : item, index)
                    "
                    :class="{
                      'active-tab':
                        pagination.is_active === (item === 'all' ? null : item),
                    }"
                    :disabled="disables[index]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      class="mr-2 rtl:mr-0 rtl:ml-2"
                      v-if="disables[index]"
                    />
                    {{ $t(item.replace(/\s/g, '_')) }}
                  </button>
                </li>
              </ul>
            </template>
            <template #head>
              <th>{{ $t('name') }}</th>
              <th>{{ $t('alt_name') }}</th>

              <th>
                <div class="sort">
                  <div>
                    <p>{{ $t('active') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('is_active')"
                    ></button>
                  </div>
                </div>
              </th>

              <th>
                <div class="sort">
                  <div>
                    <p>{{ $t('updated_at') }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('updated_at')"
                    ></button>
                  </div>
                </div>
              </th>
              <th></th>
            </template>
            <template #tbody v-if="!load">
              <TableLoad :rows="5" v-for="i in 6" :key="i" />
            </template>
            <template #tbody v-else-if="load && items.length !== 0">
              <tr v-for="(item, index) in items" :key="index">
                <td>{{ item.name || '-' }}</td>
                <td>{{ item.alt_name || '-' }}</td>

                <td>
                  <p
                    class="status capitalize"
                    :class="item_status[item.is_active]"
                  >
                    {{ item.is_active === 1 ? $t('active') : $t('not_active') }}
                  </p>
                </td>
                <td
                  v-html="createTime(item.updated_at || item.created_at)"
                ></td>

                <td>
                  <div class="actions-group">
                    <div>
                      <button
                        class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded-sm"
                        @click.prevent="changeStatus(item.id)"
                        :content="$t('change_status')"
                        v-tippy
                        :disabled="disables[`status_${item.id}`]"
                      >
                        <spinner
                          size="w-4 h-4 inline-block"
                          v-if="disables[`status_${item.id}`]"
                        />
                        <i class="fa-solid fa-right-left" v-else></i>
                      </button>
                    </div>
                    <div>
                      <button
                        class="w-8 h-8 bg-sky-500 text-white rounded-sm"
                        @click.prevent="showUpdate(item.id)"
                        :content="$t('update')"
                        v-tippy
                      >
                        <i class="fa-solid fa-pen"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            <template #tbody v-else-if="load && items.length === 0">
              <tr>
                <td colspan="5">
                  <div class="table mx-auto my-4">
                    <button
                      class="py-1.5 px-3 rounded-sm text-sm flex items-center gap-2 text-gray-600 ring-1 ring-gray-300 font-medium rtl:font-semibold"
                      @click.prevent="add_active = !add_active"
                    >
                      <i class="fa-solid fa-plus"></i>
                      <span>{{ $t('add_new.page') }}</span>
                    </button>
                  </div>
                  <p
                    class="text-sm text-gray-600 font-medium rtl:font-semibold text-center"
                  >
                    {{ $t('no_data') }}
                  </p>
                </td>
              </tr>
            </template>
          </Table>
        </main>
      </section>
      <transition name="scale">
        <Modal
          :title="$t('add_new.page')"
          v-if="add_active"
          @close="add_active = $event"
        >
          <template>
            <form class="p-6 space-y-4">
              <div class="form-group">
                <div class="with-label">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    required
                    autocomplete="off"
                    v-model="add_data.name"
                    v-ltr
                  />
                  <label for="name">{{ $t('name') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.name"
                >
                  {{ add_errors.name.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <input
                    type="text"
                    name="alt_name"
                    id="alt_name"
                    placeholder="الاسم"
                    required
                    autocomplete="off"
                    v-model="add_data.alt_name"
                    v-rtl
                  />
                  <label for="alt_name">{{ $t('alt_name') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.alt_name"
                >
                  {{ add_errors.alt_name.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <VueEditor
                    v-model="add_data.content"
                    :editor-toolbar="customToolbar"
                  />

                  <label for="content"
                    >{{ $t('content') }} | {{ $t('langs.ar') }}</label
                  >
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.content"
                >
                  {{ add_errors.content.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <VueEditor
                    v-model="add_data.alt_content"
                    :editor-toolbar="customToolbar"
                  />

                  <label for="alt_content"
                    >{{ $t('content') }} | {{ $t('langs.en') }}</label
                  >
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.alt_content"
                >
                  {{ add_errors.alt_content.join(' ') }}
                </p>
              </div>

              <div class="actions-group">
                <div>
                  <button
                    class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                    @click.prevent="addItem"
                    :disabled="add_disabled"
                  >
                    <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
                    {{ $t('add') }}
                  </button>
                </div>
              </div>
            </form>
          </template>
        </Modal>
      </transition>
      <transition name="scale">
        <Modal
          :title="$t('update')"
          v-if="edit_active"
          @close="edit_active = $event"
        >
          <template>
            <form class="p-6 space-y-4">
              <div class="form-group">
                <div class="with-label">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    required
                    autocomplete="off"
                    v-model="edit_data.name"
                    v-ltr
                  />
                  <label for="name">{{ $t('name') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.name"
                >
                  {{ edit_errors.name.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <input
                    type="text"
                    name="alt_name"
                    id="alt_name"
                    placeholder="الاسم"
                    required
                    autocomplete="off"
                    v-model="edit_data.alt_name"
                    v-rtl
                  />
                  <label for="alt_name">{{ $t('alt_name') }}</label>
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.alt_name"
                >
                  {{ edit_errors.alt_name.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <VueEditor
                    v-model="edit_data.content"
                    :editor-toolbar="customToolbar"
                  />

                  <label for="content"
                    >{{ $t('content') }} | {{ $t('langs.ar') }}</label
                  >
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.content"
                >
                  {{ edit_errors.content.join(' ') }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <VueEditor
                    v-model="edit_data.alt_content"
                    :editor-toolbar="customToolbar"
                  />

                  <label for="alt_content"
                    >{{ $t('content') }} | {{ $t('langs.en') }}</label
                  >
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.alt_content"
                >
                  {{ edit_errors.alt_content.join(' ') }}
                </p>
              </div>
              <div class="actions-group">
                <div>
                  <button
                    class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                    @click.prevent="editItem"
                    :disabled="edit_disabled"
                  >
                    <spinner size="w-4 h-4 inline-block" v-if="edit_disabled" />
                    {{ $t('save_changes') }}
                  </button>
                </div>
              </div>
            </form>
          </template>
        </Modal>
      </transition>
    </div>
  </website-tabs>
</template>
<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import Modal from '@/components/basics/Modal.vue'
import { mapGetters, mapMutations } from 'vuex'
import { VueEditor } from 'vue2-editor'
import WebsiteTabs from '@/components/WebsiteTabs.vue'

export default {
  name: 'Pages',
  data() {
    return {
      load: false,
      items: [],
      add_data: {},
      add_active: false,
      add_errors: {},
      add_disabled: false,
      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }], // dropdown with defaults from theme
        [{ align: [] }],
        [{ direction: 'rtl' }],
      ],
      pagination: {
        page: 1,
        total_pages: 0,
        search_key: null,
        sort_key: null,
        sort_type: 'asc',
        is_active: null,
        total: 0,
      },
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },

      apply_disabled: false,
      reset_disabled: false,
      status: ['all', 'active', 'not active'],
      disables: {},
    }
  },
  watch: {
    'pagination.page'() {
      this.getItems()
    },
    edit_active() {
      if (!this.edit_active) {
        this.edit_data = {}
        this.edit_errors = {}
        this.edit_disabled = false
      }
    },
    add_active() {
      if (!this.add_active) {
        this.add_data = {}
        this.add_errors = {}
        this.add_disabled = false
      }
    },
    is_called(val) {
      if (val) this.getItems()
    },
  },
  computed: {
    ...mapGetters('table', ['search', 'is_called']),
  },
  created() {
    this.getItems()
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled']),
    getItems() {
      const { page, search_key, sort_key, sort_type, is_active } =
        this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'pages',
          options: {
            page,
            search_key: this.search,
            sort_key,
            sort_type,
            is_active: is_active ? (is_active === 'active' ? 1 : 0) : is_active,
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.pages
          this.items = data
          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
        })
        .catch((err) => {
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => {
          this.load = true
          this.changeIsCalled(false)
        })
    },
    async addItem() {
      try {
        this.add_disabled = true
        this.add_errors = {}

        const { data } = await this.axios.post('pages/add', this.add_data)
        this.getItems()
        this.createAlert(data.message)
        this.add_active = false
        this.add_data = {}
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    async editItem() {
      try {
        this.edit_disabled = true
        this.edit_errors = {}

        const { data } = await this.axios.post('pages/update', this.edit_data)
        const { message, result } = data
        const finalResult = result.page ?? {}
        this.getItems()
        this.createAlert(message)
        // this.edit_active = false
        this.edit_data = {
          ...finalResult,
        }
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.edit_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.edit_errors = res?.data?.message
      } finally {
        this.edit_disabled = false
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true)
      try {
        const { data } = await this.axios.post(`pages/update_is_active/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)
      try {
        const { isConfirmed } = await this.deleteAlert()
        if (!isConfirmed) return
        const { data } = await this.axios.post(`pages/delete/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.edit_data = {
        ...item,
      }
      this.edit_active = true
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination)
        .filter((e) => ['status', 'search_key'].includes(e))
        .forEach((key) => this.$set(this.pagination, key, null))
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      this.$set(this.pagination, 'is_active', status)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
  },
  components: { Table, Modal, TableLoad, VueEditor, WebsiteTabs },
}
</script>
