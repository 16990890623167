<template>
  <div>
    <section class="w-full space-y-6 px-6">
      <filter-wrapper>
        <div class="w-full">
          <multiselect
            v-model="pagination.store_id"
            :options="stores"
            :placeholder="$t('store')"
            id="store_id"
            style="background-color: #ffffff !important"
            :hideSelected="true"
            :clearOnSelect="true"
            label="name"
            track-by="name"
            :disabled="stores.length === 0"
          ></multiselect>
        </div>
        <div class="w-full">
          <multiselect
            v-model="pagination.country_id"
            :options="countries"
            :placeholder="$t('country')"
            id="country_id"
            style="background-color: #ffffff !important"
            :hideSelected="true"
            :clearOnSelect="true"
            label="name"
            track-by="name"
            :disabled="countries.length === 0"
          ></multiselect>
        </div>
        <div class="w-full">
          <multiselect
            v-model="pagination.region_id"
            :options="
              FilterData(
                'pagination',
                'regions',
                'country',
                'country_id',
                'region_id'
              )
            "
            :placeholder="$t('region')"
            id="region_id"
            style="background-color: #ffffff !important"
            :hideSelected="true"
            :clearOnSelect="true"
            label="name"
            track-by="name"
            :disabled="
              FilterData(
                'pagination',
                'regions',
                'country',
                'country_id',
                'region_id'
              ).length === 0
            "
          ></multiselect>
        </div>
        <div class="w-full">
          <multiselect
            v-model="pagination.city_id"
            :options="
              FilterData(
                'pagination',
                'cities',
                'region',
                'region_id',
                'city_id'
              )
            "
            :placeholder="'city'"
            id="city_id"
            style="background-color: #ffffff !important"
            :hideSelected="true"
            :clearOnSelect="true"
            label="name"
            track-by="name"
            :disabled="
              FilterData(
                'pagination',
                'cities',
                'region',
                'region_id',
                'city_id'
              ).length === 0
            "
          ></multiselect>
        </div>
        <div class="w-full">
          <div class="actions-group">
            <div>
              <button
                class="w-full h-auto bg-secondary text-white font-medium rtl:font-semibold text-sm rounded-md text-center py-2.5 px-4"
                @click.prevent="applyFilter"
                :disabled="apply_disabled"
              >
                <spinner size="w-4 h-4 inline-block" v-if="apply_disabled" />
                {{ $t('apply') }}
              </button>
            </div>
            <div>
              <button
                class="w-full h-auto bg-gray-300 text-gray-600 font-medium rtl:font-semibold text-sm rounded-md text-center py-2.5 px-4"
                @click.prevent="resetFilter"
                :disabled="reset_disabled"
              >
                <spinner size="w-4 h-4 inline-block" v-if="reset_disabled" />
                {{ $t('reset') }}
              </button>
            </div>
          </div>
        </div>
      </filter-wrapper>

      <main class="w-full">
        <Table
          :has_header="true"
          :has_export="true"
          :has_import="true"
          :has_check="false"
          :fields="fields"
          :title="$t('branches')"
          :records="`${pagination.total} ${$t('records')}`"
          :total_pages="pagination.total_pages"
          v-model="pagination.page"
        >
          <template #actions>
            <div>
              <button
                class="py-1.5 px-3 rounded-sm text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
                @click.prevent="add_active = !add_active"
              >
                <i class="fa-solid fa-plus"></i>
                <span>{{ $t('add_new.branch') }}</span>
              </button>
            </div>
          </template>
          <template #filter>
            <ul class="flex items-center">
              <li v-for="(item, index) in status" :key="index">
                <button
                  class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                  @click.prevent="
                    changeFilterStatus(item === 'all' ? null : item, index)
                  "
                  :class="{
                    'active-tab':
                      pagination.requested_branches ===
                        (item === 'all' ? null : item) ||
                      pagination.is_active === (item === 'all' ? null : item),
                  }"
                  :disabled="disables[index]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    class="mr-2 rtl:mr-0 rtl:ml-2"
                    v-if="disables[index]"
                  />
                  {{ $t(item.replace(/\s/g, '_')) }}
                </button>
              </li>
            </ul>
          </template>
          <template #head>
            <th>{{ $t('name') }}</th>
            <th>{{ $t('alt_name') }}</th>
            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('mobile') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('mobile')"
                  ></button>
                </div>
              </div>
            </th>
            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('email') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('email')"
                  ></button>
                </div>
              </div>
            </th>
            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('store') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('store_id')"
                  ></button>
                </div>
              </div>
            </th>
            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('country') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('country_id')"
                  ></button>
                </div>
              </div>
            </th>
            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('region') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('region_id')"
                  ></button>
                </div>
              </div>
            </th>
            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('city') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('city_id')"
                  ></button>
                </div>
              </div>
            </th>
            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('active') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('is_active')"
                  ></button>
                </div>
              </div>
            </th>

            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('updated_at') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('updated_at')"
                  ></button>
                </div>
              </div>
            </th>

            <th></th>
          </template>
          <template #tbody v-if="!load">
            <TableLoad :rows="11" v-for="i in 6" :key="i" />
          </template>
          <template #tbody v-else-if="load && items.length !== 0">
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ item.name || '-' }}</td>
              <td>{{ item.alt_name || '-' }}</td>
              <td>
                <div class="grid min-w-[8rem] max-w-[10rem]" v-if="item.mobile">
                  <a
                    :href="`mailto:${item.mobile}`"
                    class="text-sky-500 font-semibold truncate"
                    >{{ item.mobile }}</a
                  >
                </div>
                <span v-else>-</span>
              </td>
              <td>
                <div class="grid min-w-[8rem] max-w-[10rem]" v-if="item.email">
                  <a
                    :href="`mailto:${item.email}`"
                    class="text-sky-500 font-semibold truncate"
                    >{{ item.email }}</a
                  >
                </div>
                <span v-else>-</span>
              </td>

              <td>{{ (item.store || {}).name || '-' }}</td>
              <td>{{ (item.country || {}).name || '-' }}</td>
              <td>{{ (item.region || {}).name || '-' }}</td>
              <td>{{ (item.city || {}).name || '-' }}</td>
              <td>
                <p
                  class="status capitalize"
                  :class="item_status[item.is_active]"
                >
                  {{ item.is_active === 1 ? $t('active') : $t('not_active') }}
                </p>
              </td>
              <td v-html="createTime(item.updated_at || item.created_at)"></td>

              <td>
                <div class="actions-group">
                  <div>
                    <button
                      class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                      @click.prevent="deleteItem(item.id)"
                      :disabled="disables[`delete_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`delete_${item.id}`]"
                      />
                      <span v-else> {{ $t('delete') }} </span>
                    </button>
                  </div>
                  <div v-if="!pagination.requested_branches">
                    <button
                      class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded-sm"
                      @click.prevent="changeStatus(item.id)"
                      :content="$t('change_status')"
                      v-tippy
                      :disabled="disables[`status_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`status_${item.id}`]"
                      />
                      <i class="fa-solid fa-right-left" v-else></i>
                    </button>
                  </div>
                  <div v-if="!pagination.requested_branches">
                    <button
                      class="w-8 h-8 bg-sky-500 text-white rounded-sm"
                      @click.prevent="showUpdate(item.id)"
                      :content="$t('update')"
                      v-tippy
                    >
                      <i class="fa-solid fa-pen"></i>
                    </button>
                  </div>
                  <div
                    v-if="!pagination.requested_branches && item.wrong_location"
                  >
                    <button
                      class="w-8 h-8 bg-red-500 text-white rounded-sm"
                      @click.prevent="showUpdate(item.id)"
                      :content="$t('wrong_location')"
                      v-tippy
                    >
                      <i class="fa-solid fa-triangle-exclamation"></i>
                    </button>
                  </div>

                  <div v-if="pagination.requested_branches">
                    <button
                      class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded-sm"
                      @click.prevent="fetchBranchesData(item)"
                      :content="$t('add_data')"
                      v-tippy
                      :disabled="disables[`fetch_branches_data_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`fetch_branches_data_${item.id}`]"
                      />
                      <i class="fa-solid fa-pen" v-else></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template #tbody v-else-if="load && items.length === 0">
            <tr>
              <td colspan="11">
                <div class="table mx-auto my-4">
                  <button
                    class="py-1.5 px-3 rounded-sm text-sm flex items-center gap-2 text-gray-600 ring-1 ring-gray-300 font-medium rtl:font-semibold"
                    @click.prevent="add_active = !add_active"
                  >
                    <i class="fa-solid fa-plus"></i>
                    <span>{{ $t('add_new.branch') }}</span>
                  </button>
                </div>
                <p
                  class="text-sm text-gray-600 font-medium rtl:font-semibold text-center"
                >
                  {{ $t('no_data') }}
                </p>
              </td>
            </tr>
          </template>
        </Table>
      </main>
    </section>
    <transition name="scale">
      <Modal
        :title="$t('add_new.branch')"
        v-if="add_active"
        @close="
          (e) => {
            add_active = e
            add_data = {}
            add_errors = {}
            item = {}
            show_detail = false
          }
        "
      >
        <template>
          <form class="p-6 space-y-4">
            <div class="form-group">
              <div class="with-label">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  autocomplete="off"
                  v-model="add_data.name"
                  v-ltr
                />
                <label for="name">{{ $t('name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.name"
              >
                {{ add_errors.name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <input
                  type="text"
                  name="alt_name"
                  id="alt_name"
                  placeholder="الاسم"
                  required
                  autocomplete="off"
                  v-model="add_data.alt_name"
                  v-rtl
                />
                <label for="alt_name">{{ $t('alt_name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.alt_name"
              >
                {{ add_errors.alt_name.join(' ') }}
              </p>
            </div>

            <div class="form-group">
              <div class="with-label">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="example@example.com"
                  required
                  autocomplete="off"
                  v-model="add_data.email"
                  v-email
                />
                <label for="email">{{ $t('email') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.email"
              >
                {{ add_errors.email.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <vue-tel-input
                  id="mobile"
                  ref="branchAddMobile"
                  @open="handleOpen('branchAddMobile')"
                  v-model="add_data.mobile"
                />
                <label for="mobile">{{ $t('mobile') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.mobile"
              >
                {{ add_errors.mobile.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="add_data.store_id"
                  :options="stores"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="store_id"
                ></multiselect>
                <label for="store_id">{{ $t('store') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.store_id"
              >
                {{ add_errors.store_id.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="add_data.country_id"
                  :options="countries"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="country_id"
                ></multiselect>
                <label for="country_id">{{ $t('country') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.country_id"
              >
                {{ add_errors.country_id.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="add_data.region_id"
                  :options="
                    FilterData(
                      'add_data',
                      'regions',
                      'country',
                      'country_id',
                      'region_id'
                    )
                  "
                  :disabled="
                    FilterData(
                      'add_data',
                      'regions',
                      'country',
                      'country_id',
                      'region_id'
                    ).length === 0
                  "
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="region_id"
                ></multiselect>
                <label for="region_id">{{ $t('region') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.region_id"
              >
                {{ add_errors.region_id.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="add_data.city_id"
                  :options="
                    FilterData(
                      'add_data',
                      'cities',
                      'region',
                      'region_id',
                      'city_id'
                    )
                  "
                  :disabled="
                    FilterData(
                      'add_data',
                      'cities',
                      'region',
                      'region_id',
                      'city_id'
                    ).length === 0
                  "
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="city_id"
                ></multiselect>
                <label for="city_id">{{ $t('city') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.city_id"
              >
                {{ add_errors.city_id.join(' ') }}
              </p>
            </div>
            <div class="space-y-2">
              <Map v-model="add_data.location" />
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.address"
              >
                {{ add_errors.address.join(' ') }}
              </p>
            </div>

            <div class="actions-group">
              <div>
                <button
                  class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                  @click.prevent="addItem"
                  :disabled="add_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
                  {{ $t('add') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
    <transition name="scale">
      <Modal
        :title="`${edit_data.name}`"
        v-if="edit_active"
        @close="
          (e) => {
            edit_active = e
            edit_data = {}
            edit_errors = {}
            item = {}
            show_detail = false
          }
        "
      >
        <template>
          <form class="p-6 space-y-4">
            <div class="form-group">
              <div class="with-label">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  autocomplete="off"
                  v-model="edit_data.name"
                  v-ltr
                />
                <label for="name">{{ $t('name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.name"
              >
                {{ edit_errors.name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <input
                  type="text"
                  name="alt_name"
                  id="alt_name"
                  placeholder="الاسم"
                  required
                  autocomplete="off"
                  v-model="edit_data.alt_name"
                  v-rtl
                />
                <label for="alt_name">{{ $t('alt_name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.alt_name"
              >
                {{ edit_errors.alt_name.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="example@example.com"
                  required
                  autocomplete="off"
                  v-model="edit_data.email"
                  v-email
                />
                <label for="email">E-mail editress</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.email"
              >
                {{ edit_errors.email.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <vue-tel-input
                  id="mobile"
                  ref="brancheditMobile"
                  @open="handleOpen('brancheditMobile')"
                  v-model="edit_data.mobile"
                />
                <label for="mobile">{{ $t('mobile') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.mobile"
              >
                {{ edit_errors.mobile.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="edit_data.store_id"
                  :options="stores"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="store_id"
                ></multiselect>
                <label for="store_id">{{ $t('store') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.store_id"
              >
                {{ edit_errors.store_id.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="edit_data.country_id"
                  :options="countries"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="country_id"
                ></multiselect>
                <label for="country_id">{{ $t('country') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.country_id"
              >
                {{ edit_errors.country_id.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="edit_data.region_id"
                  :options="
                    FilterData(
                      'edit_data',
                      'regions',
                      'country',
                      'country_id',
                      'region_id'
                    )
                  "
                  :disabled="
                    FilterData(
                      'edit_data',
                      'regions',
                      'country',
                      'country_id',
                      'region_id'
                    ).length === 0
                  "
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="region_id"
                ></multiselect>
                <label for="region_id">{{ $t('region') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.region_id"
              >
                {{ edit_errors.region_id.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="edit_data.city_id"
                  :options="
                    FilterData(
                      'edit_data',
                      'cities',
                      'region',
                      'region_id',
                      'city_id'
                    )
                  "
                  :disabled="
                    FilterData(
                      'edit_data',
                      'cities',
                      'region',
                      'region_id',
                      'city_id'
                    ).length === 0
                  "
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="city_id"
                ></multiselect>
                <label for="city_id">{{ $t('city') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.city_id"
              >
                {{ edit_errors.city_id.join(' ') }}
              </p>
            </div>
            <div class="space-y-2">
              <Map v-model="edit_data.location" />
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.address || edit_errors.lat || edit_errors.lng"
              >
                {{
                  Object.entries(edit_errors)
                    .filter(([key]) => /(address|lat|lng)/gi.test(key))
                    .map(([key, value]) => value)
                    .join(' ')
                }}
              </p>
            </div>

            <div class="actions-group">
              <div>
                <button
                  class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                  @click.prevent="editItem"
                  :disabled="edit_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="edit_disabled" />
                  Save changed
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
    <transition name="scale">
      <Modal
        :title="$t('add_data')"
        v-if="assign_active"
        @close="
          (e) => {
            assign_active = e
            assign_data = {}
            assign_errors = {}
          }
        "
      >
        <template>
          <form class="p-6 space-y-4">
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="assign_data.brand_ids"
                  :options="brands"
                  :disabled="!brands.length"
                  :multiple="true"
                  :closeOnSelect="false"
                  :taggable="true"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="brand_ids"
                ></multiselect>
                <label for="brand_ids">{{ $t('brands') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="assign_errors.brand_ids"
              >
                {{ assign_errors.brand_ids.join(' ') }}
              </p>
            </div>
            <div class="table [margin-inline-start:auto] !mt-1">
              <button
                class="py-1 px-2 rounded text-xs font-medium rtl:font-semibold text-gray-600"
                @click.prevent="selectAllBrands"
                :disabled="!brands.length"
              >
                {{ $t('select_all') }} ({{ brands.length }})
              </button>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="assign_data.sku_ids"
                  :options="filterSKUs"
                  :disabled="!filterSKUs.length"
                  :multiple="true"
                  :closeOnSelect="false"
                  :taggable="true"
                  :hideSelected="true"
                  :clearOnSelect="true"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="sku_ids"
                ></multiselect>
                <label for="sku_ids">{{ $t('skus') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="assign_errors.sku_ids"
              >
                {{ assign_errors.sku_ids.join(' ') }}
              </p>
            </div>
            <div class="table [margin-inline-start:auto] !mt-1">
              <button
                class="py-1 px-2 rounded text-xs font-medium rtl:font-semibold text-gray-600"
                @click.prevent="selectAllSkus"
                :disabled="!filterSKUs.length"
              >
                {{ $t('select_all') }} ({{ filterSKUs.length }})
              </button>
            </div>
            <div class="actions-group">
              <div>
                <button
                  class="py-2.5 px-4 rounded-md text-xs font-medium rtl:font-semibold text-white bg-primary table"
                  @click.prevent="addRequestedBranchData"
                  :disabled="assign_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="assign_disabled" />
                  {{ $t('add') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
  </div>
</template>
<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import Modal from '@/components/basics/Modal.vue'
import Map from '@/components/basics/Map.vue'
import FilterWrapper from '@/components/basics/FilterWrapper.vue'
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'Branches',
  data() {
    return {
      load: false,
      items: [],
      add_data: {
        location: {},
      },
      stores: [],
      countries: [],
      regions: [],
      cities: [],
      add_active: false,
      add_errors: {},
      show_detail: false,
      add_disabled: false,
      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,
      pagination: {
        page: 1,
        total_pages: 0,
        search_key: null,
        sort_key: null,
        sort_type: 'asc',
        region_id: null,
        store_id: null,
        is_active: null,
        total: 0,
      },
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
      item: {},
      apply_disabled: false,
      reset_disabled: false,
      status: ['all', 'active', 'not active', 'requested_branches'],
      disables: {},
      fields: [],
      assign_data: {
        sku_ids: [],
        brand_ids: [],
        id: null,
      },
      assign_errors: {},
      assign_disabled: false,
      assign_load: false,
      assign_active: false,
      skus: [],
      brands: [],
    }
  },

  computed: {
    ...mapGetters('table', ['search', 'is_called']),
    FilterData() {
      return (type, key, itemKey, neededKey, current_id) => {
        const items = this[key].filter(
          (e) => e?.[itemKey]?.id === this[type]?.[neededKey]?.id
        )
        if (items.length === 0) {
          this.$set(this[type], current_id, null)
        }
        return items ?? []
      }
    },
    filterSKUs() {
      return this.skus.filter((e) => {
        return this.assign_data?.brand_ids
          ?.map((b) => b.id)
          .includes(e.brand?.id)
      })
    },
  },
  watch: {
    is_called(val) {
      if (val) this.getItems()
    },
    'pagination.page'() {
      this.getItems()
    },
    edit_active() {
      if (!this.edit_active) {
        this.edit_data = {}
        this.edit_errors = {}
        this.edit_disabled = false
      }
    },
    add_active() {
      if (!this.add_active) {
        this.add_data = {}
        this.add_errors = {}
        this.add_disabled = false
      }
    },
    'add_data.branch_id'() {
      if (this.add_data.branch_id?.id) {
        const item = JSON.parse(
          JSON.stringify({
            ...this.add_data.branch_id,
            address: [],
          })
        )
        // filter current object and add new key
        Object.keys(item).forEach((key) => {
          const existed = item[key]
          if (['country', 'region', 'city'].includes(key)) {
            if (existed) {
              item.address.push(existed?.name)
            }
          }
          // add some keys as default
          if (['mobile', 'email', 'name'].includes(key)) {
            if (existed) {
              this.$set(this.add_data, key, existed)
            }
          }
        })

        // store new object
        this.item = item
      }
    },
    'edit_data.branch_id'() {
      if (this.edit_data.branch_id?.id) {
        const item = JSON.parse(
          JSON.stringify({
            ...this.edit_data.branch_id,
            address: [],
          })
        )
        // filter current object and add new key
        Object.keys(item).forEach((key) => {
          const existed = item[key]
          if (['country', 'region', 'city'].includes(key)) {
            if (existed) {
              item.address.push(existed?.name)
            }
          }
          // add some keys as default
          if (['mobile', 'email', 'name'].includes(key)) {
            if (existed) {
              this.$set(this.edit_data, key, existed)
            }
          }
        })

        // store new object
        this.item = item
      }
    },
  },
  created() {
    this.getItems()
    this.getStores()
    this.getCountries()
    this.getRegions()
    this.getCities()
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled']),
    getStores() {
      this.$store.dispatch('getting/getRoute', 'stores').then(({ result }) => {
        this.stores = result.stores
      })
    },
    getCountries() {
      this.$store
        .dispatch('getting/getRoute', 'countries')
        .then(({ result }) => {
          this.countries = result.countries
        })
    },
    getRegions() {
      this.$store.dispatch('getting/getRoute', 'regions').then(({ result }) => {
        this.regions = result.regions
      })
    },
    getCities() {
      this.$store.dispatch('getting/getRoute', 'cities').then(({ result }) => {
        this.cities = result.cities
      })
    },
    getBrands(id) {
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'brands',
          options: {
            company_id: id,
          },
        })
        .then(({ result }) => {
          this.brands = result.brands
        })
    },
    getSkus(id) {
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'skus',
          options: {
            company_d: id,
          },
        })
        .then(({ result }) => {
          this.skus = result.skus
        })
    },

    getItems() {
      const {
        page,
        search_key,
        sort_key,
        sort_type,
        is_active,
        country_id,
        region_id,
        city_id,
        store_id,
        requested_branches,
      } = this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'branches',
          options: {
            page,
            search_key: this.search,
            sort_key,
            sort_type,
            country_id: country_id?.id,
            region_id: region_id?.id,
            city_id: city_id?.id,
            store_id: store_id?.id,
            is_active: requested_branches
              ? null
              : is_active
              ? is_active === 'active'
                ? 1
                : 0
              : is_active,
            requested_branches: is_active
              ? null
              : requested_branches
              ? 1
              : null,
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.branches
          this.items = data.map((e) => {
            return {
              ...e,
            }
          })
          this.fields = result.export_fields

          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
        })
        .catch((err) => {
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => {
          this.load = true
          this.changeIsCalled(false)
        })
    },
    async addItem() {
      this.add_disabled = true
      this.add_errors = {}
      const item = this.add_data

      try {
        const { data } = await this.axios.post('branches/add', {
          ...item,
          ...item?.location,
          mobile: item?.mobile?.replace(/[+|\s|()|-]/g, ''),
          country_id: item?.country_id?.id,
          region_id: item?.region_id?.id,
          city_id: item?.city_id?.id,
          store_id: item?.store_id?.id,
        })
        this.getItems()
        this.createAlert(data.message)
        this.add_active = false
        this.add_data = {}
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    async editItem() {
      this.edit_disabled = true
      this.edit_errors = {}
      const item = this.edit_data
      try {
        const { data } = await this.axios.post('branches/update', {
          ...item,
          ...item?.location,
          mobile: item?.mobile?.replace(/[+|\s|()|-]/g, ''),
          country_id: item?.country_id?.id,
          region_id: item?.region_id?.id,
          city_id: item?.city_id?.id,
          store_id: item?.store_id?.id,
        })
        const { message, result } = data
        this.getItems()
        this.createAlert(message)
        // this.edit_active = false
        let finalResult = result.branch
        this.edit_data = {
          ...finalResult,
          location: {
            lat: finalResult?.lat,
            lng: finalResult?.lng,
            address: finalResult?.address,
          },
          country_id: finalResult?.country,
          region_id: finalResult?.region,
          city_id: finalResult?.city,
          store_id: finalResult?.store,
        }
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.edit_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.edit_errors = res?.data?.message
      } finally {
        this.edit_disabled = false
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true)
      try {
        const { data } = await this.axios.post(
          `branches/update_is_active/${id}`
        )
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)
      try {
        const { isConfirmed } = await this.deleteAlert()
        if (!isConfirmed) return
        const { data } = await this.axios.post(`branches/delete/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.edit_data = {
        ...item,
        location: {
          lat: item?.lat,
          lng: item?.lng,
          address: item?.address,
        },
        country_id: item?.country,
        region_id: item?.region,
        city_id: item?.city,
        store_id: item?.store,
      }
      this.edit_active = true
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination)
        .filter((e) =>
          ['status', 'search_key', 'region_id', 'store_id'].includes(e)
        )
        .forEach((key) => this.$set(this.pagination, key, null))
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      this.$set(
        this.pagination,
        'is_active',
        status === 'requested_branches' ? 0 : status
      )
      this.$set(
        this.pagination,
        'requested_branches',
        status === 'requested_branches' ? status : 0
      )
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
    async fetchBranchesData({ company_id, id }) {
      try {
        this.$set(this.disables, `fetch_branches_data_${id}`, true)
        await Promise.all([
          this.getBrands(company_id),
          this.getSkus(company_id),
        ])
        this.assign_active = true
        this.assign_data.id = id
      } catch (error) {
      } finally {
        this.disables = {}
      }
    },
    selectAllBrands() {
      this.assign_data.brand_ids = this.brands
    },
    selectAllSkus() {
      this.assign_data.sku_ids = this.filterSKUs
    },
    async addRequestedBranchData() {
      this.assign_disabled = true
      this.assign_errors = {}
      const { sku_ids, brand_ids, ...others } = this.assign_data

      try {
        const { data } = await this.axios.post('branches/accept', {
          sku_ids: sku_ids.map((e) => e.id),
          brand_ids: brand_ids.map((e) => e.id),
          ...others,
        })
        this.getItems()
        this.createAlert(data.message)
        this.assign_active = false
        this.assign_data = {}
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.assign_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.assign_errors = res?.data?.message
      } finally {
        this.assign_disabled = false
      }
    },
  },
  components: { Table, Modal, TableLoad, Map, FilterWrapper },
}
</script>
