<template>
  <div class="w-full">
    <section class="px-6">
      <div class="bg-white ring-1 ring-gray-200 rounded-md">
        <form class="py-10 p-6 space-y-5 max-w-screen-xl" v-if="load">
          <transition name="fade">
            <div
              class="w-full bg-red-500/10 p-3 ring-1 ring-red-500 rounded-sm"
              v-if="add_error"
            >
              <p
                class="text-red-500 text-sm font-medium rtl:font-semibold"
                dir="auto"
              >
                {{ add_error }}
              </p>
            </div>
          </transition>
          <div
            class="w-full hidden items-center flex-wrap gap-3 sm:gap-0 form-group"
          >
            <div class="w-full sm:w-1/3 self-end">
              <label for="name">{{ $t('profile_photo') }}</label>
            </div>
            <div class="w-full sm:w-2/3 space-y-2">
              <figure class="space-y-4">
                <figcaption>
                  <img
                    :src="
                      add_data.image_input
                        ? convertImage(add_data.image_input)
                        : add_data.image ||
                          require('@/assets/images/profile.jpg')
                    "
                    @error="
                      (e) =>
                        (e.target.src = require('@/assets/images/profile.jpg'))
                    "
                    alt="profile image"
                    class="w-16 h-16 rounded-full ring-1 ring-gray-200 object-cover"
                  />
                </figcaption>
                <blockquote class="space-y-2">
                  <label class="block">
                    <span class="sr-only">{{ $t('choose_photo') }}</span>
                    <input
                      type="file"
                      accept="image/*"
                      class="custom-file"
                      @change="
                        (e) => $set(add_data, 'image_input', e.target.files[0])
                      "
                    />
                  </label>
                  <p
                    class="text-red-500 font-medium rtl:font-semibold text-sm"
                    v-if="add_errors.image"
                  >
                    {{ add_errors.image.join(' ') }}
                  </p>
                </blockquote>
              </figure>
            </div>
          </div>
          <div
            class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
          >
            <div class="w-full sm:w-1/3">
              <label for="name">{{ $t('name') }}</label>
            </div>
            <div class="w-full sm:w-2/3 space-y-2">
              <input
                type="text"
                name="name"
                id="name"
                required
                placeholder="Name"
                v-model="add_data.name"
              />
              <p
                class="text-red-500 font-medium rtl:font-semibold text-sm"
                v-if="add_errors.name"
              >
                {{ add_errors.name.join(' ') }}
              </p>
            </div>
          </div>

          <div
            class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
          >
            <div class="w-full sm:w-1/3 space-y-2">
              <label for="name">{{ $t('email') }}</label>
              <p class="text-gray-600 font-medium rtl:font-semibold text-sm">
                {{ $t('email_note') }}
              </p>
            </div>
            <div class="w-full sm:w-2/3 space-y-2">
              <input
                type="email"
                name="email"
                id="email"
                required
                placeholder="example@example.com"
                v-model.trim="add_data.email"
              />

              <p
                class="text-red-500 font-medium rtl:font-semibold text-sm"
                v-if="add_errors.email"
              >
                {{ add_errors.email.join(' ') }}
              </p>
            </div>
          </div>
          <div
            class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
          >
            <div class="w-full sm:w-1/3">
              <label for="mobile">{{ $t('mobile') }} </label>
            </div>
            <div class="w-full sm:w-2/3 space-y-2">
              <vue-tel-input
                id="mobile"
                ref="profileMobile"
                @open="handleOpen('profileMobile')"
                v-model="add_data.mobile"
              />
              <p
                class="text-red-500 font-medium rtl:font-semibold text-sm"
                v-if="add_errors.mobile"
              >
                {{ add_errors.mobile.join(' ') }}
              </p>
            </div>
          </div>
          <div
            class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
          >
            <div class="w-full sm:w-1/3">
              <label for="password">{{ $t('current_password') }}</label>
            </div>
            <div class="w-full sm:w-2/3 space-y-2">
              <div class="with-icon">
                <input
                  type="password"
                  name="password"
                  id="password"
                  required
                  placeholder="••••••"
                  v-model="add_data.current_password"
                />
                <button
                  class="input-icon"
                  @click.prevent="textPasswordType"
                  type="button"
                >
                  <i class="fa-regular fa-eye-slash"></i>
                </button>
              </div>
              <p
                class="text-red-500 font-medium rtl:font-semibold text-sm"
                v-if="add_errors.current_password"
              >
                {{ add_errors.current_password.join(' ') }}
              </p>
            </div>
          </div>
          <div
            class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
          >
            <div class="w-full sm:w-1/3">
              <label for="new_password">{{ $t('new_password') }}</label>
            </div>
            <div class="w-full sm:w-2/3 space-y-2">
              <div class="with-icon">
                <input
                  type="password"
                  name="new_password"
                  id="new_password"
                  required
                  placeholder="••••••"
                  v-model="add_data.new_password"
                />
                <button
                  class="input-icon"
                  @click.prevent="textPasswordType"
                  type="button"
                >
                  <i class="fa-regular fa-eye-slash"></i>
                </button>
              </div>
              <p
                class="text-red-500 font-medium rtl:font-semibold text-sm"
                v-if="add_errors.new_password"
              >
                {{ add_errors.new_password.join(' ') }}
              </p>
            </div>
          </div>
          <div
            class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
          >
            <div class="w-full sm:w-1/3"></div>
            <div class="w-full sm:w-2/3">
              <button
                class="py-3 px-6 rounded-md bg-blue-600 text-white text-xs font-medium rtl:font-semibold shadow-xl shadow-blue-600/20"
                @click.prevent="updateProfile"
                :disabled="add_disabled"
              >
                <spinner
                  size="w-4 h-4 inline-block"
                  class="mr-2 rtl:mr-0 rtl:ml-2"
                  v-if="add_disabled"
                />

                {{ $t('save_changes') }}
              </button>
            </div>
          </div>
        </form>
        <div class="py-10 px-6 space-y-5 max-w-screen-xl" v-else>
          <div
            class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
            v-for="i in 3"
            :key="i"
          >
            <div class="w-full sm:w-1/3 space-y-2">
              <div class="w-1/3 h-4 rounded-sm bg-gray-200 animate-pulse"></div>
              <div class="w-2/3 h-3 rounded-sm bg-gray-200 animate-pulse"></div>
            </div>
            <div class="w-full sm:w-2/3">
              <div
                class="w-full h-10 rounded-sm bg-gray-200 animate-pulse"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'profilePage',
  data() {
    return {
      load: false,
      add_data: {},
      add_disabled: false,
      add_errors: {},
      add_error: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      this.$store
        .dispatch('getting/getRoute', { name: 'profile' })
        .then(({ result }) => {
          const { profile, image_url } = result
          const user = {
            ...profile,
          }
          this.add_data = user
          this.load = true
        })
        .catch(({ result }) => {
          this.createAlert(result.message, 'error')
        })
    },
    async updateProfile() {
      this.add_disabled = true
      this.add_error = null
      this.add_errors = {}
      let fd = new FormData()

      Object.keys(this.add_data).forEach((key) => {
        fd.delete('image')
        if (this.add_data[key]) {
          if (key === 'image_input') {
            if (typeof this.add_data[key] === 'object') {
              fd.append('image', this.add_data[key], this.add_data[key].name)
            }
          } else if (key === 'mobile') {
            fd.append(key, this.add_data[key]?.replace(/[+|\s|()|-]/g, ''))
          } else {
            fd.append(key, this.add_data[key])
          }
        }
      })
      try {
        const { data } = await this.axios.post('profile/update', fd)
        // check if email address changed or not to redirect it to login again
        if (this.add_data?.email !== this.getUser?.email) {
          this.createAlert(
            'The current session has been ended, redirecting to login again'
          )
          this.logout()
          return
        }
        this.getUserInfo()
        this.createAlert(data.message)
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    getImageURL() {
      const splitURL = this.add_data?.image?.split('/') || []
      const filename = splitURL.filter((e) =>
        /(.png|.jpg|.webp|.jpeg)/g.test(e)
      )[0]
      return splitURL.slice(0, splitURL.indexOf(filename)).join('/')
    },
  },
}
</script>
