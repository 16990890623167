<template>
  <div class="space-y-6">
    <section class="w-full space-y-6">
      <main class="w-full px-6">
        <Table
          :has_header="true"
          :has_check="false"
          :title="$t('admins')"
          :records="`${pagination.total} ${$t('records')}`"
          :total_pages="pagination.total_pages"
          v-model="pagination.page"
          :is_loading="is_loading"
        >
          <template #actions>
            <div class="actions-group">
              <div>
                <button
                  class="py-1.5 px-3 rounded-sm text-sm flex items-center gap-2 text-gray-600 ring-1 ring-gray-300 font-medium rtl:font-semibold"
                  @click.prevent="add_active = !add_active"
                >
                  <i class="fa-solid fa-plus"></i>
                  <span>{{ $t('add_new.admin') }}</span>
                </button>
              </div>
            </div>
          </template>
          <template #filter>
            <ul class="flex items-center">
              <li v-for="(item, index) in status" :key="index">
                <button
                  class="p-4 text-center font-medium rtl:font-semibold text-sm capitalize"
                  @click.prevent="
                    changeFilterStatus(item === 'all' ? null : item, index)
                  "
                  :class="{
                    'active-tab':
                      pagination.is_active === (item === 'all' ? null : item),
                  }"
                  :disabled="disables[index]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    class="mr-2 rtl:mr-0 rtl:ml-2"
                    v-if="disables[index]"
                  />
                  {{ $t(item.replace(/\s/g, '_')) }}
                </button>
              </li>
            </ul>
          </template>
          <template #head>
            <th>{{ $t('name') }}</th>
            <th>{{ $t('email') }}</th>

            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('mobile') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('mobile')"
                  ></button>
                </div>
              </div>
            </th>
            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('role') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('role')"
                  ></button>
                </div>
              </div>
            </th>
            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('active') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('is_active')"
                  ></button>
                </div>
              </div>
            </th>
            <th>
              <div class="sort">
                <div>
                  <p>{{ $t('updated_at') }}</p>
                </div>
                <div>
                  <button
                    class="sort-btn"
                    @click.prevent="sortTable('updated_at')"
                  ></button>
                </div>
              </div>
            </th>

            <th></th>
          </template>
          <template #tbody v-if="!load">
            <TableLoad :rows="7" v-for="i in 6" :key="i" />
          </template>
          <template #tbody v-else-if="load && items.length !== 0">
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ item.name || '-' }}</td>
              <td>
                <a
                  :href="`mailto:${item.email}`"
                  class="text-sky-500"
                  v-if="item.email"
                  >{{ item.email }}</a
                >
                <span v-else>-</span>
              </td>
              <td>
                <a
                  :href="`tel:${item.mobile}`"
                  class="text-sky-500"
                  v-if="item.mobile"
                  >{{ item.mobile }}</a
                >
                <span v-else>-</span>
              </td>
              <td>{{ (item.role || {}).name || '-' }}</td>

              <td>
                <p
                  class="status capitalize"
                  :class="item_status[item.is_active]"
                >
                  {{ item.is_active === 1 ? $t('active') : $t('not_active') }}
                </p>
              </td>
              <td v-html="createTime(item.updated_at || item.created_at)"></td>

              <td>
                <div class="actions-group">
                  <div>
                    <button
                      class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                      @click.prevent="deleteItem(item.id)"
                      :disabled="disables[`delete_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`delete_${item.id}`]"
                      />
                      <span v-else> {{ $t('delete') }}</span>
                    </button>
                  </div>
                  <div>
                    <button
                      class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded-sm"
                      @click.prevent="changeStatus(item.id)"
                      :content="$t('change_status')"
                      v-tippy
                      :disabled="disables[`status_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`status_${item.id}`]"
                      />
                      <i class="fa-solid fa-right-left" v-else></i>
                    </button>
                  </div>
                  <div>
                    <button
                      class="w-8 h-8 bg-sky-500 text-white rounded-sm"
                      @click.prevent="showUpdate(item.id)"
                      :content="$t('update')"
                      v-tippy
                    >
                      <i class="fa-solid fa-pen"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template #tbody v-else-if="load && items.length === 0">
            <tr>
              <td colspan="7">
                <div class="table mx-auto my-4">
                  <button
                    class="py-1.5 px-3 rounded-sm text-sm flex items-center gap-2 text-gray-600 ring-1 ring-gray-300 font-medium rtl:font-semibold"
                    @click.prevent="add_active = !add_active"
                  >
                    <i class="fa-solid fa-plus"></i>
                    <span>{{ $t('add_new.admin') }}</span>
                  </button>
                </div>
                <p
                  class="text-sm text-gray-600 font-medium rtl:font-semibold text-center"
                >
                  {{ $t('no_data') }}
                </p>
              </td>
            </tr>
          </template>
        </Table>
      </main>
    </section>
    <transition name="scale">
      <Modal
        :title="$t('add_new.admin')"
        v-if="add_active"
        @close="add_active = $event"
      >
        <template>
          <form class="p-6 space-y-4">
            <div class="form-group">
              <div class="with-label">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  autocomplete="off"
                  v-model="add_data.name"
                />
                <label for="name">{{ $t('name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.name"
              >
                {{ add_errors.name.join(' ') }}
              </p>
            </div>

            <div class="form-group">
              <div class="with-label">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="example@example.com"
                  required
                  autocomplete="off"
                  v-model="add_data.email"
                  v-email
                />
                <label for="email">{{ $t('email') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.email"
              >
                {{ add_errors.email.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <vue-tel-input
                  id="mobile"
                  ref="userMobile"
                  @open="handleOpen('userMobile')"
                  v-model="add_data.mobile"
                />
                <label for="mobile">{{ $t('mobile') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.mobile"
              >
                {{ add_errors.mobile.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <a
                  href="javascript:void(0)"
                  @click.prevent="genPassword('password')"
                  class="text-secondary text-xs font-medium rtl:font-semibold bg-secondary/10 py-1 px-2 table rounded-sm ml-auto rtl:ml-0 rtl:mr-auto"
                  >{{ $t('generate_password') }}</a
                >
                <div class="with-icon">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••"
                    required
                    autocomplete="off"
                    v-model="add_data.password"
                    minlength="6"
                    ref="password"
                  />
                  <button
                    class="input-icon"
                    @click.prevent="textPasswordType"
                    type="button"
                    :content="$t('view')"
                    v-tippy
                  >
                    <i class="fa-regular fa-eye"></i>
                  </button>
                </div>
                <label for="password">{{ $t('password') }} </label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.password"
              >
                {{ add_errors.password.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <div class="with-icon">
                  <input
                    type="password"
                    name="password_confirmation"
                    id="password_confirmation"
                    placeholder="••••••"
                    required
                    autocomplete="off"
                    v-model="add_data.password_confirmation"
                    minlength="6"
                    ref="password_confirmation"
                  />
                  <button
                    class="input-icon"
                    @click.prevent="textPasswordType"
                    type="button"
                    :content="$t('view')"
                    v-tippy
                  >
                    <i class="fa-regular fa-eye"></i>
                  </button>
                </div>
                <label for="password_confirmation"
                  >{{ $t('confirmation_password') }}
                </label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.password_confirmation"
              >
                {{ add_errors.password_confirmation.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="add_data.role_id"
                  :options="roles"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="role_id"
                ></multiselect>
                <label for="role_id">{{ $t('role') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.role_id"
              >
                {{ add_errors.role_id.join(' ') }}
              </p>
            </div>

            <div class="actions-group">
              <div>
                <button
                  class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                  @click.prevent="addItem"
                  :disabled="add_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
                  {{ $t('add') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
    <transition name="scale">
      <Modal
        :title="`${edit_data.name}`"
        v-if="edit_active"
        @close="edit_active = $event"
      >
        <template>
          <form class="p-6 space-y-4">
            <div class="form-group">
              <div class="with-label">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  autocomplete="off"
                  v-model="edit_data.name"
                />
                <label for="name">{{ $t('name') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.name"
              >
                {{ edit_errors.name.join(' ') }}
              </p>
            </div>

            <div class="form-group">
              <div class="with-label">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="example@example.com"
                  required
                  autocomplete="off"
                  v-model="edit_data.email"
                  v-email
                />
                <label for="email">{{ $t('email') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.email"
              >
                {{ edit_errors.email.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <vue-tel-input
                  id="mobile"
                  ref="userMobile"
                  @open="handleOpen('userMobile')"
                  v-model="edit_data.mobile"
                />
                <label for="mobile">{{ $t('mobile') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.mobile"
              >
                {{ edit_errors.mobile.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <div class="with-icon">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••"
                    required
                    autocomplete="off"
                    v-model="edit_data.n_password"
                    minlength="6"
                  />
                  <button
                    class="input-icon"
                    @click.prevent="textPasswordType"
                    type="button"
                    :content="$t('view')"
                    v-tippy
                  >
                    <i class="fa-regular fa-eye"></i>
                  </button>
                </div>

                <label for="password">Password</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.n_password"
              >
                {{ edit_errors.n_password.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <div class="with-icon">
                  <input
                    type="password"
                    name="n_password_confirmation"
                    id="n_password_confirmation"
                    placeholder="••••••"
                    required
                    autocomplete="off"
                    v-model="add_data.n_password_confirmation"
                    minlength="6"
                    ref="n_password_confirmation"
                  />
                  <button
                    class="input-icon"
                    @click.prevent="textPasswordType"
                    type="button"
                    :content="$t('view')"
                    v-tippy
                  >
                    <i class="fa-regular fa-eye"></i>
                  </button>
                </div>
                <label for="n_password_confirmation"
                  >{{ $t('confirmation_password') }}
                </label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="add_errors.n_password_confirmation"
              >
                {{ add_errors.n_password_confirmation.join(' ') }}
              </p>
            </div>
            <div class="form-group">
              <div class="with-label">
                <multiselect
                  v-model="edit_data.role_id"
                  :options="roles"
                  :placeholder="$t('search')"
                  label="name"
                  track-by="id"
                  id="role_id"
                ></multiselect>
                <label for="role_id">{{ $t('role') }}</label>
              </div>
              <p
                class="text-red-500 font-medium text-xs mt-2"
                v-if="edit_errors.role_id"
              >
                {{ edit_errors.role_id.join(' ') }}
              </p>
            </div>
            <div class="actions-group">
              <div>
                <button
                  class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                  @click.prevent="editItem"
                  :disabled="edit_disabled"
                >
                  <spinner size="w-4 h-4 inline-block" v-if="edit_disabled" />
                  {{ $t('save_changes') }}
                </button>
              </div>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
  </div>
</template>
<script>
import Table from '@/components/basics/Table.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import Modal from '@/components/basics/Modal.vue'
import Tabs from '@/components/basics/Tabs.vue'
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'Admins',
  data() {
    return {
      load: false,
      is_loading: false,
      items: [],
      add_data: {
        role_id: null,
      },
      stores: [],
      branches: [],
      add_active: false,
      add_errors: {},
      add_disabled: false,
      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,
      pagination: {
        page: 1,
        total_pages: 0,
        search_key: null,
        sort_key: null,
        sort_type: 'asc',
        is_active: null,
        role_type: 'all',
        total: 0,
      },
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
      roles: [],
      apply_disabled: false,
      reset_disabled: false,
      status: ['all', 'active', 'not active'],
      disables: {},
    }
  },
  watch: {
    'pagination.page'() {
      this.getItems()
    },
    'pagination.role_type'() {
      this.is_loading = true
      this.getItems().finally(() => {
        this.is_loading = false
      })
    },
    edit_active() {
      if (!this.edit_active) {
        this.edit_data = {}
        this.edit_errors = {}
        this.edit_disabled = false
      }
    },
    add_active() {
      if (!this.add_active) {
        this.add_data = {}
        this.add_errors = {}
        this.add_disabled = false
      }
    },
    is_called(val){
      if(val) return this.getItems()
    }
  },
  computed:{
    ...mapGetters('table', ['search', 'is_called']),
  },
  async created() {
    Promise.all([await this.getItems(), await this.getRoles()])
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled']),
    async getRoles() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          'roles'
        )
        this.roles = result.roles
      } catch (error) {}
    },
    getItems() {
      const { page, search_key, sort_key, sort_type, is_active, role } =
        this.pagination
      return this.$store
        .dispatch('getting/getRoute', {
          name: 'admins',
          options: {
            page,
            search_key: this.search,
            sort_key,
            sort_type,
            is_active: is_active ? (is_active === 'active' ? 1 : 0) : is_active,
            role,
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.admins
          this.items = data.map((e) => {
            return {
              ...e,
              image: e.image ? result.image_url + '/' + e.image : e.image, // null,
            }
          })
          this.$set(this.pagination, 'total', pagination.total)
          this.$set(this.pagination, 'total_pages', pagination.total_pages)
        })
        .catch((err) => {
          const res = err?.response
          if (!res) {
            this.createAlert(
              'There is something went wrong, please try again later.',
              'error'
            )
            return
          }
          this.createAlert(res?.data?.message, 'error')
        })
        .finally(() => {
          this.load = true;
          this.changeIsCalled(false);
        })
    },
    async addItem() {
      console.log('lorem')
      this.add_disabled = true
      this.add_errors = {}
      const item = this.add_data

      try {
        const { data } = await this.axios.post('admins/add', {
          ...item,
          mobile: item?.mobile?.replace(/[+|\s|()|-]/g, ''),
          role_id: item?.role_id?.id,
        })
        this.getItems()
        this.createAlert(data.message)
        this.add_active = false
        this.add_data = {}
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.add_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.add_errors = res?.data?.message
      } finally {
        this.add_disabled = false
      }
    },
    async editItem() {
      this.edit_disabled = true
      this.edit_errors = {}
      const item = this.edit_data
      try {
        const { data } = await this.axios.post('admins/update', {
          ...item,
          mobile: item?.mobile?.replace(/[+|\s|()|-]/g, ''),

          role_id: item?.role_id?.id,
        })
        const { message, result } = data
        this.getItems()
        this.createAlert(message)
        // this.edit_active = false
        this.edit_data = result.admin
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.edit_error =
            'There is something went wrong, please try again later.'
          return
        }
        this.edit_errors = res?.data?.message
      } finally {
        this.edit_disabled = false
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true)
      try {
        const { data } = await this.axios.post(`admins/update_is_active/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true)
      try {
        const { isConfirmed } = await this.deleteAlert();
        if (!isConfirmed) return
        const { data } = await this.axios.post(`admins/delete/${id}`)
        this.createAlert(data.message)
        this.getItems()
      } catch (err) {
        const res = err?.response
        if (!res) {
          this.createAlert(
            'There is something went wrong, please try again later.',
            'error'
          )
          return
        }
        this.add_errors = res?.data?.message
        this.createAlert(res?.data?.message, 'error')
      } finally {
        this.disables = {}
      }
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id)
      if (!item) {
        this.createAlert('Item does not existed', 'info')
        return
      }
      this.edit_data = {
        ...item,
        role_id: item?.role,
      }
      this.edit_active = true
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = 'asc'
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == 'asc' ? 'desc' : 'asc'
      }
      this.pagination.sort_key = key
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`)
      })
    },
    applyFilter() {
      this.apply_disabled = true
      this.getItems().finally(() => {
        this.apply_disabled = false
      })
    },
    resetFilter() {
      this.reset_disabled = true
      Object.keys(this.pagination)
        .filter((e) => ['status', 'search_key'].includes(e))
        .forEach((key) => this.$set(this.pagination, key, null))
      this.getItems().finally(() => {
        this.reset_disabled = false
      })
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true)
      this.$set(this.pagination, 'is_active', status)
      this.getItems().finally(() => {
        this.disables = {}
      })
    },
  },
  components: { Table, Modal, TableLoad, Tabs },
}
</script>
