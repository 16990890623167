<template>
  <div>
    <section class="w-full space-y-6 px-6">
      <cards-container :cards="cards" :load="!load" />
      <main class="w-full flex flex-wrap gap-6">
        <div class="flex-1 w-full">
          <Table
            :has_header="true"
            :has_search="false"
            :has_check="false"
            :title="$t('companies')"
            :records="`${companies.length} ${$t('records')}`"
          >
            <template #head>
              <th>{{ $t('name') }}</th>
              <th>{{ $t('email') }}</th>
              <th>{{ $t('mobile') }}</th>
              <th>{{ $t('contact_name') }}</th>
              <th>{{ $t('status') }}</th>
              <th>{{ $t('active_merchandisers') }}</th>
              <th>{{ $t('not_active_merchandisers') }}</th>
              <th>
                {{ $t('merchandisers') }}
              </th>
            </template>
            <template #tbody v-if="!load">
              <TableLoad :rows="5" v-for="i in 6" :key="i" />
            </template>
            <template #tbody v-else-if="load && companies.length !== 0">
              <tr v-for="(item, index) in companies" :key="index">
                <td>{{ item.name || '...' }}</td>
                <td>
                  <a
                    :href="`mailto:${item.contact_email}`"
                    class="text-sky-500"
                    v-if="item.contact_email"
                    >{{ item.contact_email }}</a
                  >
                  <span v-else>-</span>
                </td>
                <td>
                  <a
                    :href="`tel:${item.contact_mobile}`"
                    class="text-sky-500"
                    v-if="item.contact_mobile"
                    >{{ item.contact_mobile }}</a
                  >
                  <span v-else>-</span>
                </td>
                <td>{{ item.contact_name || '...' }}</td>
                <td>
                  <p
                    class="status capitalize"
                    :class="item_status[item.is_active]"
                    v-if="!item.is_new"
                  >
                    {{ item.is_active === 1 ? $t('active') : $t('not_active') }}
                  </p>
                  <p
                    class="status capitalize"
                    :class="item_status['new']"
                    v-else
                  >
                    {{ $t('new') }}
                  </p>
                </td>
                <td>{{ item.active_merchandisers_count || 0 }}</td>
                <td>{{ item.not_active_merchandisers_count || 0 }}</td>
                <td>
                  {{
                    item.is_new
                      ? item.merchandisers_count || 0
                      : (item.active_merchandisers_count + item.not_active_merchandisers_count)  || 0
                  }}
                </td>
              </tr>
            </template>
            <template #tbody v-else-if="load && companies.length === 0">
              <tr>
                <td colspan="5">
                  <p
                    class="
                      text-sm text-gray-600
                      font-medium
                      rtl:font-semibold
                      text-center
                    "
                  >
                    {{ $t('no_data') }}
                  </p>
                </td>
              </tr>
            </template>
          </Table>
        </div>
        <div class="flex-1 w-full" v-if="false">
          <Table
            :has_header="true"
            :has_search="false"
            :has_check="false"
            :title="$t('join_requests')"
            :records="`${join_requests.length} ${$t('records')}`"
          >
            <template #head>
              <th>{{ $t('name') }}</th>
              <th>{{ $t('email') }}</th>
              <th>{{ $t('mobile') }}</th>
              <th>{{ $t('contact_name') }}</th>
              <th>{{ $t('status') }}</th>
              <th>{{ $t('merchandisers') }}</th>
            </template>
            <template #tbody v-if="!load">
              <TableLoad :rows="6" v-for="i in 6" :key="i" />
            </template>
            <template #tbody v-else-if="load && join_requests.length !== 0">
              <tr v-for="(item, index) in join_requests" :key="index">
                <td>{{ item.company_name || '...' }}</td>
                <td>
                  <a
                    :href="`mailto:${item.contact_email}`"
                    class="text-sky-500"
                    v-if="item.contact_email"
                    >{{ item.contact_email }}</a
                  >
                  <span v-else>-</span>
                </td>
                <td>
                  <a
                    :href="`tel:${item.contact_mobile}`"
                    class="text-sky-500"
                    v-if="item.contact_mobile"
                    >{{ item.contact_mobile }}</a
                  >
                  <span v-else>-</span>
                </td>
                <td>{{ item.contact_name || '...' }}</td>

                <td>
                  <p
                    class="status capitalize"
                    :class="item_status[item.status]"
                  >
                    {{ $t(item.status) }}
                  </p>
                </td>
                <td>
                  {{ item.merchandisers_count || '...' }}
                </td>
              </tr>
            </template>
            <template #tbody v-else-if="load && join_requests.length === 0">
              <tr>
                <td colspan="6">
                  <p
                    class="
                      text-sm text-gray-600
                      font-medium
                      rtl:font-semibold
                      text-center
                    "
                  >
                    {{ $t('no_data') }}
                  </p>
                </td>
              </tr>
            </template>
          </Table>
        </div>
      </main>
    </section>
  </div>
</template>
<script>
import Table from '@/components/basics/Table.vue'
import SingleCard from '@/components/basics/SingleCard.vue'
import TableLoad from '@/components/basics/TableLoad.vue'
import { mapGetters, mapMutations } from 'vuex'
import CardsContainer from '@/components/basics/CardsContainer.vue'
export default {
  name: 'HomePage',
  data() {
    return {
      load: false,
      companies: [],
      join_requests: [],

      cards: [
        {
          title: 'Visits',
          value: 0,
          key: 'visits_count',
          translate_key: 'visits',
          icon: 'fa-solid fa-location-crosshairs',
          style: {
            icon: 'text-sky-600',
            parent: 'bg-sky-600/20',
          },
        },
        {
          title: 'SKUs',
          value: 0,
          key: 'skus_count',
          translate_key: 'skus',
          icon: 'fa-solid fa-basket-shopping',
          style: {
            icon: 'text-yellow-600',
            parent: 'bg-yellow-600/20',
          },
        },
        {
          title: 'Stores',
          value: 0,
          key: 'stores_count',
          translate_key: 'stores',
          icon: 'fa-solid fa-store',
          style: {
            icon: 'text-orange-600',
            parent: 'bg-orange-600/20',
          },
        },
        {
          title: 'Branches',
          value: 0,
          key: 'branches_count',
          translate_key: 'branches',
          icon: 'fa-solid fa-shop',
          style: {
            icon: 'text-red-600',
            parent: 'bg-red-600/20',
          },
        },
        {
          title: 'Listed Stores',
          value: 0,
          key: 'listed_stores_acount',
          translate_key: 'listed_stores',
          icon: 'fa-solid fa-store',
          style: {
            icon: 'text-orange-600',
            parent: 'bg-orange-600/20',
          },
        },
        {
          title: 'Listed Branches',
          value: 0,
          key: 'listed_branches_count',
          translate_key: 'listed_branches',
          icon: 'fa-solid fa-shop',
          style: {
            icon: 'text-red-600',
            parent: 'bg-red-600/20',
          },
        },

        {
          title: 'Brands',
          value: 0,
          key: 'brands_count',
          translate_key: 'brands',
          icon: 'fa-solid fa-hashtag',
          style: {
            icon: 'text-indigo-600',
            parent: 'bg-indigo-600/20',
          },
        },
        {
          title: 'companies',
          value: 0,
          key: 'companies_count',
          translate_key: 'companies',
          icon: 'fa-solid fa-building',
          style: {
            icon: 'text-sky-600',
            parent: 'bg-sky-600/20',
          },
        },
        {
          title: 'Clients',
          value: 0,
          key: 'client_count',
          translate_key: 'clients',
          icon: 'fa-solid fa-user-group',
          style: {
            icon: 'text-blue-400',
            parent: 'bg-blue-400/20',
          },
        },
        {
          title: 'Supervisors',
          value: 0,
          key: 'supervisors_count',
          translate_key: 'supervisors',
          icon: 'fa-solid fa-user-group',
          style: {
            icon: 'text-blue-400',
            parent: 'bg-blue-400/20',
          },
        },

        {
          title: 'Regions',
          value: 0,
          key: 'regions_count',
          translate_key: 'regions',
          icon: 'fa-solid fa-magnifying-glass-location',
          style: {
            icon: 'text-green-600',
            parent: 'bg-green-600/20',
          },
        },
        {
          title: 'Cities',
          value: 0,
          key: 'cities_count',
          translate_key: 'cities',
          icon: 'fa-solid fa-compass',
          style: {
            icon: 'text-green-400',
            parent: 'bg-green-400/20',
          },
        },
      ],

      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
        accepted: 'bg-sky-500/10 text-sky-500',
        rejected: 'bg-red-500/10 text-red-500',
        pending: 'bg-gray-600/10 text-gray-600',
        new: 'bg-teal-500/10 text-teal-500',
      },
    }
  },
  async created() {
    Promise.all([await this.getDashboard()])
  },

  computed: {
    ...mapGetters('customize', ['getBasicColumn', 'getSelectedColumns']),
    ...mapGetters('table', ['is_called', 'table_paginate', 'search']),
    endpointName() {
      return this.$route.meta?.endpoint_name
    },
    columns() {
      const data = this.getSelectedColumns(this.endpointName)
      return data
    },
  },
  watch: {
    async is_called() {
      if (this.is_called) {
        await this.getDashboard()
      }
    },
  },
  methods: {
    ...mapMutations('table', ['changeIsCalled']),

    async getDashboard() {
      this.load = false
      try {
        const { result } = await this.$store.dispatch('getting/getRoute', {
          name: 'dashboard',
          options: {
            ...this.table_paginate,
          },
        })
        const { summary, latest_companies, latest_join_requests } = result
        this.displaySummaryData(summary)
        this.companies = latest_companies
        this.join_requests = latest_join_requests
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      } finally {
        this.load = true
        this.changeIsCalled(false)
      }
    },

    displaySummaryData(summary) {
      const summaryKeys = Object.keys(summary)
      for (let sIndx = 0; sIndx < summaryKeys.length; sIndx++) {
        const item = summaryKeys[sIndx]
        const cardItem = this.cards.find((e) => e.key === item)
        if (cardItem) {
          cardItem.value = summary[item]
          this.$set(
            this.cards,
            this.cards.findIndex((e) => e.key === cardItem.key),
            cardItem
          )
        }
      }
    },

    async displayWeekVisits(visits) {
      this.week_visits_count = await this.calcCounts(visits)

      this.$set(this.chartOptions?.xaxis, 'categories', visits)
      this.$set(
        this.series[0],
        'data',
        visits.map((e) => e.scheduled_visits_count)
      )
      this.$set(
        this.series[1],
        'data',
        visits.map((e) => e.done_visits_count)
      )
    },
    async displayWeekVisitsPerStatus(visits) {
      this.week_visits_status_count = await this.calcCounts(visits)
      let items = []
      let obj = {}
      for (let i = 0; i < visits.length; i++) {
        const item = visits[i]
        const { labels } = await this.displayDataPerStatus(item)

        obj.labels = labels
        labels.forEach((label, index) => {
          if (!obj[label]) {
            obj[label] = []
          }
          Object.assign(obj, {
            [label]: [...obj[label], item[label]],
          })
        })
      }

      for (const key in obj) {
        let finalObj = {}
        if (!/labels/g.test(key)) {
          finalObj.data = obj[key]
          finalObj.name = key
          items.push(finalObj)
        }
      }

      this.per_status_series = items
    },

    displayDataPerStatus(items) {
      return new Promise((resolve) => {
        const visitsKeys = Object.keys(items)
        let series = [],
          labels = []
        for (let i = 0; i < visitsKeys.sort().length; i++) {
          const searchRegex = /_visits_count/g
          const visitStatus = visitsKeys[i]
          if (searchRegex.test(visitStatus)) {
            series = [...series, items[visitStatus]]
            labels = [...labels, visitStatus]
          }
        }

        resolve({
          series,
          labels,
        })
      })
    },

    calcCounts(items) {
      let total = 0
      return new Promise((resolve) => {
        for (let i = 0; i < items.length; i++) {
          const item = items[i]
          total += item.visits_count
        }

        resolve(total)
      })
    },
  },
  components: { Table, SingleCard, TableLoad, CardsContainer },
}
</script>
