import Vue from 'vue'
import VueEcho from 'vue-echo'
import pusher from 'vue-pusher'

export default async function auth({ next, store, to, from }) {
  const token = store.state.auth.token || Vue.$cookies.get('t')
  // const isExisted = Object.keys(store.state.auth.user_info).length !== 0
  if (!token) {
    if (!['Login', 'Join'].includes(to.name)) {
      return next({
        name: 'Login',
        query: {
          redirect: ['Login', 'Join'].includes(from.name) ? '/' : from.fullPath,
        },
      })
    }
    return next()
  }

  if (['Login', 'Join'].includes(to.name)) {
    const tokenQuery = to.query.token
    if (!tokenQuery) {
      return next({
        path: from.path || '/',
      })
    }
    store.commit('auth/SET_USER', {
      access_token: tokenQuery,
    })
    await fetchProfile(store, tokenQuery, next)

    return next({
      path: '/profile',
    })
  }

  Vue.use(pusher, {
    api_key: 'eed09efd52277fadc262',
    options: {
      cluster: 'eu',
      encrypted: true,
      // authTransport: "jsonp",
      authEndpoint: 'https://apis.rtibha.com/broadcasting/auth',
      forceTLS: false,
      // disableStats: true
      auth: {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    },
  })

  Vue.use(VueEcho, {
    broadcaster: 'pusher',
    key: 'eed09efd52277fadc262',
    authEndpoint: 'https://apis.rtibha.com/broadcasting/auth',
    forceTLS: false,
    encrypted: true,

    cluster: 'eu',
    // disableStats: true
    auth: {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  })

  await fetchProfile(store, token, to)

  return next()
}

async function fetchProfile(store, token, route) {
  return await store
    .dispatch('getting/getRoute', { name: 'profile' })
    .then(({ result }) => {
      store.commit('auth/SET_USER', {
        profile: result.profile,
        access_token: token,
      })
      const permissions = result?.permissions || []
      store.commit('auth/SET_PERMISSIONS', permissions)

      // store.commit('customize/STORE_CART', { id: data.result.profile.id })
      // next()
    })
    .catch((err) => {
      const res = err?.response
      // console.log(res)
      // if (!res) {
      //   store.commit('getting/SET_ERROR', true)
      //   return next({
      //     name: 'Login',
      //   })
      //   return
      // }
      // store.commit('auth/SET_USER', {})
      // Vue.$cookies.remove('t')
      // next({
      //   name: 'Login',
      // })
      return
    })
}
