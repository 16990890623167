<template>
  <div>
    <section class="w-full flex gap-6 flex-col lg:flex-row" v-if="load">
      <aside class="w-full lg:w-1/3 space-y-4">
        <figure class="bg-white ring-1 ring-gray-200 rounded-md w-full">
          <header class="w-full border-b border-gray-100 p-4">
            <p class="text-sm font-semibold rtl:font-bold text-gray-600">
              {{ $t('view_details') }}
            </p>
          </header>
          <blockquote class="p-6">
            <ul class="list-none space-y-3">
              <li class="w-full">
                <div class="flex w-full">
                  <div class="w-1/2 p-1.5">
                    <p
                      class="text-sm text-gray-500 font-medium rtl:font-semibold"
                    >
                      <i class="fa-solid fa-user mr-2 rtl:mr-0 rtl:ml-2"></i>
                      {{ $t('name') }}
                    </p>
                  </div>
                  <div class="w-1/2 p-1.5">
                    <p
                      class="text-sm font-medium rtl:font-semibold"
                      v-if="item.name || item.alt_name"
                    >
                      {{ item.name }} -
                      {{ item.alt_name }}
                    </p>
                    <p class="text-sm font-medium rtl:font-semibold" v-else>
                      ...
                    </p>
                  </div>
                </div>
              </li>
              <li class="w-full">
                <div class="flex w-full flex-wrap">
                  <div class="w-1/2 p-1.5">
                    <p
                      class="text-sm text-gray-500 font-medium rtl:font-semibold"
                    >
                      <i
                        class="fa-solid fa-right-left mr-2 rtl:mr-0 rtl:ml-2"
                      ></i>
                      {{ $t('active') }}
                    </p>
                  </div>
                  <div class="w-1/2 p-1.5">
                    <p
                      class="status capitalize"
                      :class="item_status[item.is_active]"
                    >
                      {{
                        item.is_active === 1 ? $t('active') : $t('not_active')
                      }}
                    </p>
                  </div>
                </div>
              </li>
              <li class="w-full">
                <div class="flex w-full">
                  <div class="w-1/2 p-1.5">
                    <p
                      class="text-sm text-gray-500 font-medium rtl:font-semibold"
                    >
                      <i
                        class="fa-solid fa-envelope-open mr-2 rtl:mr-0 rtl:ml-2"
                      ></i>
                      {{ $t('email') }}
                    </p>
                  </div>
                  <div class="w-1/2 p-1.5">
                    <a
                      :href="`mailto:${item.contact_email || '...'}`"
                      class="text-xs text-primary block font-medium rtl:font-semibold truncate"
                      >{{ item.contact_email || '...' }}
                    </a>
                  </div>
                </div>
              </li>
              <li class="w-full">
                <div class="flex w-full">
                  <div class="w-1/2 p-1.5">
                    <p
                      class="text-sm text-gray-500 font-medium rtl:font-semibold"
                    >
                      <i class="fa-solid fa-phone mr-2 rtl:mr-0 rtl:ml-2"></i>
                      {{ $t('mobile') }}
                    </p>
                  </div>
                  <div class="w-1/2 p-1.5">
                    <a
                      :href="`tel:${item.contact_mobile || '...'}`"
                      class="text-xs text-primary block font-medium rtl:font-semibold truncate"
                      >{{ item.contact_mobile || '...' }}
                    </a>
                  </div>
                </div>
              </li>
              <li class="w-full">
                <div class="flex w-full">
                  <div class="w-1/2 p-1.5">
                    <p
                      class="text-sm text-gray-500 font-medium rtl:font-semibold"
                    >
                      <i class="fa-regular fa-clock mr-2 rtl:mr-0 rtl:ml-2"></i>
                      {{ $t('joined_date') }}
                    </p>
                  </div>
                  <div class="w-1/2 p-1.5">
                    <p
                      class="text-sm font-medium rtl:font-semibold"
                      v-html="createTime(item.created_at)"
                    ></p>
                  </div>
                </div>
              </li>
            </ul>
          </blockquote>
        </figure>
      </aside>
      <main class="space-y-6 w-full lg:w-2/3">
        <slot></slot>
      </main>
    </section>
    <div class="table p-6 mx-auto" v-else>
      <spinner size="w-8 h-8" class="align-sub text-primary" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContainerWrapper',
  data() {
    return {
      item: {},
      load: false,
      item_status: {
        1: 'bg-sky-500/10 text-sky-500',
        0: 'bg-red-500/10 text-red-500',
      },
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  async created() {
    await this.getItem()
  },
  methods: {
    async getItem() {
      try {
        const { result } = await this.$store.dispatch(
          'getting/getRoute',
          `companies/${this.id}`
        )
        this.item = result.company
      } catch ({ result, type }) {
        this.createAlert(result?.message, type)
      } finally {
        this.load = true
      }
    },
  },
}
</script>
